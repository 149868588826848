import React from 'react';
import { Button, Columns, Form } from 'react-bulma-components';

import { ChargeResult } from '../../types/api';

const ChargeDetail = function ChargeDetail({
  paymentTransfer,
  paymentMethod,
  period,
}: ChargeResult) {
  const { metadata, paymentProfile } = paymentMethod || {};
  const { email, percentage } = metadata || {};
  const { stripeCustomerId } = paymentProfile || {};
  const { amount, revenueDetails, status } = paymentTransfer;
  const { endDate, startDate, totalRevenue } = revenueDetails || {};
  const { startedAt: start, endedAt: end } = period || {};
  const [endedAt] = end
    ? new Date(Date.parse(end)).toISOString().split('T')
    : [];
  const [startedAt] = start
    ? new Date(Date.parse(start)).toISOString().split('T')
    : [];

  return (
    <>
      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Status</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Field.Label>
              <b>{status}</b>
            </Form.Field.Label>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Period</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Columns>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>Start Date</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Button isStatic fullwidth>
                      {startDate}
                    </Button>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>End Date</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Button isStatic fullwidth>
                      {endDate}
                    </Button>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Revenue</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Columns>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>
                      Total<i>&nbsp;USD</i>
                    </Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Button isStatic fullwidth>
                      {totalRevenue}
                    </Button>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              {percentage && (
                <Columns.Column>
                  <Form.Field kind="addons">
                    <Form.Control>
                      <Button isStatic>
                        Everbloom Shares<i>&nbsp;%</i>
                      </Button>
                    </Form.Control>
                    <Form.Control fullwidth>
                      <Button isStatic fullwidth>
                        {percentage}
                      </Button>
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
              )}
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>
                      Charge Amount<i>&nbsp;USD</i>
                    </Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Button isStatic fullwidth>
                      {amount}
                    </Button>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      {period && (
        <Form.Field horizontal>
          <Form.Field.Label>
            <Form.Label>Contract Period</Form.Label>
          </Form.Field.Label>
          <Form.Field.Body>
            <Form.Field>
              <Columns>
                <Columns.Column>
                  <Form.Field kind="addons">
                    <Form.Control>
                      <Button isStatic>Started at</Button>
                    </Form.Control>
                    <Form.Control fullwidth>
                      <Button isStatic fullwidth>
                        {startedAt}
                      </Button>
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field kind="addons">
                    <Form.Control>
                      <Button isStatic>Ends at</Button>
                    </Form.Control>
                    <Form.Control fullwidth>
                      <Button isStatic fullwidth>
                        {endedAt}
                      </Button>
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
              </Columns>
            </Form.Field>
          </Form.Field.Body>
        </Form.Field>
      )}

      {paymentMethod && (
        <Form.Field horizontal>
          <Form.Field.Label>
            <Form.Label>Stripe Info</Form.Label>
          </Form.Field.Label>
          <Form.Field.Body>
            <Form.Field>
              <Columns>
                <Columns.Column>
                  <Form.Field kind="addons">
                    <Form.Control>
                      <Button isStatic>Email</Button>
                    </Form.Control>
                    <Form.Control fullwidth>
                      <Button isStatic fullwidth>
                        {email}
                      </Button>
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field kind="addons">
                    <Form.Control>
                      <Button isStatic>Customer ID</Button>
                    </Form.Control>
                    <Form.Control fullwidth>
                      <Button isStatic fullwidth>
                        {stripeCustomerId}
                      </Button>
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
              </Columns>
            </Form.Field>
          </Form.Field.Body>
        </Form.Field>
      )}
    </>
  );
};

export default ChargeDetail;
