import React from 'react';
import { Button, Columns, Form, Progress } from 'react-bulma-components';

import { Profile } from '../../types';
import { PayoutRequest, PayoutResult } from '../../types/api';
import NumberInputColumn from '../Form/NumberInputColumn';
import PayoutTable from './table';

type Props = {
  isLoadingPayout?: boolean;
  isLoadingProfile?: boolean;
  onChange: (
    fieldName: keyof PayoutRequest
  ) => (event: React.FormEvent<HTMLInputElement>) => void;
  onRequest: () => {};
  payout: PayoutRequest;
  profile: Profile;
  result?: PayoutResult;
};

const PayoutForm = function PayoutForm({
  isLoadingPayout,
  isLoadingProfile,
  onChange,
  onRequest,
  payout,
  profile,
  result,
}: Props) {
  const { dryRun, endDate, startDate, totalRevenue } = payout;
  const { fullName, userName } = profile;

  return isLoadingProfile ? (
    <Progress max={100} color="info" />
  ) : (
    <>
      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Creator</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Field.Label>{fullName || userName}</Form.Field.Label>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Period</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Columns>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>Start Date</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="startDate"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      value={startDate}
                      onChange={onChange('startDate')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>End Date</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="endDate"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      value={endDate}
                      onChange={onChange('endDate')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Total Revenue</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Columns>
              <NumberInputColumn
                id="totalRevenue"
                info="US$"
                placeholder="Total Revenue"
                value={totalRevenue}
                onChange={onChange('totalRevenue')}
                help={
                  <>
                    The<b> total 100%</b> creator&apos;s revenue during the{' '}
                    <i>period</i>. We are only going to distribute a small
                    percentage of it.
                  </>
                }
              />
              <Columns.Column />
            </Columns>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <br />

      <Form.Field horizontal>
        <Form.Field.Label />
        <Form.Field.Body>
          {isLoadingPayout ? (
            <Form.Field>
              <Progress max={100} color="info" />
            </Form.Field>
          ) : (
            <PayoutTable result={result} />
          )}
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label />
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Button
                fullwidth
                color={dryRun ? 'primary' : 'warning'}
                loading={isLoadingPayout}
                onClick={onRequest}
              >
                <b>{dryRun ? 'Preview' : 'Commit'}</b>
              </Button>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>
    </>
  );
};

PayoutForm.defaultProps = {
  isLoadingPayout: false,
  isLoadingProfile: false,
  result: undefined,
};

export default PayoutForm;
