import React, { useEffect, useState } from 'react';
import { Container, Progress } from 'react-bulma-components';
import { Link } from 'react-router-dom';

import { ROOT, DISCOVERS, DISCOVER_NEW } from '../../constants/routes';

import { useFirebase } from '../Firebase';
import Navigation from '../Navigation';

import DiscoversTable from './table';
import { converter, Discover } from './types';

const DiscoversListPage = function DiscoversListPage() {
  const firebase = useFirebase()!;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [discovers, setDiscovers] = useState<Discover[]>([]);

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const fetchAndSetData = async () => {
      const snapshot = await firebase.db
        .collection('discoverStories')
        .withConverter(converter)
        .get();
      const discovers1: Discover[] = snapshot.docs.map((doc) => doc.data()!);
      if (mounted) {
        // console.log(JSON.stringify(discovers1[0], null, 4));
        setDiscovers(discovers1);
        setLoading(false);
      }
    };
    fetchAndSetData().catch((error) =>
      // eslint-disable-next-line no-console
      console.error('fetchAndSetData ERR', error)
    );
    return () => {
      mounted = false;
    };
  }, [firebase.db]);

  return (
    <>
      <Navigation />
      <Container>
        <br />
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li className="is-active">
              <Link to={DISCOVERS.to}>{DISCOVERS.title}</Link>
            </li>
            <li>
              <Link to={DISCOVER_NEW.to}>{DISCOVER_NEW.title}</Link>
            </li>
          </ul>
        </nav>
        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <DiscoversTable discovers={discovers} />
        )}
        <br />
      </Container>
    </>
  );
};

export default DiscoversListPage;
