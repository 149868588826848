import { firebase } from '../Firebase';

export interface Featured {
  id: string;
  title: string;
  subtitle: string;
  imageUrl: string;
  cardTitle: string;
  cardSubtitle: string;
  cardBadgeUrl: string;
  storyType: string;
  tier: string;
}

export const IDs = {
  backup: 'backup',
  current: 'current',
  new: 'new',
};

export const Tiers = {
  free: 'free',
  originals: 'originals',
  bryant: 'bryant',
};

export const CardBadgeUrls = Object.keys(Tiers).reduce<{
  [x: string]: string;
}>((acc, tier) => {
  acc[
    tier
  ] = `https://d2st0fnq3grac2.cloudfront.net/discover-images/${tier}-badge-clear.png`;
  return acc;
}, {});

export const empty: Featured = {
  id: '',
  title: '',
  subtitle: '',
  imageUrl: '',
  cardTitle: '',
  cardSubtitle: '',
  cardBadgeUrl: CardBadgeUrls.free,
  storyType: '',
  tier: Tiers.free,
};

export const converter = {
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)!;
    const featured: Featured = {
      ...empty,
      id: snapshot.id,
      ...data,
    };
    return featured;
  },
  toFirestore(featured: Featured): firebase.firestore.DocumentData {
    return {
      ...featured,
    };
  },
};
