import React from 'react';
import { Button, Image } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import { Style } from './types';
import { STYLES } from '../../constants/routes';

type Props = {
  onDelete: (a: string) => void;
  style: Style;
};

const StyleRow = function StyleRow({
  onDelete,
  style: {
    order,
    id,
    name,
    tier,
    isPublished,
    // isReleased,
    thumbnailUrl,
    variationThumbnailUrl,
  },
}: Props) {
  const to = `${STYLES.to}/${id}`;
  return (
    <tr>
      <th>{order}</th>
      <th style={{ whiteSpace: 'nowrap' }}>
        <Link to={to}>{id}</Link>
      </th>
      <td>{name}</td>
      <td>
        <Image fallback="" size="4by3" src={thumbnailUrl} />
      </td>
      <td>
        <Image fallback="" size={64} src={variationThumbnailUrl} />
      </td>
      <td>{tier}</td>
      <td>{isPublished ? 'true' : 'false'}</td>
      <td>
        <div className="field is-grouped">
          <p className="control">
            <Link to={to}>
              <Button outlined color="warning">
                Edit
              </Button>
            </Link>
          </p>
          <p className="control">
            <Button outlined color="danger" onClick={() => onDelete(id)}>
              Delete
            </Button>
          </p>
        </div>
      </td>
    </tr>
  );
};

export default StyleRow;
