import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import {
  Container,
  Content,
  Heading,
  Hero,
  Notification,
  Progress,
} from 'react-bulma-components';

import { POSTS_FEATURED } from '../constants/routes';
import Auth from '../services/auth';

interface Props {
  googleWebClientId: string;
  login: () => void;
}

const Login = function Login({ googleWebClientId, login }: Props) {
  const [isLoggingIn, setLoggingIn] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string | null>(
    null
  );

  const onGoogleLoginSuccess = async (googleUser: any) => {
    const authResponse = googleUser.getAuthResponse(true);

    try {
      setLoggingIn(true);
      await Auth.login({
        idToken: authResponse.id_token,
      });
      login();

      setLoggingIn(false);
      window.location.href = POSTS_FEATURED.to;
    } catch (err: any) {
      const { data } = err && err.response;
      const { message } = data && data.status;

      setNotificationMessage(message);
      setLoggingIn(false);
    }
  };

  const onGoogleLoginFailure = ({ error }: { error: string }) => {
    // console.log('handleGoogleLoginFailure ERR', error);
    setNotificationMessage(error);
  };

  return (
    <Hero size="fullheight">
      <Hero.Body>
        <Container className="has-text-centered">
          {notificationMessage && (
            <Notification color="warning">
              <button
                aria-label="close"
                className="delete"
                onClick={() => setNotificationMessage(null)}
                type="button"
              />
              {notificationMessage}
            </Notification>
          )}
          <Content size="large">
            <Heading>Everbloom Admin CMS</Heading>
            <p>Login with your@everbloom.app account</p>
            {isLoggingIn ? (
              <Progress max={100} color="warning" />
            ) : (
              <GoogleLogin
                clientId={googleWebClientId}
                buttonText="Login"
                onFailure={onGoogleLoginFailure}
                onRequest={() => setNotificationMessage(null)}
                onSuccess={onGoogleLoginSuccess}
                theme="dark"
              />
            )}
          </Content>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default Login;
