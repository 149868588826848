import React, { useEffect, useRef, useState } from 'react';
import { Container, Notification, Progress } from 'react-bulma-components';
import { Link, useHistory, useParams } from 'react-router-dom';

import { AwsConfig } from '../../config';
import { ROOT, FEATUREDS } from '../../constants/routes';
import { uploadFile } from '../../utils';

import { documentId, useFirebase } from '../Firebase';
import Navigation from '../Navigation';

import FeaturedForm from './form';
import { CardBadgeUrls, converter, Featured, empty, IDs } from './types';

const FeaturedDetailsPage = function FeatureDetailsPage({
  config,
}: {
  config: AwsConfig;
}) {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [featured, setFeatured] = useState<Featured>({
    ...empty,
    id,
  });
  const [image, setImage] = useState<File | undefined>();

  const currentRef = useRef<Featured>({ ...empty, id });
  const [notificationMessage, setNotificationMessage] = useState<string | null>(
    null
  );

  const history = useHistory();

  const firebase = useFirebase()!;
  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const getFeatured = async () => {
      const doc = await firebase.db
        .collection('featuredTemplates')
        .withConverter(converter)
        .doc(id)
        .get();
      if (doc.exists && mounted) {
        const data = doc.data()!;
        // console.log('getFeatured', data);
        setFeatured(data);
        currentRef.current = data;
      } else {
        // TODO: show 404 - not found
        // eslint-disable-next-line no-console
        console.log('getFeatured !exists');
      }
      setLoading(false);
    };
    // eslint-disable-next-line no-console
    getFeatured().catch((error) => console.error('getFeatured ERR', error));
    return () => {
      mounted = false;
    };
  }, [firebase.db, id]);

  const onSave = async () => {
    // console.log('onSave');
    const type = 'featured';
    const directory = config.s3.directories[type];
    const cloudfrontBaseUrl = config.cloudfront.baseUrls.assets;

    const updateFeatured = async (featured1: Featured) => {
      const ref = firebase.db.collection('featuredTemplates').doc(id);
      await ref.update(featured1);
    };

    setLoading(true);
    try {
      const name = featured.title.toLowerCase();
      let saveImageAction;
      if (image) {
        saveImageAction = uploadFile({
          type,
          directory,
          id: name,
          file: image,
        });
      }
      const saveImageResult = await saveImageAction;
      if (saveImageResult && saveImageResult.status === 204) {
        const imageUrl = `${cloudfrontBaseUrl}/${directory}/${name}/${encodeURI(
          image!.name
        )}`;
        featured.imageUrl = imageUrl;
        setImage(undefined);
      }

      if (featured) {
        await updateFeatured(featured);
      }

      currentRef.current = featured;
    } catch (error: any) {
      // console.log('updateFeatured ERR', error);
      setNotificationMessage(error.message);
    }
    setLoading(false);
  };

  const onApply = async () => {
    // console.log('onApply');
    if (
      // eslint-disable-next-line no-alert
      !window.confirm(
        'You are about to apply Featured Discover Card shown on the Everbloom app.'
      )
    ) {
      return;
    }

    await onSave();

    const getFeatureds = async () => {
      const snapshot = await firebase.db
        .collection('featuredTemplates')
        .withConverter(converter)
        .where(documentId(), 'in', Object.keys(IDs))
        .get();

      const featureds: Featured[] = snapshot.docs.map((doc) => doc.data()!);

      const dictionary = featureds.reduce<{
        [x: string]: Featured;
      }>((acc, featured1) => {
        acc[featured1.id] = featured1;
        return acc;
      }, {});

      return dictionary;
    };

    const applyFeatureds = async (featureds: { [x: string]: Featured }) => {
      const new1 = featureds.new;
      const { current } = featureds;

      const currentRef1 = firebase.db
        .collection('featuredTemplates')
        .doc('current');
      await currentRef1.update(Object.assign(new1, { id: 'current' }));

      const backupRef = firebase.db
        .collection('featuredTemplates')
        .doc('backup');
      await backupRef.update(Object.assign(current, { id: 'backup' }));
    };

    setLoading(true);
    try {
      const featureds = await getFeatureds();
      await applyFeatureds(featureds);
      history.push(FEATUREDS.to);
    } catch (error: any) {
      // console.log('applyFeatureds ERR', error);
      setNotificationMessage(error.message);
    }
    setLoading(false);
  };

  const onChange =
    (fieldName: keyof Featured) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      let { value } = event.currentTarget;
      if (typeof featured[fieldName] !== 'string') {
        value = JSON.parse(value);
      }
      // console.log(fieldName, value);
      const values = {
        [fieldName]: value,
      };
      if (fieldName === 'title') {
        values.cardTitle = value;
      } else if (fieldName === 'tier') {
        values.cardBadgeUrl = CardBadgeUrls[value];
      }
      const updated: Featured = { ...featured, ...values };
      setFeatured(updated);
    };

  const onFileChange = (event: React.FormEvent<HTMLInputElement>) => {
    // console.log(event.currentTarget.id, event.currentTarget.value);
    const { files } = event.currentTarget;
    setImage(files && files.length > 0 ? files[0] : undefined);
  };

  return (
    <>
      <Navigation />
      <Container>
        <br />
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li>
              <Link to={FEATUREDS.to}>{FEATUREDS.title}</Link>
            </li>
            <li className="is-active">
              <Link to={`${FEATUREDS}/${id}`}>{featured.id}</Link>
            </li>
          </ul>
        </nav>
        {notificationMessage && (
          <Notification color="warning">
            <button
              aria-label="close"
              className="delete"
              onClick={() => setNotificationMessage(null)}
              type="button"
            />
            {notificationMessage}
          </Notification>
        )}

        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <FeaturedForm
            featured={featured}
            image={image}
            onApply={onApply}
            onChange={onChange}
            onFileChange={onFileChange}
            onSave={onSave}
            isEditable={featured.id === IDs.new}
          />
        )}
        <br />
      </Container>
    </>
  );
};

export default FeaturedDetailsPage;
