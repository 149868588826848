import React from 'react';
import { Button, Columns, Form, Image } from 'react-bulma-components';

import { Profile } from '../../types';
import {
  AdditionalsRaw,
  Socials,
  TagsRaw,
  YouTube,
  YouTubeNumbersFlattened,
} from '../../types/profile';
import NumberInputColumn from '../Form/NumberInputColumn';
import { Files } from './types';

type Props = {
  files: Files;
  onChange: (
    fieldName: keyof Profile
  ) => (
    event: React.FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  onFileChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onNumbersChange: (
    fieldName: keyof YouTubeNumbersFlattened
  ) => (
    event: React.FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  onAdditionalsChange: (
    fieldName: keyof AdditionalsRaw
  ) => (
    event: React.FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  onSocialsChange: (
    fieldName: keyof Socials
  ) => (
    event: React.FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  onTagsChange: (
    fieldName: keyof TagsRaw
  ) => (
    event: React.FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  onYouTubeChange: (
    fieldName: keyof YouTube
  ) => (
    event: React.FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  onSave: () => void;
  profile: Profile;
  isNew?: boolean;
};

const ProfileForm = function ProfileForm({
  files,
  onChange,
  onFileChange,
  onAdditionalsChange,
  onNumbersChange,
  onSocialsChange,
  onTagsChange,
  onYouTubeChange,
  onSave,
  profile,
  isNew,
}: Props) {
  const { avatar, cover, feature } = files;
  const {
    userName,
    fullName,
    description,
    header,
    quote,
    avatarUrl,
    coverUrl,
    featureUrl,
    // isYouTuber,
    socials,
    statistics,
    additionalsRaw,
    tagsRaw,
    numbers: numbersF,
  } = profile;
  const { bottoms, tops } = additionalsRaw || {};
  const {
    pastSemesterEarnings,
    pastYearEarnings,
    yearlyEstimateEarnings,
    subscribersCount,
    pastSemesterViewsCount,
    pastYearViewsCount,
    totalViewsCount,
    yearlyEstimateHighViewsCount,
    yearlyEstimateLowViewsCount,
    pastYearWatchHours,
    youtubeStartedDate,
  } = numbersF || {};
  const { discord, instagram, tiktok, twitter, website, youtube } =
    socials || {};
  const { channelUrl, channelUrlAlt, videoId } = youtube || {};
  const { youtube: numbers } = statistics || {};
  const { createdAt } = numbers || {};

  const { serverUrl: discordServerUrl } = discord || {};
  const { pageUrl: instagramPageUrl } = instagram || {};
  const { profileUrl: tiktokProfileUrl } = tiktok || {};
  const { handleUrl: twitterHandleUrl } = twitter || {};
  const { homePageUrl: websiteHomePageUrl } = website || {};

  const { category, stage } = tagsRaw || {};

  return (
    <>
      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>UserName</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Input
                id="userName"
                type="text"
                placeholder="username"
                value={userName || ''}
                onChange={onChange('userName')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Full Name</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Input
                id="fullName"
                type="text"
                placeholder="Full Name"
                value={fullName || ''}
                onChange={onChange('fullName')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Quote</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Input
                id="quote"
                type="text"
                placeholder="Quote"
                value={quote || ''}
                onChange={onChange('quote')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Header</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Input
                id="header"
                type="text"
                placeholder="Header"
                value={header || ''}
                onChange={onChange('header')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Description</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Textarea
                id="description"
                placeholder="Description"
                value={description || ''}
                onChange={onChange('description')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Top Section</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Textarea
                id="tops"
                value={tops || ''}
                onChange={onAdditionalsChange('tops')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Bottom Section</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Textarea
                id="bottoms"
                value={bottoms || ''}
                onChange={onAdditionalsChange('bottoms')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      {/*
      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Is YouTuber?</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Select
                id="isYouTuber"
                disabled={isNew}
                value={JSON.stringify(isYouTuber || false)}
                onChange={onChange('isYouTuber')}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Select>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>
      */}

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Tags</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Columns>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>Category</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="category"
                      type="text"
                      placeholder="Comedy/Story Telling/Travel/News/etc."
                      value={category || ''}
                      onChange={onTagsChange('category')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>Stage</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="stage"
                      type="text"
                      placeholder="Rising Star/YouTube Veteran/Steady Growth/etc."
                      value={stage || ''}
                      onChange={onTagsChange('stage')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>YouTube</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Columns>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>Channel 1</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="channelUrl"
                      type="text"
                      placeholder="https://youtube.com/@everbloomapp"
                      value={channelUrl || ''}
                      onChange={onYouTubeChange('channelUrl')}
                    />
                  </Form.Control>
                </Form.Field>
                <Form.Help color="info">
                  <i>Channel 1</i>&nbsp;is required when either
                  <i>&nbsp;Channel 2</i> or <i>Banner Video</i>&nbsp;is
                  provided.
                </Form.Help>
              </Columns.Column>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>Channel 2</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="channelUrlAlt"
                      type="text"
                      placeholder="https://youtube.com/@everbloomapp"
                      value={channelUrlAlt || ''}
                      onChange={onYouTubeChange('channelUrlAlt')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>Banner Video</Button>
                  </Form.Control>
                  <Form.Control>
                    <Button isStatic>youtube.com/watch?v=</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="videoId"
                      type="text"
                      placeholder="Video ID"
                      value={videoId || ''}
                      onChange={onYouTubeChange('videoId')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>
                      Since <i>&nbsp;YYYY-MM-DD</i>
                    </Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="youtubeStartedDate"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      value={
                        youtubeStartedDate ||
                        (createdAt &&
                          new Date(createdAt).toISOString().split('T')[0]) ||
                        ''
                      }
                      onChange={onNumbersChange('youtubeStartedDate')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Numbers</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Columns>
              <NumberInputColumn
                id="subsribersCount"
                placeholder="Total"
                value={subscribersCount}
                onChange={onNumbersChange('subscribersCount')}
                info="Subscribers"
              />
              <NumberInputColumn
                id="pastYearWatchHours"
                placeholder="Past Year"
                value={pastYearWatchHours}
                onChange={onNumbersChange('pastYearWatchHours')}
                info={
                  <>
                    Watch Hours <i>&nbsp;Past Year</i>
                  </>
                }
              />
            </Columns>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Earnings</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Columns>
              <NumberInputColumn
                id="pastSemesterEarnings"
                placeholder="Past 6 Months (USD)"
                value={pastSemesterEarnings}
                onChange={onNumbersChange('pastSemesterEarnings')}
                info={
                  <>
                    <i>Past 6 Months</i>&nbsp;USD
                  </>
                }
              />
              <NumberInputColumn
                id="pastYearEarnings"
                info={
                  <>
                    <i>Past Year</i>&nbsp;USD
                  </>
                }
                placeholder="Past Year (USD)"
                value={pastYearEarnings}
                onChange={onNumbersChange('pastYearEarnings')}
              />
              <NumberInputColumn
                id="yearlyEstimateEarnings"
                info={
                  <>
                    <i>Yearly Estimate</i>&nbsp;USD
                  </>
                }
                placeholder="Yearly Estimate (USD)"
                value={yearlyEstimateEarnings}
                onChange={onNumbersChange('yearlyEstimateEarnings')}
              />
            </Columns>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Views</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Columns>
              <NumberInputColumn
                id="totalViewsCount"
                placeholder="Total"
                value={totalViewsCount}
                onChange={onNumbersChange('totalViewsCount')}
                info={<i>Total</i>}
              />
              <NumberInputColumn
                id="pastSemesterViewsCount"
                placeholder="Past 6 Months"
                value={pastSemesterViewsCount}
                onChange={onNumbersChange('pastSemesterViewsCount')}
                info={<i>Past 6 Months</i>}
              />
              <NumberInputColumn
                id="pastYearViewsCount"
                placeholder="Past Year"
                value={pastYearViewsCount}
                onChange={onNumbersChange('pastYearViewsCount')}
                info={<i>Past Year</i>}
              />
            </Columns>
            <Columns>
              <NumberInputColumn
                id="yearlyEstimateLowViewsCount"
                placeholder="Low Estimate"
                value={yearlyEstimateLowViewsCount}
                onChange={onNumbersChange('yearlyEstimateLowViewsCount')}
                info={
                  <>
                    <i>Yearly</i>&nbsp;Views <b>&nbsp;Low Estimate</b>
                  </>
                }
              />
              <NumberInputColumn
                id="yearlyEstimateHighViewsCount"
                placeholder="High Estimate"
                value={yearlyEstimateHighViewsCount}
                onChange={onNumbersChange('yearlyEstimateHighViewsCount')}
                info={
                  <>
                    <i>Yearly</i>&nbsp;Views<b>&nbsp;High Estimate</b>
                  </>
                }
              />
            </Columns>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Socials</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Help color="info">
              <i>YouTube</i>&nbsp;section above must be filled when one of these
              other<i>&nbsp;Socials</i>&nbsp;is provided.
            </Form.Help>
            <Columns>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>Twitter</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="twitter"
                      type="text"
                      placeholder="https://twitter.com/everbloomapp"
                      value={twitterHandleUrl || ''}
                      onChange={onSocialsChange('twitter')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>Instagram</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="instagram"
                      type="text"
                      placeholder="https://instagram.com/everbloom"
                      value={instagramPageUrl || ''}
                      onChange={onSocialsChange('instagram')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>Discord</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="discord"
                      type="text"
                      placeholder="https://discord.gg/everbloom"
                      value={discordServerUrl || ''}
                      onChange={onSocialsChange('discord')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>TikTok</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="tiktok"
                      type="text"
                      placeholder="https://tiktok.com/@everbloom.app"
                      value={tiktokProfileUrl || ''}
                      onChange={onSocialsChange('tiktok')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>Website</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="website"
                      type="text"
                      placeholder="https://everbloom.app"
                      value={websiteHomePageUrl || ''}
                      onChange={onSocialsChange('website')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Avatar</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          {isNew ? (
            <Form.Help color="info">Upload later after creation</Form.Help>
          ) : (
            <Form.Field>
              <Columns>
                <Columns.Column>
                  <Image
                    src={
                      (avatar && URL.createObjectURL(avatar)) || avatarUrl || ''
                    }
                  />
                </Columns.Column>
                <Columns.Column>
                  <Form.InputFile
                    boxed
                    color="warning"
                    fullwidth
                    className={avatar ? 'has-name' : ''}
                    label="Upload & Change Avatar"
                    // @ts-ignore
                    inputProps={{ id: 'avatar' }}
                    filename={avatar ? avatar.name : undefined}
                    onChange={onFileChange}
                  />
                </Columns.Column>
              </Columns>
            </Form.Field>
          )}
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Cover</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          {isNew ? (
            <Form.Help color="info">Upload later after creation</Form.Help>
          ) : (
            <Form.Field>
              <Columns>
                <Columns.Column>
                  <Image
                    src={
                      (cover && URL.createObjectURL(cover)) || coverUrl || ''
                    }
                  />
                </Columns.Column>
                <Columns.Column>
                  <Form.InputFile
                    boxed
                    color="warning"
                    fullwidth
                    className={cover ? 'has-name' : ''}
                    label="Upload & Change Cover"
                    // @ts-ignore
                    inputProps={{ id: 'cover' }}
                    filename={cover ? cover.name : undefined}
                    onChange={onFileChange}
                  />
                </Columns.Column>
              </Columns>
            </Form.Field>
          )}
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Feature</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          {isNew ? (
            <Form.Help color="info">Upload later after creation</Form.Help>
          ) : (
            <Form.Field>
              <Columns>
                <Columns.Column>
                  <Image
                    src={
                      (feature && URL.createObjectURL(feature)) ||
                      featureUrl ||
                      ''
                    }
                  />
                </Columns.Column>
                <Columns.Column>
                  <Form.InputFile
                    boxed
                    color="warning"
                    fullwidth
                    className={feature ? 'has-name' : ''}
                    label="Upload & Change Feature"
                    // @ts-ignore
                    inputProps={{ id: 'feature' }}
                    filename={feature ? feature.name : undefined}
                    onChange={onFileChange}
                  />
                </Columns.Column>
              </Columns>
            </Form.Field>
          )}
        </Form.Field.Body>
      </Form.Field>

      <br />

      <Form.Field horizontal>
        <Form.Field.Label />
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Button fullwidth color="primary" onClick={onSave}>
                {isNew ? 'Create' : 'Update'}
              </Button>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>
    </>
  );
};

ProfileForm.defaultProps = {
  onFileChange: () => {},
  isNew: false,
};

export default ProfileForm;
