import React, { FormEvent, KeyboardEventHandler } from 'react';
import { Button, Form } from 'react-bulma-components';

import { UserSearchField, UserSearchQuery } from '../../types/api';

type Info = {
  expected: string;
  negative: string;
  negativeAlt?: string;
  positive: string;
  positiveAlt?: string;
};

type Props = {
  fieldType: string;
  query: UserSearchQuery;
  onSearch: () => void;
  onTextChange: (event: FormEvent<HTMLInputElement>) => void;
  onTypeChange: (event: FormEvent<HTMLSelectElement>) => void;
};

const UserSearchForm = function UserSearchForm({
  fieldType,
  query,
  onSearch,
  onTextChange,
  onTypeChange,
}: Props) {
  const { emailAddress, ethAddress, phoneNumber } = query;
  let value = emailAddress;
  let info: Info = {
    expected: 'hDTZVM4bgRNjRqMNNqJ2YeVtGoC2',
    negative: 'hdtzvm',
    negativeAlt: 'DTZVM',
    positive: 'hDTZ',
    positiveAlt: 'hD',
  };
  if (fieldType === UserSearchField.Email) {
    info = {
      expected: 'werick@everbloom.app',
      negative: 'erick',
      negativeAlt: 'erick@everbloom',
      positive: 'we',
    };
  } else if (fieldType === UserSearchField.Wallet) {
    value = ethAddress;
    info = {
      expected: '0x9c22fF48889B324dBd5Ce69Ba849a74CF220990F',
      negative: 'c22f',
      positive: '0x9c22',
      positiveAlt: '9c22',
    };
  } else if (fieldType === UserSearchField.Phone) {
    value = phoneNumber;
    info = {
      expected: '+6282260336043',
      negative: '6282',
      positive: '+6282',
      positiveAlt: '6282',
    };
  }

  const { expected, negative, negativeAlt, positive, positiveAlt } = info;
  const help = (
    <>
      Press enter to begin the search. Use <i>{positive}</i>
      {positiveAlt && (
        <>
          &nbsp;or just&nbsp;<i>{positiveAlt}</i>
        </>
      )}
      &nbsp;for&nbsp;
      <b>{expected}</b>, but <i>{negative}</i>
      {negativeAlt && (
        <>
          &nbsp;or&nbsp;<i>{negativeAlt}</i>
        </>
      )}
      &nbsp;<b>won&apos;t</b>&nbsp;match.
    </>
  );

  const onEnter: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { key } = event;
    if (key === 'Enter') {
      onSearch();
    }
  };

  return (
    <Form.Field className="is-horizontal">
      <Form.Field.Body>
        <Form.Field kind="addons">
          <Form.Control>
            <Form.Select color="info" onChange={onTypeChange}>
              {Object.entries(UserSearchField).map(([k, v]) => (
                <option key={k} value={v}>
                  {k}
                </option>
              ))}
            </Form.Select>
          </Form.Control>
          <Form.Control fullwidth>
            <Form.Input
              value={value}
              onChange={onTextChange}
              onKeyDown={onEnter}
            />
            <Form.Help color="info">{help}</Form.Help>
          </Form.Control>
          <Form.Control>
            <Button color="info" onClick={onSearch}>
              Search
            </Button>
          </Form.Control>
        </Form.Field>
      </Form.Field.Body>
    </Form.Field>
  );
};

export default UserSearchForm;
