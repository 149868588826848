import React, { useState, useEffect } from 'react';
import { Button, Progress } from 'react-bulma-components';
import { useParams } from 'react-router-dom';

import {
  CREATORS,
  CHARGES,
  CHARGE_NEW,
  PROFILES,
  ROOT,
  CHARGE_EDIT,
} from '../../constants/routes';
import { useNotification } from '../../contexts/notification';
import Api from '../../services/api';
import { emptyProfile as empty, Profile } from '../../types';
import {
  emptyChargeRequest,
  ChargeRequest,
  ChargeResult,
} from '../../types/api';
import {
  PaymentMethodMetadata,
  PaymentProfile,
  RevenueDetails,
} from '../../types/payment';
import Layout, { Path } from '../Layout';

import ChargeDetails from './details';
import ChargeForm from './form';

type Params = { paymentTransferId?: string; userName: string };

const ChargeNewPage: React.FC = function ChargeNewPage() {
  const { paymentTransferId, userName } = useParams<Params>();
  const isNew = !paymentTransferId;

  const [isLoadingCharge, setLoadingCharge] = useState<boolean>(false);
  const [isLoadingProfile, setLoadingProfile] = useState<boolean>(false);
  const [isMissingInfo, setMissingInfo] = useState<boolean>(false);
  const { paymentTransfer: emptyPaymentTransfer } = emptyChargeRequest;
  const [request, setRequest] = useState<ChargeRequest>({
    ...emptyChargeRequest,
    paymentTransfer: { ...emptyPaymentTransfer, id: paymentTransferId },
    dryRun: isNew,
  });
  const [profile, setProfile] = useState<Profile>(empty);
  const { setNotification } = useNotification();
  const [result, setResult] = useState<ChargeResult | undefined>();

  const onMethodChange =
    (fieldName: keyof PaymentMethodMetadata) =>
    (event: React.FormEvent<HTMLInputElement>) => {
      const { value: v } = event.currentTarget;
      let value: any = v;
      const { paymentMethod: pm } = request;
      const { metadata: meta } = pm;
      const fieldType = meta && typeof meta[fieldName];
      if (fieldType !== 'undefined' && fieldType !== 'string') {
        value = JSON.parse(value);
      }
      const request0: ChargeRequest = {
        ...request,
        paymentMethod: {
          ...pm,
          metadata: { ...meta, [fieldName]: value },
        },
      };
      setRequest({ ...request0, dryRun: isNew });
    };
  const onOthersChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    let request0 = request;
    request0 = { ...request0, quarterNumber: parseInt(value, 10) };
    setRequest({ ...request0, dryRun: isNew });
  };
  const onProfileChange =
    (fieldName: keyof PaymentProfile) =>
    (event: React.FormEvent<HTMLInputElement>) => {
      const { value: v } = event.currentTarget;
      let value: any = v;
      const { paymentMethod: pm } = request;
      const { paymentProfile: pp } = pm;
      const fieldType = pp && typeof pp[fieldName];
      if (fieldType !== 'undefined' && fieldType !== 'string') {
        value = JSON.parse(value);
      }
      const request0: ChargeRequest = {
        ...request,
        paymentMethod: {
          ...pm,
          paymentProfile: { ...pp, [fieldName]: value },
        },
      };
      setRequest({ ...request0, dryRun: isNew });
    };
  const onRevenueChange =
    (fieldName: keyof RevenueDetails) =>
    (event: React.FormEvent<HTMLInputElement>) => {
      const { value: v } = event.currentTarget;
      let value: any = v;
      const { paymentTransfer: pt } = request;
      const { revenueDetails } = pt;
      const fieldType = revenueDetails && typeof revenueDetails[fieldName];
      if (fieldType !== 'undefined' && fieldType !== 'string') {
        value = JSON.parse(value);
      }
      const request0 = {
        ...request,
        paymentTransfer: {
          ...pt,
          revenueDetails: { ...revenueDetails, [fieldName]: value },
        },
      };
      setRequest({ ...request0, dryRun: isNew });
    };

  const onCreate = async () => {
    const call = async () => {
      setNotification(undefined);
      let confirmed = request.dryRun;
      if (!confirmed) {
        // eslint-disable-next-line no-alert
        const prompt = window.prompt(
          `You are about to charge ${userName} for the first time and schedule consecutive automatic charges. Make sure all the preview data is all correct. \n\nType 'charge ${userName}' below to continue.`
        );
        confirmed = prompt === `charge ${userName}`;
        if (!confirmed) {
          setNotification('Incorrect confirmation message. Please try again.');
        }
      }
      if (confirmed) {
        setLoadingCharge(true);
        const result0 = await Api.createCharge(request);
        const { paymentTransfer: pt } = result0;
        setRequest({ ...request, paymentTransfer: pt, dryRun: false });
        setResult(result0);
        setLoadingCharge(false);
        setMissingInfo(false);
      }
    };

    call().catch((err) => {
      const { data, status } = err.response || {};
      const { message } = data?.status || err;
      let { code } = data?.status || {};
      code ||= status;
      setNotification(message);
      setLoadingCharge(false);
      setMissingInfo(code === 412);
    });
  };

  const onSave = async () => {
    const call = async () => {
      setNotification(undefined);
      // eslint-disable-next-line no-alert
      const confirmed = window.confirm(
        `You are about to update the quarterly automatic charging of this creator.`
      );
      if (confirmed) {
        setLoadingCharge(true);
        const result0 = await Api.updatePaymentTransfer(
          request.paymentTransfer
        );
        const { paymentTransfer: pt } = result0;
        setRequest({ ...request, paymentTransfer: pt, dryRun: false });
        setResult(result0);
        setLoadingCharge(false);
      }
    };

    call().catch((err) => {
      const { data } = err.response || {};
      const { message } = data?.status || err;
      setNotification(message);
      setLoadingCharge(false);
    });
  };

  useEffect(() => {
    let mounted = true;
    setLoadingProfile(true);
    setNotification(undefined);

    const fetchAndSetData = async () => {
      const [profile0, pt] = await Promise.all([
        Api.getProfile({ userName }),
        paymentTransferId
          ? Api.getPaymentTransfer({ id: paymentTransferId })
          : undefined,
      ]);
      if (mounted) {
        setProfile(profile0);
        const { id: creatorId } = profile0;
        const paymentTransfer =
          result?.paymentTransfer || request.paymentTransfer;
        const { revenueDetails: revenue } = paymentTransfer || {};
        let request0: ChargeRequest = {
          ...request,
          paymentTransfer: {
            ...paymentTransfer,
            revenueDetails: { ...revenue, creatorId },
          },
        };
        if (pt) {
          const { revenueDetails } = pt;
          request0 = {
            ...request0,
            paymentTransfer: {
              ...pt,
              senderId: creatorId,
              revenueDetails: { ...revenueDetails, creatorId },
            },
            dryRun: false,
          };
          setResult({ ...result, paymentTransfer: pt });
        }
        setRequest(request0);
        setLoadingProfile(false);
      }
    };

    fetchAndSetData().catch((err) => {
      const { data } = err.response || {};
      const { message } = data?.status || err;
      setNotification(message);
      setLoadingProfile(false);
    });

    return () => {
      mounted = false;
    };
  }, [userName, paymentTransferId]);

  const breadcrumbs: Path[] = [
    { route: ROOT },
    { route: CREATORS },
    { route: { to: `${PROFILES.to}/${userName}`, title: userName } },
    {
      route: {
        to: `${CHARGES.to}/${userName}/${isNew ? 'new' : paymentTransferId}`,
        title: isNew ? CHARGE_NEW.title : CHARGE_EDIT.title,
      },
      active: true,
    },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <ChargeForm
        onMethodChange={onMethodChange}
        onOthersChange={onOthersChange}
        onProfileChange={onProfileChange}
        onRevenueChange={onRevenueChange}
        request={request}
        profile={profile}
        isLoadingProfile={isLoadingProfile}
        isMissingInfo={isMissingInfo}
      />

      <br />

      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoadingCharge ? (
        <Progress max={100} color="info" />
      ) : result ? (
        <ChargeDetails
          paymentTransfer={result.paymentTransfer}
          paymentMethod={result.paymentMethod}
          period={result.period}
        />
      ) : null}

      <br />

      <Button
        fullwidth
        color={request.dryRun ? 'primary' : 'warning'}
        loading={isLoadingCharge}
        onClick={isNew ? onCreate : onSave}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        <b>{isNew ? (request.dryRun ? 'Preview' : 'Commit') : 'Save'}</b>
      </Button>
    </Layout>
  );
};

export default ChargeNewPage;
