import React, { useState } from 'react';
import { Container, Button, Progress } from 'react-bulma-components';
import { Link } from 'react-router-dom';

import { LIMIT } from '../../constants/api';
import { FEED, ROOT } from '../../constants/routes';
import { usePosts } from '../../hooks';

import Navigation from '../Navigation';
import { PostsCollection } from '../Post';
import Pagination from '../Pagination';

type Props = { featured?: boolean };

const FeedCollectionPage: React.FC<Props> = function FeedCollectionPage({
  featured: featured0,
}: Props) {
  const {
    curated,
    featured: featured1,
    isLoading,
    posts,
    setAfter,
  } = usePosts(featured0);
  const featured = featured0 || featured1;
  const [mobile, setMobile] = useState(false);

  return (
    <>
      <Navigation />
      <Container>
        <br />
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li className="is-active">
              <Link to={FEED.to}>{FEED.title}</Link>
            </li>
            <li className="is-active">
              <Link to={FEED.to}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {featured ? 'Featured' : curated === false ? 'All' : 'Public'}
              </Link>
            </li>
          </ul>
        </nav>
        <Button.Group>
          <Button
            color="info"
            disabled={!mobile}
            onClick={() => setMobile(false)}
          >
            Full view
          </Button>
          <Button
            color="success"
            disabled={mobile}
            onClick={() => setMobile(true)}
          >
            Mobile view
          </Button>
        </Button.Group>
        <Pagination
          nextAfter={posts?.length ? posts[posts.length - 1].id : undefined}
          hasNextPage={posts?.length === LIMIT}
          onChange={(after) => setAfter(after)}
        >
          {isLoading ? (
            <Progress max={100} color="info" />
          ) : (
            <PostsCollection mobile={mobile} posts={posts} />
          )}
        </Pagination>
      </Container>
    </>
  );
};

FeedCollectionPage.defaultProps = { featured: false };

export default FeedCollectionPage;
