import { firebase } from '../Firebase';

export interface Story {
  order: number;
  id: string;
  type: string;
  imageUrl: string;
  videoUrl: string;
  thumbImageUrl: string;
  thumbVideoUrl: string;
  isPublished: boolean;
  thumbVideoMp4Urls?: VideoUrls;
  videoMp4Urls?: VideoUrls;
}

interface VideoUrls {
  highRes: string;
  lowRes: string;
}

export const empty: Story = {
  order: 0,
  id: '',
  type: '',
  imageUrl: '',
  videoUrl: '',
  thumbImageUrl: '',
  thumbVideoUrl: '',
  isPublished: false,
};

export interface VideoFiles {
  thumbnailVideo: File | null;
  thumbnailVideoImage: File | null;
  video: File | null;
  videoImage: File | null;
}

export const emptyVideoFiles: VideoFiles = {
  thumbnailVideo: null,
  thumbnailVideoImage: null,
  video: null,
  videoImage: null,
};

export const converter = {
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)!;
    const story: Story = {
      ...empty,
      id: snapshot.id,
      ...data,
    };
    return story;
  },
  toFirestore(story: Story): firebase.firestore.DocumentData {
    return {
      ...story,
    };
  },
};
