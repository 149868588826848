import React from 'react';
import { Link } from 'react-router-dom';

import { PROFILES } from '../../constants/routes';
import { Profile } from '../../types';

type Props = { profile: Profile };

const ProfileRow: React.FC<Props> = function ProfileRow({ profile }) {
  const { id, fullName, userName } = profile;
  const to = `${PROFILES.to}/${userName}`;
  const charges = `/charges/${userName}/new`;
  const payouts = `/payouts/${userName}/new`;
  return (
    <tr>
      <td>
        <Link to={to} style={{ fontSize: '.9em' }}>
          <i>{id}</i>
        </Link>
      </td>
      <th style={{ whiteSpace: 'nowrap' }}>
        <Link to={to}>{`${fullName} | ${userName}`}</Link>
      </th>
      <th>
        <Link to={charges}>
          <i>Charges</i>
        </Link>{' '}
        |{' '}
        <Link to={payouts}>
          <i>Payouts</i>
        </Link>
      </th>
    </tr>
  );
};

export default ProfileRow;
