import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import Firebase from './firebase';

export interface FirebaseProps {
  firebase: Firebase;
}

interface Props extends FirebaseProps {
  children: React.ReactNode;
}

const FirebaseContext = React.createContext<Firebase | null>(null);

export const FirebaseProvider = function FirebaseProvider({
  children,
  firebase: firebase1,
}: Props) {
  return (
    <FirebaseContext.Provider value={firebase1}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => useContext(FirebaseContext);

export const useFirebaseUser = () => {
  const firebase1 = useFirebase()!;
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null);

  firebase1.auth.onAuthStateChanged((authUser) => {
    setFirebaseUser(authUser);
  });

  return firebaseUser;
};

export const useFirebaseToken = () => {
  const user = useFirebaseUser();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    if (!user) {
      return;
    }
    user.getIdToken().then((token1) => {
      setToken(token1);
    });
  }, [user]);

  return [token];
};

export function withFirebase<P extends FirebaseProps>(
  Component: React.ComponentType<P>
) {
  return function withFirebase1(
    props: Pick<P, Exclude<keyof P, keyof FirebaseProps>>
  ) {
    return (
      /* eslint-disable react/jsx-props-no-spreading */
      <FirebaseContext.Consumer>
        {(firebase1) => <Component {...(props as P)} firebase={firebase1} />}
      </FirebaseContext.Consumer>
      /* eslint-enable react/jsx-props-no-spreading */
    );
  };
}

export default FirebaseContext;
