import React, { useState, useEffect } from 'react';
import {
  Columns,
  Container,
  Notification,
  Progress,
} from 'react-bulma-components';
import { Link } from 'react-router-dom';

import { LIMIT as PAGE_LIMIT } from '../../constants/api';
import { PROFILES, PROFILE_NEW, ROOT } from '../../constants/routes';
import { useCurated } from '../../hooks';
import Api from '../../services/api';
import { Profile } from '../../types';

import Navigation from '../Navigation';

import ProfileCard from './card';
import Pagination from '../Pagination';

const ProfilesCollectionPage: React.FC =
  function ProfilesCollectionPage(): JSX.Element {
    const curated = useCurated();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [notificationMessage, setNotificationMessage] = useState<
      string | null
    >(null);
    const [profiles, setProfiles] = useState<Profile[]>([]);

    const addToCuration = async (profile: Profile) => {
      const { id: userId, userName } = profile;
      if (
        // eslint-disable-next-line no-alert
        window.confirm(`You are about to add ${userName} as a curated profile.`)
      ) {
        try {
          await Api.addToCuration({ userId }); // TODO: better to update the state instead of reloading the whole page
          window.location.reload();
        } catch (error: any) {
          // console.error('addToCuration ERR', error);
          setNotificationMessage(error.message);
        }
      }
    };

    async function removeFromCuration(profile: Profile) {
      const { id: userId, userName } = profile;
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          `You are about to remove ${userName} from curated profiles.`
        )
      ) {
        try {
          await Api.removeFromCuration({ userId });
          // TODO: better to update the state instead of reloading the whole page
          window.location.reload();
        } catch (error: any) {
          // console.error('removeFromCuration ERR', error);
          setNotificationMessage(error.message);
        }
      }
    }

    async function onAction(profile: Profile) {
      return curated ? removeFromCuration(profile) : addToCuration(profile);
    }

    const fetchProfiles = async (after: string) => {
      setLoading(true);
      const params = {};
      if (curated !== null) {
        Object.assign(params, { curated });
      }
      const profiles1 = await Api.listProfiles({
        after,
        curated: curated || undefined,
      });

      setProfiles(profiles1);
      setLoading(false);
    };

    useEffect(() => {
      let mounted = true;
      setLoading(true);
      const fetchAndSetData = async () => {
        const params = {};
        if (curated !== null) {
          Object.assign(params, { curated });
        }
        const profiles1 = await Api.listProfiles({
          curated: curated || undefined,
        });

        if (mounted) {
          setProfiles(profiles1);
          setLoading(false);
        }
      };

      fetchAndSetData().catch((error) =>
        // eslint-disable-next-line no-console
        console.error('fetchAndSetData ERR', error)
      );

      return () => {
        mounted = false;
      };
    }, [curated]);

    return (
      <>
        <Navigation />
        <Container>
          <br />
          <nav className="breadcrumb">
            <ul>
              <li>
                <Link to={ROOT.to}>{ROOT.title}</Link>
              </li>
              <li className="is-active">
                <Link to={PROFILES.to}>{PROFILES.title}</Link>
              </li>
              <li className="is-active">
                <Link to={PROFILES.to}>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {curated === null
                    ? 'All'
                    : curated === true
                    ? 'Curated'
                    : 'Uncurated'}
                </Link>
              </li>
              {curated === null && (
                <li>
                  <Link to={`${PROFILE_NEW.to}/new`}>New</Link>
                </li>
              )}
            </ul>
          </nav>
          {notificationMessage && (
            <Notification color="danger" colorVariant="light">
              <button
                aria-label="close"
                className="delete"
                onClick={() => setNotificationMessage(null)}
                type="button"
              />
              {notificationMessage}
            </Notification>
          )}
          <Pagination
            nextAfter={
              profiles?.length ? profiles[profiles.length - 1].id : undefined
            }
            hasNextPage={profiles?.length === PAGE_LIMIT}
            onChange={(after) => fetchProfiles(after)}
          >
            {isLoading ? (
              <Progress max={100} color="info" />
            ) : (
              <Columns>
                {!profiles || profiles.length <= 0
                  ? 'No users in this list'
                  : profiles.map((profile) => (
                      <ProfileCard
                        key={profile.id}
                        profile={profile}
                        onAction={() => onAction(profile)}
                      />
                    ))}
              </Columns>
            )}
          </Pagination>
        </Container>
      </>
    );
  };

export default ProfilesCollectionPage;
