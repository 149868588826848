import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

import { ROOT, MUSIC, MUSIC_API } from '../../constants/routes';
import { useLoading } from '../../contexts/loading';
import { useNotification } from '../../contexts/notification';
import { useCurated } from '../../hooks';

import Layout, { Path } from '../Layout';
import MusicTable from './table';

import { Music } from './types';

const MusicListPage: React.FC = function MusicListPage() {
  const published = useCurated('published');
  const breadcrumbs: Path[] = [
    { route: ROOT },
    {
      route: MUSIC,
      active: true,
      titleAlt:
        // eslint-disable-next-line no-nested-ternary
        published === null
          ? 'All'
          : published === true
          ? 'Published'
          : 'Unpublished',
    },
  ];

  const { setLoading } = useLoading();
  const { setNotification } = useNotification();
  const firstLoadRef = useRef(false);
  const [list, setList] = useState<Music[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (firstLoadRef.current && !(isLoadingMore && hasMore)) {
      return () => {};
    }

    if (!firstLoadRef.current) setLoading(true);

    let mounted = true;
    const fetchAndSetData = async () => {
      const limit = 20;
      const params = { limit };
      if (published !== null) {
        Object.assign(params, { published });
      }
      const { id: after } = list[list.length - 1] || {};
      if (after) {
        Object.assign(params, { after });
      }

      const { data } = await axios.get(MUSIC_API.to, { params });

      const { music } = data && data.data;
      if (mounted) {
        setHasMore(music.length >= limit);
        setList((previousList) => [...previousList, ...music]);
        setLoading(false);
        setLoadingMore(false);
      }
    };

    fetchAndSetData().catch((err) => {
      const { data } = err?.response || {};
      const message = data?.status?.message || err.message;
      setNotification(message);
    });

    firstLoadRef.current = true;

    return () => {
      mounted = false;
    };
  }, [
    hasMore,
    isLoadingMore,
    list,
    published,
    setHasMore,
    setLoading,
    setLoadingMore,
    setNotification,
  ]);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <MusicTable
        list={list}
        hasMore={hasMore}
        isLoadingMore={isLoadingMore}
        onLoadMore={() => setLoadingMore(true)}
      />
    </Layout>
  );
};

export default MusicListPage;
