import React, { useContext, useMemo } from 'react';
import { OptionalString } from '../types';

type Props = React.PropsWithChildren<{
  notification: OptionalString;
  setNotification: (a: OptionalString) => void;
}>;

const NotificationContext = React.createContext<Props>({
  notification: undefined,
  setNotification: () => {},
});

export const NotificationProvider = function NotificationProvider({
  children,
  notification,
  setNotification,
}: Props) {
  const value = useMemo(
    () => ({ notification, setNotification }),
    [notification, setNotification]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);

export default NotificationProvider;
