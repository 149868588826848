import React, { useEffect, useState } from 'react';
import { Button, Navbar } from 'react-bulma-components';
import { NavLink } from 'react-router-dom';
import {
  ROOT,
  /*
  DISCOVERS,
  FEATUREDS,
  STORIES,
  STYLES,
  */
  CREATORS,
  POSTS_ALL,
  POSTS_FEATURED,
  FEED,
  PROFILE_NEW,
  PROFILES,
  PROFILES_CURATED,
  PROFILES_UNCURATED,
  /*
  MUSIC,
  MUSIC_PUBLISHED,
  MUSIC_UNPUBLISHED,
  */
  USERS,
  WALLET,
} from '../constants/routes';
import Auth from '../services/auth';

// import { useFirebase } from './Firebase';

const Navigation = function Navigation() {
  const [isLoggedIn, setLoggedIn] = useState(Auth.isLoggedIn());

  const logout = () => {
    Auth.logout();
    setLoggedIn(false);
  };

  useEffect(() => {
    if (!Auth.isLoggedIn()) {
      setLoggedIn(false);
    }
  }, []);

  // const firebase = useFirebase()!;
  const [isNavbarActive, setNavbarActive] = useState(false);

  const onLogout = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('You are about to logout.')) {
      // firebase.signout();
      logout();
      window.location.href = ROOT.to;
    }
  };

  return !isLoggedIn ? null : (
    <Navbar active={isNavbarActive} color="light">
      <Navbar.Brand>
        <NavLink className="navbar-item" to={POSTS_FEATURED.to}>
          <img src="/logo.png" alt="logo" />
        </NavLink>
        <Navbar.Burger onClick={() => setNavbarActive(!isNavbarActive)} />
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Container>
          <Navbar.Item hoverable>
            <NavLink className="navbar-link" to={POSTS_FEATURED.to}>
              {POSTS_FEATURED.title}
            </NavLink>
            <Navbar.Dropdown>
              <NavLink className="navbar-item" to={POSTS_FEATURED.to}>
                {POSTS_FEATURED.title}
              </NavLink>
              <NavLink className="navbar-item" to={POSTS_ALL.to}>
                {POSTS_ALL.title}
              </NavLink>
              <NavLink className="navbar-item" to={FEED.to}>
                Public Feed
              </NavLink>
            </Navbar.Dropdown>
          </Navbar.Item>
          <Navbar.Item hoverable>
            <NavLink className="navbar-link" to={PROFILES.to}>
              {PROFILES.title}
            </NavLink>
            <Navbar.Dropdown>
              <NavLink className="navbar-item" to={PROFILES.to}>
                All Profiles
              </NavLink>
              <NavLink className="navbar-item" to={PROFILES_CURATED.to}>
                {PROFILES_CURATED.title}
              </NavLink>
              <NavLink className="navbar-item" to={PROFILES_UNCURATED.to}>
                {PROFILES_UNCURATED.title}
              </NavLink>
              <NavLink className="navbar-item" to={PROFILE_NEW.to}>
                {PROFILE_NEW.title}
              </NavLink>
            </Navbar.Dropdown>
          </Navbar.Item>
          <Navbar.Item hoverable>
            <a className="navbar-link" href={USERS.to}>
              {USERS.title}
            </a>
            <Navbar.Dropdown>
              <Navbar.Item href={USERS.to}>All Users</Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
          <Navbar.Item hoverable>
            <a className="navbar-link" href={CREATORS.to}>
              {CREATORS.title}
            </a>
            <Navbar.Dropdown>
              <Navbar.Item href={CREATORS.to}>All Creators</Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
          {/*
          <Navbar.Item hoverable>
            <a className="navbar-link" href={MUSIC.to}>
              {MUSIC.title}
            </a>
            <Navbar.Dropdown>
              <Navbar.Item href={MUSIC.to}>All Music</Navbar.Item>
              <Navbar.Item href={MUSIC_PUBLISHED.to}>
                {MUSIC_PUBLISHED.title}
              </Navbar.Item>
              <Navbar.Item href={MUSIC_UNPUBLISHED.to}>
                {MUSIC_UNPUBLISHED.title}
              </Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
          */}
          <Navbar.Item hoverable>
            <a className="navbar-link" href={WALLET.to}>
              {WALLET.title}
            </a>
            <Navbar.Dropdown>
              <Navbar.Item href={WALLET.to}>Wallet</Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
          {/*
          <Navbar.Item hoverable>
            <NavLink className="navbar-link" to={DISCOVERS.to}>
              {DISCOVERS.title}
            </NavLink>
            <Navbar.Dropdown>
              <NavLink className="navbar-item" to={DISCOVERS.to}>
                {DISCOVERS.title}
              </NavLink>
              <NavLink className="navbar-item" to={STORIES.to}>
                {STORIES.title}
              </NavLink>
              <NavLink className="navbar-item" to={FEATUREDS.to}>
                {FEATUREDS.title}
              </NavLink>
            </Navbar.Dropdown>
          </Navbar.Item>
          <NavLink className="navbar-item" to={STYLES.to}>
            {STYLES.title}
          </NavLink>
          */}
        </Navbar.Container>
        <Navbar.Container align="right">
          <Navbar.Item>
            <Button.Group>
              <Button outlined color="danger" onClick={onLogout}>
                Logout
              </Button>
            </Button.Group>
          </Navbar.Item>
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  );
};

export default Navigation;
