import React from 'react';
import { Button, Columns, Form, Image } from 'react-bulma-components';
import { Files, Style } from './types';

type Props = {
  files: Files;
  style: Style;
  onChange: (
    fieldName: keyof Style
  ) => (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onFileChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onSave: () => void;
  isNew?: boolean;
};

const StyleForm = function StyleForm({
  files,
  style,
  onChange,
  onFileChange,
  onSave,
  isNew,
}: Props) {
  const { thumbnail, variationThumbnail } = files;

  const {
    id,
    order,
    name,
    storyType,
    styleId,
    tier,
    thumbnailUrl,
    variationThumbnailUrl,
    isPublished,
  } = style;

  return (
    <>
      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Order</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Input
                id="order"
                type="number"
                placeholder="Order"
                value={order}
                onChange={onChange('order')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>ID</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Input
                id="id"
                type="text"
                placeholder="ID"
                value={id}
                disabled={!isNew}
                onChange={onChange('id')}
              />
            </Form.Control>
            {isNew && (
              <Form.Help color="info">
                Can only be set once upon creation
              </Form.Help>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Style ID</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Input
                id="styleId"
                type="text"
                placeholder="Style ID"
                value={styleId}
                disabled={!isNew}
                onChange={onChange('styleId')}
              />
            </Form.Control>
            {isNew && (
              <Form.Help color="info">
                Can only be set once upon creation
              </Form.Help>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Story Type</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Input
                id="storyType"
                type="text"
                placeholder="Story Type"
                value={storyType}
                disabled={!isNew}
                onChange={onChange('storyType')}
              />
            </Form.Control>
            {isNew && (
              <Form.Help color="info">
                Can only be set once upon creation
              </Form.Help>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Name</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Input
                id="name"
                type="text"
                placeholder="Name"
                value={name}
                onChange={onChange('name')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Tier</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Select id="tier" value={tier} onChange={onChange('tier')}>
                {['bryant', 'originals', 'free'].map((tier1) => (
                  <option key={tier1} value={tier1}>
                    {tier1}
                  </option>
                ))}
              </Form.Select>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Is Published?</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Select
                id="isPublished"
                value={JSON.stringify(isPublished)}
                onChange={onChange('isPublished')}
                disabled={isNew}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Select>
            </Form.Control>
            {isNew && (
              <Form.Help color="info">Change later after creation</Form.Help>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Thumbnail</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            {isNew ? (
              <Form.Help color="info">Upload later after creation</Form.Help>
            ) : (
              <Columns>
                <Columns.Column size={2}>
                  <Image
                    fallback=""
                    size="4by3"
                    src={
                      (thumbnail && URL.createObjectURL(thumbnail)) ||
                      thumbnailUrl
                    }
                  />
                </Columns.Column>
                <Columns.Column size={3}>
                  <Form.InputFile
                    boxed
                    color="warning"
                    fullwidth
                    className={thumbnail ? 'has-name' : ''}
                    label="Change Thumbnail"
                    inputProps="thumbnail"
                    onChange={onFileChange}
                  />
                </Columns.Column>
              </Columns>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Variation Thumbnail</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            {isNew ? (
              <Form.Help color="info">Upload later after creation</Form.Help>
            ) : (
              <Columns>
                <Columns.Column size={1}>
                  <Image
                    fallback=""
                    size="square"
                    src={
                      (variationThumbnail &&
                        URL.createObjectURL(variationThumbnail)) ||
                      variationThumbnailUrl
                    }
                  />
                </Columns.Column>
                <Columns.Column size={4}>
                  <Form.InputFile
                    boxed
                    color="warning"
                    fullwidth
                    className={variationThumbnail ? 'has-name' : ''}
                    label="Change Variation Thumbnail"
                    inputProps="variationThumbnail"
                    onChange={onFileChange}
                  />
                </Columns.Column>
              </Columns>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <br />

      <Form.Field horizontal>
        <Form.Field.Label />
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Button fullwidth color="primary" onClick={onSave}>
                {isNew ? 'Create' : 'Update'}
              </Button>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>
    </>
  );
};

StyleForm.defaultProps = {
  onFileChange: () => {},
  isNew: false,
};

export default StyleForm;
