import axios from 'axios';

import { PRESIGN_API } from '../constants/routes';
import { PresignRequest } from '../types/api';

export const createPresignedPost = async ({ type, id }: PresignRequest) => {
  const {
    data: {
      data: { presigned },
    },
  } = await axios.post(`${PRESIGN_API}/${type}/${id}`);
  return presigned;
};

interface UploadRequest extends PresignRequest {
  directory: string;
  subDirectory?: string;
  file: File;
}

export type UploadResult = Promise<{ status: number }>;

export const upload: (a: UploadRequest) => UploadResult = async ({
  type,
  id,
  directory,
  subDirectory,
  file,
}: UploadRequest) => {
  // console.log('upload');

  if (!(type && id && file)) {
    return { status: 400 };
  }

  try {
    const presigned = await createPresignedPost({ type, id });
    const {
      url,
      fields: {
        Policy: policy,
        'X-Amz-Algorithm': algorithm,
        'X-Amz-Credential': credential,
        'X-Amz-Date': date,
        'X-Amz-Signature': signature,
      },
    } = presigned;

    const formData = new FormData();
    const key = subDirectory
      ? `${directory}/${id}/${subDirectory}/${file.name}`
      : `${directory}/${id}/${file.name}`;
    formData.append('key', key);
    formData.append('Policy', policy);
    formData.append('X-Amz-Algorithm', algorithm);
    formData.append('X-Amz-Credential', credential);
    formData.append('X-Amz-Date', date);
    formData.append('X-Amz-Signature', signature);
    formData.append('file', file);

    const result = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        // eslint-disable-next-line no-console
        console.log(
          'onUploadProgress',
          Math.round((100 * progressEvent.loaded) / (progressEvent.total || 1))
        );
      },
    });
    // console.log('upload result', result);

    return { status: result.status };
  } catch (error: any) {
    /* eslint-disable no-console */
    if (error.response) {
      console.error('upload ERR data', error.response);
    } else if (error.request) {
      console.error('upload ERR request', error.request);
    } else {
      console.error('upload ERR message', error.message);
    }
    /* eslint-enable no-console */

    return { status: 500 };
  }
};
