import React from 'react';

import { PaymentTransfer } from '../../types/payment';

type Props = { shareTransfer: PaymentTransfer };

const ShareTransferRow: React.FC<Props> = function ShareTransferRow({
  shareTransfer,
}) {
  const { amount, receiverId, revenueDetails, status } = shareTransfer;
  const { collector } = revenueDetails || {};
  const { emailAddress, emailAddressAlt, userName } = collector || {};
  const email = emailAddress || emailAddressAlt;
  return (
    <tr>
      <td>
        {userName} | <i style={{ fontSize: '0.8em' }}>{receiverId}</i>
      </td>
      <td>{email}</td>
      <th style={{ textAlign: 'right' }}>${amount / 100}</th>
      <th>
        <i>{status}</i>
      </th>
    </tr>
  );
};

export default ShareTransferRow;
