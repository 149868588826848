import axios from 'axios';

import { PRESIGN_API } from '../constants/routes';
import { PresignRequest, UploadRequest, UploadResult } from '../types/api';

import Http from './http';

class FileService {
  // eslint-disable-next-line class-methods-use-this
  async createPresignedPost({ type, id }: PresignRequest) {
    const { data } = await Http.post(`${PRESIGN_API.to}`, {
      ...(type === 'profile' && { profile: { id } }),
    });
    return data;
  }

  async upload({ type, id, file }: UploadRequest): Promise<UploadResult> {
    // console.log('upload');

    if (!(type && id && file)) {
      return { status: 400 };
    }

    try {
      const { cdn, presigned, storage } = await this.createPresignedPost({
        type,
        id,
      });

      const { url: cdnBaseUrl } = cdn;
      const {
        url,
        fields: {
          Policy: policy,
          'X-Amz-Algorithm': algorithm,
          'X-Amz-Credential': credential,
          'X-Amz-Date': date,
          'X-Amz-Signature': signature,
        },
      } = presigned;
      const { startsWithKey } = storage;

      const formData = new FormData();
      const key = `${startsWithKey}/${file.name}`;
      formData.append('key', key);
      formData.append('Policy', policy);
      formData.append('X-Amz-Algorithm', algorithm);
      formData.append('X-Amz-Credential', credential);
      formData.append('X-Amz-Date', date);
      formData.append('X-Amz-Signature', signature);
      formData.append('file', file);
      // formData.append('Content-Type', 'image/png');

      const result = await axios.post(url, formData, {
        // headers: { 'Content-Type': 'image/png' },
        onUploadProgress: (progressEvent) => {
          // eslint-disable-next-line no-console
          console.log(
            'onUploadProgress',
            Math.round(
              (100 * progressEvent.loaded) / (progressEvent.total || 1)
            )
          );
        },
      });
      // console.log('upload result', result);

      return { location: `${cdnBaseUrl}/${key}`, status: result.status };
    } catch (error: any) {
      /* eslint-disable no-console */
      if (error.response) {
        console.error('upload ERR data', error.response);
      } else if (error.request) {
        console.error('upload ERR request', error.request);
      } else {
        console.error('upload ERR message', error.message);
      }
      /* eslint-enable no-console */

      return { status: 500 };
    }
  }
}

export default new FileService();
