import axios from 'axios';

import { GOOGLE_AUTH_API, REFRESH_AUTH_API } from '../constants/routes';
import { Auth } from '../types';

import Token from './token';

class AuthService {
  // eslint-disable-next-line class-methods-use-this
  isLoggedIn() {
    return !!Token.getAccess();
  }

  // eslint-disable-next-line class-methods-use-this
  async login({ idToken }: { idToken: string }): Promise<Auth> {
    const { data } = await axios.post(GOOGLE_AUTH_API.to, {
      oauthIdToken: idToken,
      profile: { isAdmin: true },
    });

    const auth = data?.data as Auth;
    const { accessToken } = auth || {};
    if (accessToken) {
      Token.resetAll(auth);
    }

    return auth;
  }

  // eslint-disable-next-line class-methods-use-this
  async refresh(): Promise<Auth> {
    const { data } = await axios.post(REFRESH_AUTH_API.to, {
      refreshToken: Token.getRefresh(),
    });

    const auth = data?.data as Auth;
    const { accessToken } = auth || {};
    if (accessToken) {
      Token.resetAll(auth);
    }

    return auth;
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    Token.removeAll();
  }
}

export default new AuthService();
