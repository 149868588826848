import React from 'react';
import { DefaultPlayer as Video } from 'react-html5video';
import { Button, Columns, Form, Image } from 'react-bulma-components';
import { Discover, Tiers } from './types';

type Props = {
  discover: Discover;
  onChange: (
    fieldName: keyof Discover
  ) => (
    event: React.FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  onSave: () => void;
  isNew?: boolean;
};

const DiscoverForm = function DiscoverForm({
  discover,
  onChange,
  onSave,
  isNew,
}: Props) {
  const {
    id,
    order,
    title,
    subtitle,
    tier,
    isPublished,
    isReleased,
    story,
    storyId,
  } = discover;

  return (
    <>
      <Form.Field className="is-horizontal">
        <Form.Field.Label>
          <Form.Label>ID</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Input
                id="id"
                type="text"
                placeholder="ID"
                value={id}
                disabled={!isNew}
                onChange={onChange('id')}
              />
            </Form.Control>
            {isNew && (
              <Form.Help color="info">
                Can only be set once upon creation
              </Form.Help>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Order</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Input
                id="order"
                type="number"
                placeholder="Order"
                value={order}
                onChange={onChange('order')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Title</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Input
                id="title"
                type="text"
                placeholder="Title"
                value={title || ''}
                onChange={onChange('title')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Subtitle</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Textarea
                id="subtitle"
                placeholder="Subtitle"
                value={subtitle}
                onChange={onChange('subtitle')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Tier</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Select id="tier" value={tier} onChange={onChange('tier')}>
                {Object.keys(Tiers).map((tier1) => (
                  <option key={tier1} value={tier1}>
                    {tier1}
                  </option>
                ))}
              </Form.Select>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Is Published?</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Select
                id="isPublished"
                value={JSON.stringify(isPublished)}
                onChange={onChange('isPublished')}
                disabled={isNew}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Select>
            </Form.Control>
            {isNew && (
              <Form.Help color="info">Change later after creation</Form.Help>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Is Released?</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Select
                id="isReleased"
                disabled={isNew}
                value={JSON.stringify(isReleased)}
                onChange={onChange('isReleased')}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Select>
            </Form.Control>
            {isNew && (
              <Form.Help color="info">Change later after creation</Form.Help>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Videos Set ID</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Input
                id="storyId"
                type="text"
                disabled={isNew}
                placeholder="Story ID"
                value={storyId || ''}
                onChange={onChange('storyId')}
              />
            </Form.Control>
            {isNew && (
              <Form.Help color="info">Change later after creation</Form.Help>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Thumbnail Video</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            {isNew ? (
              <Form.Help color="info">Change later after creation</Form.Help>
            ) : (
              <Columns>
                <Columns.Column>
                  High Resolution (682 x 462):
                  <Video poster={story && story.thumbImageUrl}>
                    <source
                      type="video/mp4"
                      src={
                        story &&
                        story.thumbVideoMp4Urls &&
                        story.thumbVideoMp4Urls.highRes
                      }
                    />
                  </Video>
                </Columns.Column>
                <Columns.Column>
                  First Frame Image (682 x 462):
                  <Image
                    size="3by2"
                    src={(story && story.thumbImageUrl) || ''}
                  />
                </Columns.Column>
              </Columns>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Video</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            {isNew ? (
              <Form.Help color="info">Change later after creation</Form.Help>
            ) : (
              <Columns>
                <Columns.Column>
                  High Resolution (504 x 894):
                  <Video poster={story && story.imageUrl}>
                    <source
                      type="video/mp4"
                      src={
                        story &&
                        story.videoMp4Urls &&
                        story.videoMp4Urls.highRes
                      }
                    />
                  </Video>
                </Columns.Column>
                <Columns.Column>
                  First Frame Image (504 x 894):
                  <Image size="9by16" src={(story && story.imageUrl) || ''} />
                </Columns.Column>
              </Columns>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <br />

      <Form.Field horizontal>
        <Form.Field.Label />
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Button fullwidth color="primary" onClick={onSave}>
                {isNew ? 'Create' : 'Update'}
              </Button>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>
    </>
  );
};

DiscoverForm.defaultProps = {
  isNew: false,
};

export default DiscoverForm;
