import { firebase } from '../Firebase';
import { Story } from '../Story';

export interface Discover {
  order: number;
  id: string;
  title: string;
  subtitle?: string;
  cardTitle: string;
  tier: string;
  badgeUrl: string;
  cardBadgeUrl: string;
  isPublished: boolean;
  isReleased: boolean;
  storyId?: string;
  story?: Story;
}

export const Tiers = {
  free: 'free',
  originals: 'originals',
  bryant: 'bryant',
};

export const BadgeUrls = Object.keys(Tiers).reduce<{
  [x: string]: string;
}>((acc, tier) => {
  acc[
    tier
  ] = `https://d2st0fnq3grac2.cloudfront.net/discover-images/${tier}-badge.png`;
  return acc;
}, {});

export const CardBadgeUrls = Object.keys(Tiers).reduce<{
  [x: string]: string;
}>((acc, tier) => {
  acc[
    tier
  ] = `https://d2st0fnq3grac2.cloudfront.net/discover-images/${tier}-badge-clear.png`;
  return acc;
}, {});

export const empty: Discover = {
  order: 0,
  id: '',
  title: '',
  cardTitle: '',
  tier: Tiers.free,
  badgeUrl: BadgeUrls.free,
  cardBadgeUrl: CardBadgeUrls.free,
  isPublished: false,
  isReleased: false,
};

export const converter = {
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)!;
    const discover: Discover = {
      ...empty,
      id: snapshot.id,
      ...data,
    };
    return discover;
  },
  toFirestore(discover: Discover): firebase.firestore.DocumentData {
    return {
      ...discover,
    };
  },
};
