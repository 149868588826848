import React, { useEffect, useRef, useState } from 'react';
import { Container, Notification, Progress } from 'react-bulma-components';
import { Link, useParams } from 'react-router-dom';

import { ROOT, DISCOVERS } from '../../constants/routes';

import { useFirebase } from '../Firebase';
import Navigation from '../Navigation';

import DiscoverForm from './form';
import { BadgeUrls, CardBadgeUrls, converter, Discover, empty } from './types';

const DiscoverDetailsPage = function DiscoverDetailsPage() {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [discover, setDiscover] = useState<Discover>({
    ...empty,
    id,
  });

  const currentRef = useRef<Discover>({ ...empty, id });
  const [notificationMessage, setNotificationMessage] = useState<string | null>(
    null
  );

  const firebase = useFirebase()!;
  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const getDiscover = async () => {
      const doc = await firebase.db
        .collection('discoverStories')
        .withConverter(converter)
        .doc(id)
        .get();
      if (doc.exists && mounted) {
        const data = doc.data()!;
        // console.log('getDiscover', data);
        setDiscover(data);
        currentRef.current = data;
      } else {
        // TODO: show 404 - not found
        // eslint-disable-next-line no-console
        console.log('getDiscover !exists');
      }
      setLoading(false);
    };
    // eslint-disable-next-line no-console
    getDiscover().catch((error) => console.error('getDiscover ERR', error));
    return () => {
      mounted = false;
    };
  }, [firebase.db, id]);

  const onChange =
    (fieldName: keyof Discover) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      let { value } = event.currentTarget;
      if (typeof discover[fieldName] !== 'string') {
        value = JSON.parse(value);
      }
      // console.log(fieldName, value);
      const values = {
        [fieldName]: value,
      };
      if (fieldName === 'title') {
        values.cardTitle = value;
      } else if (fieldName === 'tier') {
        values.badgeUrl = BadgeUrls[value];
        values.cardBadgeUrl = CardBadgeUrls[value];
      }
      const updated: Discover = { ...discover, ...values };
      setDiscover(updated);
    };

  async function getDiscoverRef() {
    return (
      await firebase.db
        .collection('discoverStories')
        .withConverter(converter)
        .limit(1)
        .get()
    ).docs[0].data()!;
  }

  const onSave = async () => {
    // console.log('onSave');
    const updateDiscover = async (discover1: Discover) => {
      const ref = await firebase.db.collection('discoverStories').doc(id);
      await ref.update(discover1);
    };

    setLoading(true);
    try {
      if (
        discover.story &&
        discover.isPublished &&
        !currentRef.current.isPublished
      ) {
        const ref = await getDiscoverRef();
        discover.story.type = ref.story!.type;
      }
      if (discover) {
        await updateDiscover(discover);
      }
      if (
        currentRef.current.storyId &&
        currentRef.current.storyId !== discover.storyId
      ) {
        setNotificationMessage(
          `Discover ${id} is being updated with storyId: ${discover.storyId}. Videos will be updated in about half a minute. Please refresh this page again later.`
        );
      }
      currentRef.current = discover;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('updateDiscover ERR', error);
    }
    setLoading(false);
  };

  return (
    <>
      <Navigation />
      <Container>
        <br />
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li>
              <Link to={DISCOVERS.to}>{DISCOVERS.title}</Link>
            </li>
            <li className="is-active">
              <Link to={`${DISCOVERS}/${id}`}>
                {discover.title || discover.id}
              </Link>
            </li>
          </ul>
        </nav>
        {notificationMessage && (
          <Notification color="warning">
            <button
              aria-label="close"
              className="delete"
              onClick={() => setNotificationMessage(null)}
              type="button"
            />
            {notificationMessage}
          </Notification>
        )}

        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <DiscoverForm
            discover={discover}
            onChange={onChange}
            onSave={onSave}
          />
        )}
        <br />
      </Container>
    </>
  );
};

export default DiscoverDetailsPage;
