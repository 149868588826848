import React from 'react';
import { Table } from 'react-bulma-components';
import FeaturedRow from './row';
import { Featured, IDs } from './types';

type Props = {
  featureds: Featured[];
};

const FeaturedsTable = function FeaturedsTable({ featureds }: Props) {
  return (
    <Table className="is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Thumbnail</th>
          <th>Subtitle</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {featureds.map((featured) => (
          <FeaturedRow
            key={featured.id}
            featured={featured}
            isEditable={featured.id === IDs.new}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default FeaturedsTable;
