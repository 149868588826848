import React from 'react';
import { Button } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import { PROFILES } from '../../constants/routes';

import { Music } from './types';

type Props = {
  music: Music;
  onPublish: () => void;
};

const MusicRow: React.FC<Props> = function MusicRow({ music, onPublish }) {
  const { id, title, profile, isPublished, url } = music;
  const { userName } = profile;
  const to = `${PROFILES.to}/${userName}`;
  return (
    <tr>
      <th>{id}</th>
      <td>{title}</td>
      <th style={{ whiteSpace: 'nowrap' }}>
        <Link to={to}>{userName}</Link>
      </th>
      <td>{isPublished ? 'true' : 'false'}</td>
      <td>
        <audio preload="none" controls loop src={url}>
          <track kind="captions" />
        </audio>
      </td>
      <td>
        <div className="field is-grouped">
          <p className="control">
            <Button outlined color="warning" onClick={() => onPublish()}>
              {isPublished ? 'Unpublish' : 'Publish'}
            </Button>
          </p>
          <p className="control">
            <a
              href={url}
              download
              target="_blank"
              rel="noreferrer"
              className="button is-link is-outlined"
            >
              Download
            </a>
          </p>
        </div>
      </td>
    </tr>
  );
};

export default MusicRow;
