import React, { useState } from 'react';
import { Pagination, Section } from 'react-bulma-components';

// Ignoring this eslint because component.defaultProps will be deprecated in future
/* eslint-disable react/require-default-props */

interface Props {
  showPrevNext?: boolean;
  onChange?: (after: string) => void;
  nextAfter?: string;
  hasNextPage?: boolean;
}

const PaginationComponent: React.FC<Props> = function PaginationComponent({
  children,
  showPrevNext = true,
  onChange = () => {},
  nextAfter = undefined,
  hasNextPage = false,
}) {
  const [afterList, setAfterList] = useState<string[]>([]);

  const handleOnChange = (page: number) => {
    let after;

    if (!nextAfter) {
      return ;
    }
    if (page > (afterList.length + 1)) {
      after = nextAfter;
      setAfterList((prevAfterList) => ([...prevAfterList, nextAfter]));
    } else {
      after = afterList[afterList.length - 2];
      setAfterList((prevAfterList) => ([...prevAfterList.slice(0, prevAfterList.length - 1)]));
    }

    onChange(after);
  }

  return (
    <Section paddingless>
      <Pagination
        align='center'
        onChange={handleOnChange}
        showPrevNext={showPrevNext}
        current={nextAfter ? (afterList.length + 1) : 1}
        total={nextAfter ? (afterList.length + (hasNextPage ? 2 : 1)) : 1}
        delta={0}
      />
      {children}
      <Pagination
        align='center'
        onChange={handleOnChange}
        showPrevNext={showPrevNext}
        current={nextAfter ? (afterList.length + 1) : 1}
        total={nextAfter ? (afterList.length + (hasNextPage ? 2 : 1)) : 1}
        delta={0}
      />
    </Section>
  );
};

export default PaginationComponent;
