import React, { useEffect, useState } from 'react';
import { Container, Progress } from 'react-bulma-components';
import { Link, useParams } from 'react-router-dom';

import { AwsConfig } from '../../config';
import { ROOT, STYLES, STYLE_EDIT } from '../../constants/routes';
import { uploadFile } from '../../utils';

import { useFirebase } from '../Firebase';
import Navigation from '../Navigation';

import StyleForm from './form';
import { converter, empty, emptyFiles, Style } from './types';

const StyleDetailsPage = function StyleDetailsPage({
  config,
}: {
  config: AwsConfig;
}) {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [style, setStyle] = useState<Style>({ ...empty, id });
  const [thumbnailFiles, setFiles] = useState(emptyFiles);
  const { thumbnail, variationThumbnail } = thumbnailFiles;

  const firebase = useFirebase()!;
  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const getStyle = async () => {
      const doc = await firebase.db
        .collection('selectStyles')
        .withConverter(converter)
        .doc(id)
        .get();

      if (doc.exists && mounted) {
        // console.log('getStyle', doc.data());
        setStyle(doc.data()!);
      } else {
        // TODO: show 404 - not found
        // eslint-disable-next-line no-console
        console.log('getStyle !exists');
      }
      setLoading(false);
    };
    // eslint-disable-next-line no-console
    getStyle().catch((error) => console.error('getStyle ERR', error));
    return () => {
      mounted = false;
    };
  }, [firebase.db, id]);

  const onChange =
    (fieldName: keyof Style) =>
    (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      let { value } = event.currentTarget;
      if (typeof style[fieldName] !== 'string') {
        value = JSON.parse(value);
      }
      // console.log(fieldName, value);
      const updated: Style = { ...style, [fieldName]: value };
      setStyle(updated);
    };

  const onFileChange = (event: React.FormEvent<HTMLInputElement>) => {
    // console.log(event.currentTarget.id, event.currentTarget.value);
    const { files } = event.currentTarget;
    setFiles({
      ...thumbnailFiles,
      [event.currentTarget.id]: files && files.length > 0 ? files[0] : null,
    });
  };

  const updateStyle = async (style1: Style) => {
    const ref = await firebase.db.collection('selectStyles').doc(id);
    await ref.set(style1, { merge: true });
  };

  const onSave = async () => {
    // console.log('onSave');

    const type = 'style';
    const directory = config.s3.directories[type];
    const subDirectory = 'variations';
    const cloudfrontBaseUrl = config.cloudfront.baseUrls.assets;

    setLoading(true);
    try {
      let saveThumbnailAction;
      let saveVariationThumbnailAction;
      if (thumbnail) {
        saveThumbnailAction = uploadFile({
          type,
          directory,
          id,
          file: thumbnail,
        });
      }
      if (variationThumbnail) {
        saveVariationThumbnailAction = uploadFile({
          type,
          directory,
          id,
          subDirectory,
          file: variationThumbnail,
        });
      }
      const [saveThumbnailResult, saveVariationThumbnailResult] =
        await Promise.all([saveThumbnailAction, saveVariationThumbnailAction]);
      if (saveThumbnailResult && saveThumbnailResult.status === 204) {
        style.thumbnailUrl = `${cloudfrontBaseUrl}/${directory}/${id}/${encodeURI(
          thumbnail!.name
        )}`;
        setFiles({ ...thumbnailFiles, thumbnail: null });
      }
      if (
        saveVariationThumbnailResult &&
        saveVariationThumbnailResult.status === 204
      ) {
        style.variationThumbnailUrl = `${cloudfrontBaseUrl}/${directory}/${id}/${subDirectory}/${encodeURI(
          variationThumbnail!.name
        )}`;
        setFiles({ ...thumbnailFiles, variationThumbnail: null });
      }

      await updateStyle(style);
    } catch (error) {
      // TODO(sirius): show error message
      // eslint-disable-next-line no-console
      console.log('updateStyle ERR', error);
    }
    setLoading(false);
  };

  return (
    <>
      <Navigation />
      <Container>
        <br />
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li>
              <Link to={STYLES.to}>{STYLES.title}</Link>
            </li>
            <li className="is-active">
              <Link to={STYLE_EDIT.to}>{style.name || style.id}</Link>
            </li>
          </ul>
        </nav>

        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <StyleForm
            files={thumbnailFiles}
            style={style}
            onChange={onChange}
            onFileChange={onFileChange}
            onSave={onSave}
          />
        )}
        <br />
      </Container>
    </>
  );
};

export default StyleDetailsPage;
