import React from 'react';
import { Button, Image } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import { Story } from './types';
import { STORIES } from '../../constants/routes';

type Props = {
  onDelete: (a: string) => void;
  story: Story;
};

const StoryRow = function StoryRow({
  onDelete,
  story: { order, id, type, isPublished, thumbImageUrl },
}: Props) {
  const to = `${STORIES.to}/${id}`;
  return (
    <tr>
      <th>{order}</th>
      <th style={{ whiteSpace: 'nowrap' }}>
        <Link to={to}>{id}</Link>
      </th>
      <td>
        <Image size="3by2" src={thumbImageUrl} fallback="" />
      </td>
      <td>{type}</td>
      <td>{isPublished ? 'true' : 'false'}</td>
      <td style={{ whiteSpace: 'nowrap' }}>
        <Link to={to}>
          <Button outlined color="warning">
            Edit
          </Button>
        </Link>{' '}
        <Button outlined color="danger" onClick={() => onDelete(id)}>
          Delete
        </Button>
      </td>
    </tr>
  );
};

export default StoryRow;
