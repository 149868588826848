import React from 'react';
import { DefaultPlayer as Video } from 'react-html5video';
import { Button, Columns, Form, Image } from 'react-bulma-components';
import { Story, VideoFiles } from './types';

type Props = {
  files: VideoFiles;
  story: Story;
  onChange: (
    fieldName: keyof Story
  ) => (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onFileChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onSave: () => void;
  isNew?: boolean;
};

const StoryForm = function StoryForm({
  files,
  story,
  onChange,
  onFileChange,
  onSave,
  isNew,
}: Props) {
  const { thumbnailVideo, thumbnailVideoImage, video, videoImage } = files;

  const {
    id,
    order,
    type,
    imageUrl,
    videoMp4Urls,
    thumbImageUrl,
    thumbVideoMp4Urls,
    isPublished,
  } = story;

  return (
    <>
      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Order</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Input
                id="order"
                type="number"
                placeholder="Order"
                value={order || ''}
                onChange={onChange('order')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>ID</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Input
                id="id"
                type="text"
                placeholder="ID"
                value={id}
                disabled={!isNew}
                onChange={onChange('id')}
              />
            </Form.Control>
            {isNew && (
              <Form.Help color="info">
                Can only be set once upon creation
              </Form.Help>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Type</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Input
                id="type"
                type="text"
                placeholder="Type"
                value={type}
                disabled={!isNew}
                onChange={onChange('type')}
              />
            </Form.Control>
            {isNew && (
              <Form.Help color="info">
                Can only be set once upon creation
              </Form.Help>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Is Published?</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Select
                id="isPublished"
                disabled={isNew}
                value={JSON.stringify(isPublished)}
                onChange={onChange('isPublished')}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Select>
            </Form.Control>
            {isNew && (
              <Form.Help color="info">Change later after creation</Form.Help>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Thumbnail Video</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          {isNew ? (
            <Form.Help color="info">Upload later after creation</Form.Help>
          ) : (
            <Form.Field>
              <Columns>
                <Columns.Column>
                  High Resolution (682 x 462):
                  <Video
                    poster={
                      (thumbnailVideo &&
                        thumbnailVideoImage &&
                        URL.createObjectURL(thumbnailVideoImage)) ||
                      thumbImageUrl
                    }
                  >
                    <source
                      type="video/mp4"
                      src={
                        (thumbnailVideo &&
                          URL.createObjectURL(thumbnailVideo)) ||
                        (thumbVideoMp4Urls && thumbVideoMp4Urls.highRes)
                      }
                    />
                  </Video>
                </Columns.Column>
                <Columns.Column>
                  First Frame Image (682 x 462):
                  <Image
                    fallback=""
                    size="3by2"
                    src={
                      (thumbnailVideoImage &&
                        URL.createObjectURL(thumbnailVideoImage)) ||
                      thumbImageUrl
                    }
                  />
                </Columns.Column>
              </Columns>
              <Columns>
                <Columns.Column>
                  <Form.InputFile
                    boxed
                    color="warning"
                    fullwidth
                    className={thumbnailVideo ? 'has-name' : ''}
                    label="Upload & Change Thumbnail Video"
                    inputProps="thumbnailVideo"
                    onChange={onFileChange}
                  />
                </Columns.Column>
                <Columns.Column>
                  <Form.InputFile
                    boxed
                    color="warning"
                    fullwidth
                    className={thumbnailVideoImage ? 'has-name' : ''}
                    label="Upload & Change Thumbnail Video's First Frame"
                    inputProps="thumbnailVideoImage"
                    onChange={onFileChange}
                  />
                </Columns.Column>
              </Columns>
            </Form.Field>
          )}
        </Form.Field.Body>
      </Form.Field>

      <br />

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Video</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          {isNew ? (
            <Form.Help color="info">Upload later after creation</Form.Help>
          ) : (
            <Form.Field>
              <Columns>
                <Columns.Column>
                  High Resolution (504 x 894):
                  <Video
                    poster={
                      (video &&
                        videoImage &&
                        URL.createObjectURL(videoImage)) ||
                      imageUrl
                    }
                  >
                    <source
                      type="video/mp4"
                      src={
                        (video && URL.createObjectURL(video)) ||
                        (videoMp4Urls && videoMp4Urls.highRes)
                      }
                    />
                  </Video>
                </Columns.Column>
                <Columns.Column>
                  First Frame Image (504 x 894):
                  <Image
                    fallback=""
                    size="9by16"
                    src={
                      (videoImage && URL.createObjectURL(videoImage)) ||
                      imageUrl
                    }
                  />
                </Columns.Column>
              </Columns>
              <Columns>
                <Columns.Column>
                  <Form.InputFile
                    boxed
                    color="warning"
                    fullwidth
                    className={video ? 'has-name' : ''}
                    label="Upload & Change Video"
                    inputProps="video"
                    onChange={onFileChange}
                  />
                </Columns.Column>
                <Columns.Column>
                  <Form.InputFile
                    boxed
                    color="warning"
                    fullwidth
                    className={videoImage ? 'has-name' : ''}
                    label="Upload & Change Video's First Frame"
                    inputProps="videoImage"
                    onChange={onFileChange}
                  />
                </Columns.Column>
              </Columns>
            </Form.Field>
          )}
        </Form.Field.Body>
      </Form.Field>

      <br />

      <Form.Field horizontal>
        <Form.Field.Label />
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Button fullwidth color="primary" onClick={onSave}>
                {isNew ? 'Create' : 'Update'}
              </Button>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>
    </>
  );
};

StoryForm.defaultProps = {
  onFileChange: () => {},
  isNew: false,
};

export default StoryForm;
