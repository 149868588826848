import React from 'react';
import { Button, Columns, Form, Progress } from 'react-bulma-components';

import { Profile } from '../../types';
import { ChargeRequest } from '../../types/api';
import {
  PaymentMethodMetadata,
  PaymentProfile,
  RevenueDetails,
} from '../../types/payment';
import NumberInputColumn from '../Form/NumberInputColumn';

type Props = {
  isLoadingProfile?: boolean;
  isMissingInfo?: boolean;
  onMethodChange: (
    fieldName: keyof PaymentMethodMetadata
  ) => (event: React.FormEvent<HTMLInputElement>) => void;
  onOthersChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onProfileChange: (
    fieldName: keyof PaymentProfile
  ) => (event: React.FormEvent<HTMLInputElement>) => void;
  onRevenueChange: (
    fieldName: keyof RevenueDetails
  ) => (event: React.FormEvent<HTMLInputElement>) => void;
  profile: Profile;
  request: ChargeRequest;
};

const ChargeForm = function ChargeForm({
  isLoadingProfile,
  isMissingInfo,
  onMethodChange,
  onOthersChange,
  onProfileChange,
  onRevenueChange,
  profile,
  request,
}: Props) {
  const { fullName, userName } = profile;
  const {
    paymentMethod,
    paymentTransfer: { id, revenueDetails },
    quarterNumber,
  } = request;
  const { metadata, paymentProfile } = paymentMethod || {};
  const { email, percentage } = metadata || {};
  const { stripeCustomerId } = paymentProfile || {};
  const isNew = !id;
  const { endDate, startDate, totalRevenue } = revenueDetails || {};

  return isLoadingProfile ? (
    <Progress max={100} color="info" />
  ) : (
    <>
      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Creator</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Field.Label>{fullName || userName}</Form.Field.Label>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>
      {isNew && (
        <Form.Field horizontal>
          <Form.Field.Label>
            <Form.Label>Quarterly #</Form.Label>
          </Form.Field.Label>
          <Form.Field.Body>
            <Form.Field>
              <Columns>
                <NumberInputColumn
                  id="quarterNumber"
                  info="Quarter #"
                  value={quarterNumber}
                  onChange={onOthersChange}
                />
                <Columns.Column />
              </Columns>
            </Form.Field>
          </Form.Field.Body>
        </Form.Field>
      )}
      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Overwrite Period</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Columns>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>Start Date</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="startDate"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      value={startDate}
                      onChange={onRevenueChange('startDate')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field kind="addons">
                  <Form.Control>
                    <Button isStatic>End Date</Button>
                  </Form.Control>
                  <Form.Control fullwidth>
                    <Form.Input
                      id="endDate"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      value={endDate}
                      onChange={onRevenueChange('endDate')}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Overwrite Revenue</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Columns>
              <NumberInputColumn
                id="totalRevenue"
                info="US$"
                placeholder="Total Revenue"
                value={totalRevenue}
                onChange={onRevenueChange('totalRevenue')}
              />
              {isMissingInfo ? (
                <NumberInputColumn
                  id="totalRevenue"
                  info="%"
                  placeholder="Percentage"
                  value={percentage}
                  onChange={onMethodChange('percentage')}
                />
              ) : (
                <Columns.Column />
              )}
            </Columns>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      {isMissingInfo && (
        <Form.Field horizontal>
          <Form.Field.Label>
            <Form.Label>Stripe Details</Form.Label>
          </Form.Field.Label>
          <Form.Field.Body>
            <Form.Field>
              <Columns>
                <Columns.Column>
                  <Form.Input
                    id="stripeCustomerId"
                    type="text"
                    placeholder="Customer ID cus_aBcD1234"
                    value={stripeCustomerId}
                    onChange={onProfileChange('stripeCustomerId')}
                  />
                </Columns.Column>
                <Columns.Column>
                  <Form.Input
                    id="startDate"
                    type="text"
                    placeholder="Email Address"
                    value={email}
                    onChange={onMethodChange('email')}
                  />
                </Columns.Column>
              </Columns>
            </Form.Field>
          </Form.Field.Body>
        </Form.Field>
      )}
    </>
  );
};

ChargeForm.defaultProps = {
  isLoadingProfile: false,
  isMissingInfo: false,
};

export default ChargeForm;
