import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import config from '../config';
import Auth from '../services/auth';

import {
  ROOT,
  LOGIN,
  CREATORS,
  DISCOVERS,
  DISCOVER_EDIT,
  DISCOVER_NEW,
  FEATUREDS,
  FEATURED_EDIT,
  STORIES,
  STORY_EDIT,
  STORY_NEW,
  STYLES,
  STYLE_EDIT,
  STYLE_NEW,
  FEED,
  POSTS,
  PROFILES,
  PROFILE_DETAILS,
  PROFILE_EDIT,
  PROFILE_NEW,
  MUSIC,
  WALLET,
  USERS,
  CHARGE_EDIT,
  CHARGE_NEW,
  PAYOUT_NEW,
} from '../constants/routes';
import { LoadingProvider } from '../contexts/loading';
import { NotificationProvider } from '../contexts/notification';
import { OptionalString } from '../types';

import { Private, Public } from './Route';
import Login from './Login';
import CreatorsListPage from './Creator/list';
import {
  DiscoverDetailsPage,
  DiscoversListPage,
  NewDiscoverPage,
} from './Discover';
import { FeaturedDetailsPage, FeaturedsListPage } from './Featured';
import { NewStoryPage, StoryDetailsPage, StoriesListPage } from './Story';
import { NewStylePage, StyleDetailsPage, StylesListPage } from './Style';
import ChargeNewPage from './Charge/new';
import PayoutNewPage from './Payout/new';
import {
  ProfileDetailsPage,
  ProfileEditPage,
  ProfileNewPage,
  ProfilesCollectionPage,
} from './Profile';
import { FeedCollectionPage } from './Feed';
import { MusicListPage } from './Music';
import { UsersSearchPage } from './User';
import { NftPage } from './Wallet';

const App = function App() {
  const [isLoggedIn, setLoggedIn] = useState(Auth.isLoggedIn());
  const [notification, setNotification] = useState<OptionalString>(undefined);
  const [isLoading, setLoading] = useState(false);

  const login = () => {
    setLoggedIn(true);
  };

  useEffect(() => {
    if (Auth.isLoggedIn()) {
      setLoggedIn(true);
    }
  }, []);

  return (
    <NotificationProvider
      notification={notification}
      setNotification={setNotification}
    >
      <LoadingProvider isLoading={isLoading} setLoading={setLoading}>
        <Router>
          <Switch>
            <Public isLoggedIn={isLoggedIn} exact path={LOGIN.to}>
              <Login
                googleWebClientId={config.googleWebClientId}
                login={login}
              />
            </Public>
            <Private isLoggedIn={isLoggedIn} exact path={ROOT.to}>
              <FeedCollectionPage featured />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={CREATORS.to}>
              <CreatorsListPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={DISCOVERS.to}>
              <DiscoversListPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={DISCOVER_NEW.to}>
              <NewDiscoverPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={DISCOVER_EDIT.to}>
              <DiscoverDetailsPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={FEATUREDS.to}>
              <FeaturedsListPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={FEATURED_EDIT.to}>
              <FeaturedDetailsPage config={config.aws} />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={STORIES.to}>
              <StoriesListPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={STORY_NEW.to}>
              <NewStoryPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={STORY_EDIT.to}>
              <StoryDetailsPage config={config.aws} />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={STYLES.to}>
              <StylesListPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={STYLE_NEW.to}>
              <NewStylePage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={STYLE_EDIT.to}>
              <StyleDetailsPage config={config.aws} />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={FEED.to}>
              <FeedCollectionPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={CHARGE_NEW.to}>
              <ChargeNewPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={CHARGE_EDIT.to}>
              <ChargeNewPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={PAYOUT_NEW.to}>
              <PayoutNewPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={POSTS.to}>
              <FeedCollectionPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={PROFILES.to}>
              <ProfilesCollectionPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={PROFILE_NEW.to}>
              <ProfileNewPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={PROFILE_DETAILS.to}>
              <ProfileDetailsPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={PROFILE_EDIT.to}>
              <ProfileEditPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={USERS.to}>
              <UsersSearchPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={MUSIC.to}>
              <MusicListPage />
            </Private>
            <Private isLoggedIn={isLoggedIn} exact path={WALLET.to}>
              <NftPage />
            </Private>
            <Route render={() => <h1>404: page not found</h1>} />
          </Switch>
        </Router>
      </LoadingProvider>
    </NotificationProvider>
  );
};

export default App;
