import React, { useState } from 'react';
import { Container, Notification, Progress } from 'react-bulma-components';
import { Link } from 'react-router-dom';

import { PROFILE_NEW, PROFILES, ROOT } from '../../constants/routes';
import Api from '../../services/api';
import { emptyProfile as empty, Profile } from '../../types';
import {
  AdditionalsRaw,
  Socials,
  TagsRaw,
  YouTube,
  YouTubeNumbersFlattened,
} from '../../types/profile';

import Navigation from '../Navigation';

import ProfileForm from './form';
import { emptyFiles } from './types';

const ProfileNewPage: React.FC = function ProfileNewPage() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile>(empty);
  const [notification, setNotification] = useState<{
    message: string;
    color: 'success' | 'danger';
  } | null>(null);

  const onAdditionalsChange =
    (fieldName: keyof AdditionalsRaw) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      let { value } = event.currentTarget;
      const { additionalsRaw } = profile;
      const fieldType = typeof (additionalsRaw && additionalsRaw[fieldName]);
      if (fieldType !== 'undefined' && fieldType !== 'string') {
        value = JSON.parse(value);
      }
      // console.log(fieldType, fieldName, value);
      setProfile({
        ...profile,
        additionalsRaw: { ...additionalsRaw, [fieldName]: value || '' },
      });
    };

  const onNumbersChange =
    (fieldName: keyof YouTubeNumbersFlattened) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const { value } = event.currentTarget;
      const { numbers } = profile;
      setProfile({ ...profile, numbers: { ...numbers, [fieldName]: value } });
    };

  const onSocialsChange =
    (fieldName: keyof Socials) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const { value } = event.currentTarget;
      const { socials } = profile;
      const { discord, instagram, tiktok, twitter, website } = socials || {};

      let updated = socials;
      if (fieldName === 'discord') {
        updated = {
          ...socials,
          discord: { ...discord, serverUrl: value || '' },
        };
      } else if (fieldName === 'instagram') {
        updated = {
          ...socials,
          instagram: { ...instagram, pageUrl: value || '' },
        };
      } else if (fieldName === 'tiktok') {
        updated = {
          ...socials,
          tiktok: { ...tiktok, profileUrl: value || '' },
        };
      } else if (fieldName === 'twitter') {
        updated = {
          ...socials,
          twitter: { ...twitter, handleUrl: value || '' },
        };
      } else if (fieldName === 'website') {
        updated = {
          ...socials,
          website: { ...website, homePageUrl: value || '' },
        };
      }
      // console.log(fieldType, fieldName, value);
      setProfile({
        ...profile,
        socials: updated,
      });
    };

  const onTagsChange =
    (fieldName: keyof TagsRaw) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      let { value } = event.currentTarget;
      const { tagsRaw } = profile;
      const fieldType = typeof (tagsRaw && tagsRaw[fieldName]);
      if (fieldType !== 'undefined' && fieldType !== 'string') {
        value = JSON.parse(value);
      }
      // console.log(fieldType, fieldName, value);
      setProfile({
        ...profile,
        tagsRaw: { ...tagsRaw, [fieldName]: value || '' },
      });
    };

  const onYouTubeChange =
    (fieldName: keyof YouTube) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      let { value } = event.currentTarget;
      const { socials } = profile;
      const { youtube } = socials || {};
      const fieldType = typeof (youtube && youtube[fieldName]);
      if (fieldType !== 'undefined' && fieldType !== 'string') {
        value = JSON.parse(value);
      }
      // console.log(fieldType, fieldName, value);
      setProfile({
        ...profile,
        socials: {
          ...socials,
          youtube: { ...youtube, [fieldName]: value || '' },
        },
      });
    };

  const onChange =
    (fieldName: keyof Profile) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      let { value } = event.currentTarget;
      const fieldType = typeof profile[fieldName];
      if (fieldType !== 'undefined' && fieldType !== 'string') {
        value = JSON.parse(value);
      }
      // console.log(fieldType, fieldName, value);
      setProfile({
        ...profile,
        [fieldName]: value,
      });
    };

  const onSave = async () => {
    setLoading(true);
    try {
      await Api.createProfile(profile);
      setNotification({
        message: 'Profile created successfully',
        color: 'success',
      });
      const { userName } = profile;
      window.location.href = `${PROFILES.to}/${userName}/edit`;
    } catch (error: any) {
      setNotification({
        message: error.response?.data?.status?.message || error.message,
        color: 'danger',
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Navigation />
      <Container>
        <br />
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li>
              <Link to={PROFILES.to}>{PROFILES.title}</Link>
            </li>
            <li className="is-active">
              <Link to={`${PROFILE_NEW.to}/new`}>New</Link>
            </li>
          </ul>
        </nav>
        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <>
            {notification && (
              <Notification color={notification.color} colorVariant="light">
                <button
                  aria-label="close"
                  className="delete"
                  onClick={() => setNotification(null)}
                  type="button"
                />
                {notification.message}
              </Notification>
            )}
            <ProfileForm
              isNew
              files={emptyFiles}
              onChange={onChange}
              onAdditionalsChange={onAdditionalsChange}
              onNumbersChange={onNumbersChange}
              onSocialsChange={onSocialsChange}
              onTagsChange={onTagsChange}
              onYouTubeChange={onYouTubeChange}
              onSave={onSave}
              profile={profile}
            />
          </>
        )}
        <br />
      </Container>
    </>
  );
};

export default ProfileNewPage;
