import React, { FormEvent, useEffect, useState } from 'react';

import { USERS, ROOT } from '../../constants/routes';
import { useNotification } from '../../contexts/notification';
import Api from '../../services/api';
import {
  emptyUserSearchQuery,
  UserSearchField,
  UserSearchQuery,
} from '../../types/api';

import Layout, { Path } from '../Layout';
import UserSearchForm from './form';
import UsersTable from './table';

import { User } from './types';

const UsersSearchPage: React.FC = function UsersSearchPage(): JSX.Element {
  const breadcrumbs: Path[] = [{ route: ROOT }, { route: USERS, active: true }];

  const { setNotification } = useNotification();
  const [list, setList] = useState<User[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setLoadingMore] = useState(true);
  const [isSearching, setSearching] = useState(true);
  const [type, setType] = useState<UserSearchField>(UserSearchField.ID);

  const [query, setQuery] = useState<UserSearchQuery>(emptyUserSearchQuery);
  const [last, setLast] = useState<UserSearchQuery | undefined>(undefined);

  useEffect(() => {
    if (!isSearching) {
      return () => {};
    }
    let mounted = true;
    const fetchAndSetData = async () => {
      const limit = 30;
      const { id: after } = list[list.length - 1] || {};

      setNotification(undefined);
      const users = await Api.search({ ...(last || query), after, limit });
      if (mounted) {
        setHasMore(users.length >= limit);
        setLast(query);
        setList((previousList) => [...previousList, ...users]);
        setLoadingMore(false);
        setSearching(false);
      }
    };

    fetchAndSetData().catch((err) => {
      const { data } = err?.response || {};
      const message = data?.status?.message || err.message;
      setNotification(message);
      setLoadingMore(false);
      setSearching(false);
    });

    return () => {
      mounted = false;
    };
  }, [isSearching]);

  const onSearch = () => {
    const { emailAddress, ethAddress, phoneNumber, userId } = query;
    if (!(emailAddress || ethAddress || phoneNumber || userId)) return;
    setHasMore(true);
    setLast(undefined);
    setList([]);
    setLoadingMore(true);
    setSearching(true);
  };

  const onTextChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setQuery({ ...query, [type]: value.trim() });
  };

  const onTypeChange = (event: FormEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    const fieldType = value as UserSearchField;
    const term = query[type];
    setType(fieldType);
    setQuery({
      ...query,
      [UserSearchField.ID]: undefined,
      [UserSearchField.Email]: undefined,
      [UserSearchField.Phone]: undefined,
      [UserSearchField.Wallet]: undefined,
      [fieldType]: term,
    });
  };

  const onUserUpdated = (user: User) => {
    setList(list.map((u) => (u.id === user.id ? user : u)));
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <UserSearchForm
        fieldType={type}
        query={query}
        onSearch={onSearch}
        onTextChange={onTextChange}
        onTypeChange={onTypeChange}
      />
      <UsersTable
        list={list}
        hasMore={hasMore}
        isLoadingMore={isLoadingMore}
        onLoadMore={() => {
          setSearching(true);
          setLoadingMore(true);
        }}
        onUserUpdated={onUserUpdated}
      />
    </Layout>
  );
};

export default UsersSearchPage;
