import React from 'react';
import {
  Breadcrumb,
  Button,
  Container,
  Notification,
  Progress,
} from 'react-bulma-components';
import { Link } from 'react-router-dom';

import { useLoading } from '../../contexts/loading';
import { useNotification } from '../../contexts/notification';

import Navigation from '../Navigation';
import { Path } from './types';

type Props = React.PropsWithChildren<{
  breadcrumbs: Path[];
}>;

const Layout: React.FC<Props> = function Layout({
  breadcrumbs,
  children,
}: Props) {
  const { isLoading } = useLoading();
  const { notification, setNotification } = useNotification();

  return (
    <>
      <Navigation />
      <Container>
        <br />
        <Breadcrumb>
          {breadcrumbs.map(
            ({ active, route: { to, title }, titleAlt }, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <Breadcrumb.Item key={idx} active={active}>
                <Link to={to}>{titleAlt || title}</Link>
              </Breadcrumb.Item>
            )
          )}
        </Breadcrumb>
        {notification && (
          <Notification color="danger" colorVariant="light">
            <Button remove onClick={() => setNotification('')} />
            {notification}
          </Notification>
        )}
        {isLoading ? <Progress max={100} color="info" /> : children}
        <br />
      </Container>
    </>
  );
};

export default Layout;
export * from './types';
