import React, { useEffect, useState } from 'react';

import { ROOT, STYLES, STYLE_NEW } from '../../constants/routes';
import { useLoading } from '../../contexts/loading';

import { useFirebase } from '../Firebase';
import Layout, { Path } from '../Layout';

import StylesTable from './table';
import { converter, Style } from './types';

const StylesListPage = function StylesListPage() {
  const firebase = useFirebase()!;
  const { setLoading } = useLoading();
  const [styles, setStyles] = useState<Style[]>([]);

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const fetchAndSetData = async () => {
      const snapshot = await firebase.db
        .collection('selectStyles')
        .withConverter(converter)
        .get();
      const styles1: Style[] = snapshot.docs.map((doc) => doc.data()!);
      if (mounted) {
        // console.log(JSON.stringify(styles1[0], null, 4));
        setStyles(styles1);
        setLoading(false);
      }
    };
    fetchAndSetData().catch((error) =>
      // eslint-disable-next-line no-console
      console.error('fetchAndSetData ERR', error)
    );
    return () => {
      mounted = false;
    };
  }, [setLoading, firebase.db]);

  const breadcrumbs: Path[] = [
    { route: ROOT },
    { route: STYLES, active: true },
    { route: STYLE_NEW },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <StylesTable styles={styles} />
    </Layout>
  );
};

export default StylesListPage;
