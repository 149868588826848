// @ts-ignore
import {mutate, tx} from '@onflow/fcl';
// @ts-ignore
import * as fcl from '@onflow/fcl';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {Button, Columns} from "react-bulma-components";

import Layout, {Path} from '../Layout';
import {MUSIC, ROOT} from '../../constants/routes';

fcl.config()
  .put("accessNode.api", "https://flow-access-mainnet.portto.io") // connect to Flow testnet
  .put("challenge.handshake", "https://flow-wallet.blocto.app/authn") // use Blocto testnet wallet
  .put("0xEverbloom", "0xe703f7fee6400754") // use Blocto testnet wallet

const SignInOutButton = function SignInOutButton ({user: {loggedIn}}: any) {
  const signInOrOut = async (event: any) => {
    event.preventDefault()
    if (loggedIn) {
      fcl.unauthenticate()
    } else {
      fcl.authenticate()
    }
  }
  return (
    <Button fullwidth color={loggedIn ? 'danger' : 'success'} outlined={false} onClick={signInOrOut}>
      {loggedIn ? 'Sign Out' : 'Sign In'}
    </Button>
  )
}

const transferTransaction = `
import Everbloom from 0xEverbloom

transaction(accountDict: {UInt64: Address}) {
    prepare(signer: AuthAccount) {
        let collectionRef = signer.borrow<&Everbloom.Collection>(from: Everbloom.CollectionStoragePath)!

        for nftId in accountDict.keys {
            let recipient = getAccount(accountDict[nftId]!)

            let depositRef = recipient.getCapability(Everbloom.CollectionPublicPath).borrow<&{Everbloom.PrintCollectionPublic}>()
                ?? panic("cannot borrow reciever collection reference")

            var nft <-  collectionRef.withdraw(withdrawID: nftId)
            depositRef.deposit(token: <-nft)
        }
    }
}
`;

const NftPage: React.FC = function NftPage() {
  const breadcrumbs: Path[] = [
    {route: ROOT},
    {
      route: MUSIC,
      active: true,
      titleAlt: 'Wallet'
    },
  ];
  const [user, setUser] = useState<any>({});
  const [transactionStatus, setTransactionStatus] = useState<string | undefined>(undefined);
  const [transactionId, setTransactionId] = useState<string | undefined>(undefined);
  const [accounts, setAccounts] = useState<{ address: string, printId: string }[]>([
    {address: '', printId: ''},
  ]);

  useEffect(() =>
      fcl
        .currentUser()
        .subscribe((newuser: any) => {
          setUser(newuser)
        })
    , []);


  const handleInputChange = (type: 'address' | 'printId') => (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const newAccounts = accounts;
    newAccounts[index][type] = event.target.value;
    setAccounts([...newAccounts]);
  }
  const handleTransferNft = async () => {
    const accountDictionary = accounts.map((account) => ({
      key: parseInt(account.printId, 10),
      value: account.address,
    }));
    try {
      const txidForUpdate = await mutate({
        cadence: transferTransaction,
        args: (arg: any, t: any) => [
          arg(accountDictionary, t.Dictionary({key: t.UInt64, value: t.Address})),
        ],
        limit: 9999,
      });
      setTransactionStatus('Transaction Sent')
      setTransactionId(txidForUpdate)
      const updateTxStatus = await tx(txidForUpdate).onceSealed()

      setTransactionStatus(updateTxStatus)
      setTransactionStatus('Transaction Sealed')
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Columns justifyContent="flex-end">
        <Columns.Column size={user.loggedIn ? 3 : 12}>
          <SignInOutButton user={user}/>
        </Columns.Column>
      </Columns>
      {
        user.loggedIn && <>
          <hr/>
          {accounts.map((account, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Columns key={`account-col-${index}`} centered justifyContent="flex-start">
              <Columns.Column size={4}>
                <input
                  className="input"
                  onChange={handleInputChange('address')(index)}
                  value={account.address}
                  type="text"
                  placeholder="Address"
                />
              </Columns.Column>
              <Columns.Column size={4}>
                <input
                  className="input"
                  onChange={handleInputChange('printId')(index)}
                  value={account.printId}
                  type="text"
                  placeholder="print Id"
                />
              </Columns.Column>
            </Columns>))}
          <Columns centered justifyContent="flex-start">
            {accounts.length <= 10 && <Columns.Column size={5}>
              <Button
                color="info"
                onClick={() => setAccounts((accountsState) => ([
                  ...accountsState,
                  {address: '', printId: ''}
                ]))}
              >
                Add Address
              </Button>
            </Columns.Column>}
            <Columns.Column size={8}>
              <Button
                fullwidth
                color="success"
                onClick={handleTransferNft}
                disabled={transactionStatus !== undefined || accounts.length <= 0}
              >
                {transactionStatus || 'Transfer NFTs'}
              </Button>
            </Columns.Column>
            {transactionId && <Columns.Column size={8}>
              <a href={`https://flowscan.org/transaction/${transactionId}`}>
                Check Transaction on Flowscan
              </a>
            </Columns.Column>}
          </Columns>
        </>
      }
    </Layout>
  );
};

export default NftPage;
