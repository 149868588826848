import React from 'react';
import axios from 'axios';
import { Button, Progress, Table } from 'react-bulma-components';

import { MUSIC_API } from '../../constants/routes';
import { useNotification } from '../../contexts/notification';
import { useCurated } from '../../hooks';

import MusicRow from './row';
import { Music } from './types';
import { useFirebaseToken } from '../Firebase';

type Props = {
  hasMore?: boolean;
  isLoadingMore?: boolean;
  list: Music[];
  onLoadMore: () => void;
};

const MusicTable: React.FC<Props> = function MusicTable({
  hasMore,
  isLoadingMore,
  list,
  onLoadMore,
}) {
  const published = useCurated('published');
  const publishedTitle =
    // eslint-disable-next-line no-nested-ternary
    published === null ? '' : published === true ? 'published' : 'unpublished';
  const [token] = useFirebaseToken();
  const { setNotification } = useNotification();

  const publishMusic = (id: string, isPublished: boolean) =>
    axios.put(
      `${MUSIC_API.to}/${id}`,
      { music: { isPublished } },
      { headers: { Authorization: `Firebase ${token}` } }
    );

  const onPublish = async ({ id, title, isPublished }: Music) => {
    const isPublishTitle = isPublished === true ? 'unpublish' : 'publish';
    if (
      // eslint-disable-next-line no-alert
      window.confirm(`You are about to ${isPublishTitle} a Music: ${title}.`)
    ) {
      try {
        await publishMusic(id, !isPublished);
        window.location.reload();
      } catch (err: any) {
        setNotification(err.message);
      }
    }
  };

  return (
    <Table hoverable className="is-fullwidth">
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Profile</th>
          <th>Published?</th>
          <th>Play</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {list && list.length > 0 ? (
          list.map((music) => (
            <MusicRow
              key={music.id}
              music={music}
              onPublish={() => onPublish(music)}
            />
          ))
        ) : (
          <tr>
            <th colSpan={6}>
              <i>There is no {publishedTitle} music.</i>
            </th>
          </tr>
        )}
        {hasMore &&
          (isLoadingMore ? (
            <tr>
              <th colSpan={6}>
                <Progress max={100} color="info" />
              </th>
            </tr>
          ) : (
            <tr>
              <th colSpan={6}>
                <Button
                  text
                  inverted
                  fullwidth
                  color="info"
                  className="is-ghost"
                  onClick={() => onLoadMore()}
                >
                  <i>load more</i>
                </Button>
              </th>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

MusicTable.defaultProps = {
  hasMore: false,
  isLoadingMore: false,
};

export default MusicTable;
