import React, { useContext, useMemo } from 'react';

type Props = React.PropsWithChildren<{
  isLoading: boolean;
  setLoading: (a: boolean) => void;
}>;

const LoadingContext = React.createContext<Props>({
  isLoading: false,
  setLoading: () => {},
});

export const LoadingProvider = function LoadingProvider({
  children,
  isLoading,
  setLoading,
}: Props) {
  const value = useMemo(
    () => ({ isLoading, setLoading }),
    [isLoading, setLoading]
  );

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);

export default LoadingProvider;
