import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  CREATORS,
  PAYOUTS,
  PAYOUT_NEW,
  PROFILES,
  ROOT,
} from '../../constants/routes';
import { useNotification } from '../../contexts/notification';
import Api from '../../services/api';
import { emptyProfile as empty, Profile } from '../../types';
import {
  emptyPayoutRequest,
  PayoutRequest,
  PayoutResult,
} from '../../types/api';

import Layout, { Path } from '../Layout';
import PayoutForm from './form';

type Params = { userName: string };

const PayoutNewPage: React.FC = function PayoutNewPage() {
  const { userName } = useParams<Params>();
  const [isLoadingPayout, setLoadingPayout] = useState<boolean>(false);
  const [isLoadingProfile, setLoadingProfile] = useState<boolean>(false);
  const [payout, setPayout] = useState<PayoutRequest>(emptyPayoutRequest);
  const [profile, setProfile] = useState<Profile>(empty);
  const { setNotification } = useNotification();
  const [result, setResult] = useState<PayoutResult | undefined>();

  const onChange =
    (fieldName: keyof PayoutRequest) =>
    (event: React.FormEvent<HTMLInputElement>) => {
      let { value } = event.currentTarget;
      const fieldType = typeof payout[fieldName];
      if (fieldType !== 'undefined' && fieldType !== 'string') {
        value = JSON.parse(value);
      }
      setPayout({ ...payout, dryRun: true, [fieldName]: value });
    };

  const onRequest = async () => {
    const request = async () => {
      setNotification(undefined);
      const { dryRun } = payout;
      let confirmed = dryRun;
      if (!confirmed) {
        // eslint-disable-next-line no-alert
        const prompt = window.prompt(
          `You are about distribute payout to all ${userName}'s collectors. Make sure all the preview data is all correct including the (quarterly) period's start & end dates. \n\nType 'distribute ${userName} payout' below to continue.`
        );
        confirmed = prompt === `distribute ${userName} payout`;
        if (!confirmed) {
          setNotification('Incorrect confirmation message. Please try again.');
        }
      }
      if (confirmed) {
        setLoadingPayout(true);
        const result0 = await Api.createPayout(payout);
        setResult(result0);
        setPayout({ ...payout, dryRun: false });
        setLoadingPayout(false);
      }
    };

    request().catch((err) => {
      const { data } = err.response || {};
      const { message } = data?.status || err;
      setNotification(message);
      setLoadingPayout(false);
    });
  };

  useEffect(() => {
    let mounted = true;
    setLoadingProfile(true);
    setNotification(undefined);

    const fetchAndSetData = async () => {
      const profile0 = await Api.getProfile({ userName });
      if (mounted) {
        setProfile(profile0);
        const { id: creatorId } = profile0;
        setPayout({ ...payout, creatorId });
        setLoadingProfile(false);
      }
    };

    fetchAndSetData().catch((err) => {
      const { data } = err.response || {};
      const { message } = data?.status || err;
      setNotification(message);
      setLoadingProfile(false);
    });

    return () => {
      mounted = false;
    };
  }, [userName]);

  const breadcrumbs: Path[] = [
    { route: ROOT },
    { route: CREATORS },
    { route: { to: `${PROFILES.to}/${userName}`, title: userName } },
    {
      route: { to: `${PAYOUTS.to}/${userName}/new`, title: PAYOUT_NEW.title },
      active: true,
    },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <PayoutForm
        onChange={onChange}
        onRequest={onRequest}
        payout={payout}
        profile={profile}
        result={result}
        isLoadingPayout={isLoadingPayout}
        isLoadingProfile={isLoadingProfile}
      />
    </Layout>
  );
};

export default PayoutNewPage;
