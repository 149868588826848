import React, { useState } from 'react';
import { Container, Notification, Progress } from 'react-bulma-components';
import { Link, useHistory } from 'react-router-dom';

import { ROOT, STYLES, STYLE_NEW } from '../../constants/routes';

import { useFirebase } from '../Firebase';
import Navigation from '../Navigation';

import StyleForm from './form';
import { converter, empty, emptyFiles, Style } from './types';

const NewStylePage = function NewStylePage() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string | null>(
    null
  );
  const [style, setStyle] = useState<Style>({ ...empty });
  const history = useHistory();

  const firebase = useFirebase()!;
  const onChange =
    (fieldName: keyof Style) =>
    (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      let { value } = event.currentTarget;
      if (typeof style[fieldName] !== 'string') {
        value = JSON.parse(value);
      } else if (fieldName === 'id') {
        value = value.replace(/[^a-z0-9]/gi, '').toLowerCase();
      }
      // console.log(fieldName, value);
      const updated: Style = { ...style, [fieldName]: value };
      setStyle(updated);
    };

  const saveStyle = async (style1: Style) => {
    const data = (
      await firebase.db
        .collection('selectStyles')
        .withConverter(converter)
        .limit(1)
        .get()
    ).docs[0].data()!;

    const { id } = style1;
    const ref = firebase.db.collection('selectStyles').doc(id);
    await ref.set(Object.assign(data, style1), { merge: false });
  };

  const onSave = async () => {
    // console.log('onSave');

    setLoading(true);
    try {
      await saveStyle(style);
      history.push(`${STYLES}/${style.id}`);
    } catch (error: any) {
      // console.log('onSave ERR', error);
      setNotificationMessage(error.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Navigation />
      <Container>
        <br />
        {notificationMessage && (
          <Notification color="danger" colorVariant="light">
            <button
              aria-label="close"
              className="delete"
              onClick={() => setNotificationMessage(null)}
              type="button"
            />
            {notificationMessage}
          </Notification>
        )}
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li>
              <Link to={STYLES.to}>{STYLES.title}</Link>
            </li>
            <li className="is-active">
              <Link to={STYLE_NEW.to}>{STYLE_NEW.title}</Link>
            </li>
          </ul>
        </nav>

        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <StyleForm
            files={emptyFiles}
            style={style}
            onChange={onChange}
            onSave={onSave}
            isNew
          />
        )}
        <br />
      </Container>
    </>
  );
};

export default NewStylePage;
