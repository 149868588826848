import React from 'react';
import { Button, Columns, Form, Image } from 'react-bulma-components';
import { Featured, Tiers } from './types';

type Props = {
  featured: Featured;
  onApply: () => void;
  onChange: (
    fieldName: keyof Featured
  ) => (
    event: React.FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  onFileChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onSave: () => void;
  image?: File;
  isEditable: boolean;
};

const FeaturedForm = function FeaturedForm({
  featured,
  onApply,
  onChange,
  onFileChange,
  onSave,
  image,
  isEditable,
}: Props) {
  const { id, title, cardSubtitle, subtitle, tier, storyType, imageUrl } =
    featured;

  return (
    <>
      <Form.Field className="is-horizontal">
        <Form.Field.Label>
          <Form.Label>ID</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Input type="text" placeholder="ID" value={id} disabled />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Title</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Input
                id="title"
                type="text"
                placeholder="Title"
                value={title}
                disabled={!isEditable}
                onChange={onChange('title')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Card Subtitle</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Input
                id="cardSubtitle"
                type="text"
                placeholder="Card Subtitle"
                value={cardSubtitle}
                disabled={!isEditable}
                onChange={onChange('cardSubtitle')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Subtitle</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Textarea
                id="subtitle"
                placeholder="Subtitle"
                value={subtitle}
                disabled={!isEditable}
                onChange={onChange('subtitle')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Tier</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field className="is-narrow">
            <Form.Control>
              <Form.Select
                id="tier"
                value={tier}
                disabled={!isEditable}
                onChange={onChange('tier')}
              >
                {Object.keys(Tiers).map((tier1) => (
                  <option key={tier1} value={tier1}>
                    {tier1}
                  </option>
                ))}
              </Form.Select>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Story Type</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Input
                id="storyType"
                type="text"
                placeholder="Story Type"
                value={storyType}
                disabled={!isEditable}
                onChange={onChange('storyType')}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal>
        <Form.Field.Label>
          <Form.Label>Image</Form.Label>
        </Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Columns>
              <Columns.Column>
                <Image
                  fallback=""
                  size="4by3"
                  src={(image && URL.createObjectURL(image)) || imageUrl}
                />
              </Columns.Column>
              <Columns.Column />
            </Columns>
            {isEditable && (
              <Columns>
                <Columns.Column>
                  <Form.InputFile
                    boxed
                    color="warning"
                    fullwidth
                    className={image ? 'has-name' : ''}
                    label="Upload & Change Image"
                    inputProps="image"
                    onChange={onFileChange}
                  />
                </Columns.Column>
                <Columns.Column />
              </Columns>
            )}
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <br />

      {isEditable && (
        <Form.Field horizontal>
          <Form.Field.Label />
          <Form.Field.Body>
            <Form.Field>
              <Columns>
                <Columns.Column>
                  <Form.Control>
                    <Button fullwidth color="primary" onClick={onSave}>
                      Save as &lsquo;{id}&rsquo;
                    </Button>
                  </Form.Control>
                </Columns.Column>
                <Columns.Column>
                  <Form.Control>
                    <Button fullwidth color="warning" onClick={onApply}>
                      Apply as &lsquo;current&rsquo;
                    </Button>
                  </Form.Control>
                </Columns.Column>
              </Columns>
            </Form.Field>
          </Form.Field.Body>
        </Form.Field>
      )}
    </>
  );
};

FeaturedForm.defaultProps = {
  image: undefined,
};

export default FeaturedForm;
