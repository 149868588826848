import React, { useState } from 'react';
import { Columns } from 'react-bulma-components';

import { Post } from '../../types';

import PostCard from './card';
import PostDetailsModal from './details';

interface Props {
  posts: Post[];
  mobile?: boolean;
  showProfile?: boolean;
}

const PostsCollection: React.FC<Props> = function PostsCollection({
  posts,
  mobile,
  showProfile,
}: Props) {
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);

  const onClose = () => setSelectedPost(null);

  return (
    <>
      <Columns centered>
        {posts.map((post) => (
          <Columns.Column key={post.id} size={mobile ? 6 : 3}>
            <PostCard
              post={post}
              setSelected={() => setSelectedPost(post)}
              showProfile={!!showProfile}
            />
          </Columns.Column>
        ))}
      </Columns>
      {selectedPost && (
        <PostDetailsModal post={selectedPost} onClose={onClose} />
      )}
    </>
  );
};

PostsCollection.defaultProps = { mobile: false, showProfile: true };

export default PostsCollection;
