import React from 'react';
import { Button, Image } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import { Featured } from './types';
import { FEATUREDS } from '../../constants/routes';

type Props = {
  featured: Featured;
  isEditable: boolean;
};

const FeaturedRow = function FeaturedRow({
  featured: { id, title, subtitle, imageUrl },
  isEditable,
}: Props) {
  const to = `${FEATUREDS.to}/${id}`;
  return (
    <tr>
      <th style={{ whiteSpace: 'nowrap' }}>
        <Link to={to}>{id}</Link>
      </th>
      <td style={{ whiteSpace: 'nowrap' }}>{title}</td>
      <td>
        <Image src={imageUrl} />
      </td>
      <td>{subtitle}</td>
      <td style={{ whiteSpace: 'nowrap' }}>
        <Link to={to}>
          <Button outlined color={isEditable ? 'warning' : 'primary'}>
            {isEditable ? 'Edit' : 'View'}
          </Button>
        </Link>
      </td>
    </tr>
  );
};

export default FeaturedRow;
