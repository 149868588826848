import React, { useEffect, useState } from 'react';
import { Container, Notification, Progress } from 'react-bulma-components';
import { Link, useParams } from 'react-router-dom';

import { AwsConfig } from '../../config';
import { ROOT, STORIES, STORY_EDIT } from '../../constants/routes';
import { uploadFile } from '../../utils';

import { useFirebase } from '../Firebase';
import Navigation from '../Navigation';

import StoryForm from './form';
import { converter, empty, emptyVideoFiles, Story } from './types';

const StoryDetailsPage = function StoryDetailsPage({
  config,
}: {
  config: AwsConfig;
}) {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [story, setStory] = useState<Story>({ ...empty, id });
  const [videoFiles, setFiles] = useState(emptyVideoFiles);
  const { thumbnailVideo, thumbnailVideoImage, video, videoImage } = videoFiles;
  const [notificationMessage, setNotificationMessage] = useState<string | null>(
    null
  );

  const firebase = useFirebase()!;
  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const getStory = async () => {
      const doc = await firebase.db
        .collection('stories2.0')
        .withConverter(converter)
        .doc(id)
        .get();

      if (doc.exists && mounted) {
        const data = doc.data()!;
        // console.log('getStory', data);
        setStory(data);
      } else {
        // TODO: show 404 - not found
        // eslint-disable-next-line no-console
        console.log('getStory !exists');
      }
      setLoading(false);
    };
    // eslint-disable-next-line no-console
    getStory().catch((error) => console.error('getStory ERR', error));
    return () => {
      mounted = false;
    };
  }, [firebase.db, id]);

  const onChange =
    (fieldName: keyof Story) =>
    (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      let { value } = event.currentTarget;
      if (typeof story[fieldName] !== 'string') {
        value = JSON.parse(value);
      }
      // console.log(fieldName, value);
      const updated: Story = { ...story, [fieldName]: value };
      setStory(updated);
    };

  const onFileChange = (event: React.FormEvent<HTMLInputElement>) => {
    // console.log(event.currentTarget.id, event.currentTarget.value);
    const { files } = event.currentTarget;
    setFiles({
      ...videoFiles,
      [event.currentTarget.id]: files && files.length > 0 ? files[0] : null,
    });
  };

  const updateStory = async (story1: Story) => {
    const ref = await firebase.db.collection('stories2.0').doc(id);
    await ref.set(story1, { merge: true });
  };

  const onSave = async () => {
    // console.log('onSave');

    const type = 'story';
    const directory = config.s3.directories[type];
    const subDirectory = 'cms';
    const cloudfrontBaseUrl = config.cloudfront.baseUrls.assets;

    setLoading(true);
    try {
      let saveThumbnailVideoImage;
      let saveVideoImage;
      if (thumbnailVideoImage) {
        saveThumbnailVideoImage = uploadFile({
          type,
          directory,
          id,
          subDirectory,
          file: thumbnailVideoImage!,
        });
      }
      if (videoImage) {
        saveVideoImage = uploadFile({
          type,
          directory,
          id,
          subDirectory,
          file: videoImage!,
        });
      }
      const [saveThumbnailVideoImageResult, saveVideoImageResult] =
        await Promise.all([saveThumbnailVideoImage, saveVideoImage]);
      if (
        saveThumbnailVideoImageResult &&
        saveThumbnailVideoImageResult.status === 204
      ) {
        story.thumbImageUrl = `${cloudfrontBaseUrl}/${directory}/${id}/${subDirectory}/${encodeURI(
          thumbnailVideoImage!.name
        )}`;
        setFiles({ ...videoFiles, thumbnailVideoImage: null });
      }
      if (saveVideoImageResult && saveVideoImageResult.status === 204) {
        story.imageUrl = `${cloudfrontBaseUrl}/${directory}/${id}/${subDirectory}/${encodeURI(
          videoImage!.name
        )}`;
        setFiles({ ...videoFiles, videoImage: null });
      }

      let saveThumbnailVideo;
      let saveVideo;
      if (thumbnailVideo) {
        saveThumbnailVideo = uploadFile({
          type,
          directory,
          id,
          subDirectory,
          file: thumbnailVideo,
        });
      }
      if (video) {
        saveVideo = uploadFile({
          type,
          directory,
          id,
          subDirectory,
          file: video,
        });
      }
      const [saveThumbnailVideoResult, saveVideoResult] = await Promise.all([
        saveThumbnailVideo,
        saveVideo,
      ]);
      if (video || thumbnailVideo) {
        setNotificationMessage(
          'Videos (resolutions) are being processed on the server and will be updated in about 15 minutes. Please refresh this page again later.'
        );
      }
      if (saveThumbnailVideoResult && saveThumbnailVideoResult.status === 204) {
        story.thumbVideoUrl = `${cloudfrontBaseUrl}/${directory}/${id}/${subDirectory}/${encodeURI(
          thumbnailVideo!.name
        )}`;
        setFiles({ ...videoFiles, thumbnailVideo: null });
      }
      if (saveVideoResult && saveVideoResult.status === 204) {
        story.videoUrl = `${cloudfrontBaseUrl}/${directory}/${id}/${subDirectory}/${encodeURI(
          video!.name
        )}`;
        setFiles({ ...videoFiles, video: null });
      }

      await updateStory(story);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('updateStory ERR', error);
    }
    setLoading(false);
  };

  return (
    <>
      <Navigation />
      <Container>
        <br />
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li>
              <Link to={STORIES.to}>{STORIES.title}</Link>
            </li>
            <li className="is-active">
              <Link to={STORY_EDIT.to}>{story.id}</Link>
            </li>
          </ul>
        </nav>
        {notificationMessage && (
          <Notification color="warning">
            <button
              aria-label="close"
              className="delete"
              onClick={() => setNotificationMessage(null)}
              type="button"
            />
            {notificationMessage}
          </Notification>
        )}

        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <StoryForm
            files={videoFiles}
            story={story}
            onChange={onChange}
            onFileChange={onFileChange}
            onSave={onSave}
          />
        )}
        <br />
      </Container>
    </>
  );
};

export default StoryDetailsPage;
