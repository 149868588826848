import React, { useEffect, useState } from 'react';
import { Container, Progress } from 'react-bulma-components';
import { Link } from 'react-router-dom';

import { ROOT, FEATUREDS } from '../../constants/routes';

import { documentId, useFirebase } from '../Firebase';
import Navigation from '../Navigation';

import FeaturedsTable from './table';
import { converter, Featured, IDs } from './types';

const FeaturedsListPage = function FeaturedsListPage(): JSX.Element {
  const firebase = useFirebase()!;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [featureds, setFeatureds] = useState<Featured[]>([]);

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const fetchAndSetData = async () => {
      const snapshot = await firebase.db
        .collection('featuredTemplates')
        .withConverter(converter)
        .where(documentId(), 'in', Object.keys(IDs))
        .get();
      const featureds1: Featured[] = snapshot.docs.map((doc) => doc.data()!);
      if (mounted) {
        // console.log(JSON.stringify(featureds1[0], null, 4));
        setFeatureds(featureds1);
        setLoading(false);
      }
    };
    fetchAndSetData().catch((error) =>
      // eslint-disable-next-line no-console
      console.error('fetchAndSetData ERR', error)
    );
    return () => {
      mounted = false;
    };
  }, [firebase.db]);

  return (
    <>
      <Navigation />
      <Container>
        <br />
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li className="is-active">
              <Link to={FEATUREDS.to}>{FEATUREDS.title}</Link>
            </li>
          </ul>
        </nav>
        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <FeaturedsTable featureds={featureds} />
        )}
        <br />
      </Container>
    </>
  );
};

export default FeaturedsListPage;
