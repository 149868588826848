import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { FirebaseConfig } from '../../config';

export { firebase };

class Firebase {
  app: firebase.app.App;

  auth: firebase.auth.Auth;

  db: firebase.firestore.Firestore;

  constructor({ config }: { config: FirebaseConfig }) {
    this.app = firebase.initializeApp(config);
    this.auth = this.app.auth();
    this.db = this.app.firestore();
  }

  signinWithCustomToken = (customToken: string) =>
    this.auth.signInWithCustomToken(customToken);

  signout = () => this.auth.signOut();
}

export default Firebase;
