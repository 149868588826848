import React, { useState } from 'react';
import { Button, Content, Form, Image, Modal } from 'react-bulma-components';
import { DefaultPlayer as Video } from 'react-html5video';

import Api from '../../services/api';

import { Post } from '../../types';

type Props = {
  post: Post;
  onClose: () => void;
};

const PostDetailsModal: React.FC<Props> = function PostDetailsModal({
  post,
  onClose,
}) {
  const {
    id,
    title,
    description,
    photoUrl,
    videoUrl,
    thumbJpgUrls,
    videoMp4Urls,
    order: orderExisting,
    availableNumberOfPrints,
    endedAtFormatted,
    isAvailable,
    isFeatured: isFeaturedExisting,
  } = post || {};

  const [featured, setFeatured] = useState(isFeaturedExisting);
  const [featuring, setFeaturing] = useState(false);
  const [order, setOrder] = useState(orderExisting || 0);
  const [reload, setReload] = useState(false);

  const onOrderChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setOrder(parseInt(value, 10));
  };

  const updateFeaturing = async (isFeatured: boolean) => {
    setFeaturing(true);
    let isFeaturedUpdated = isFeatured;
    let reloaded = true;
    try {
      await Api.updatePost(id, { isFeatured, order });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('updateFeaturing ERR', err);
      isFeaturedUpdated = !isFeatured;
      reloaded = false;
    }
    setFeatured(isFeaturedUpdated);
    setFeaturing(false);
    setReload(reloaded);
  };

  return (
    <Modal
      closeOnBlur
      show
      onClose={() => {
        onClose();
        if (reload) {
          window.location.reload();
        }
      }}
    >
      <Modal.Card>
        <Modal.Card.Header>
          <Modal.Card.Title>{title}</Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <div>
            {videoUrl ? (
              <Video
                loop
                muted
                poster={
                  (thumbJpgUrls &&
                    (thumbJpgUrls.lowRes || thumbJpgUrls.highRes)) ||
                  photoUrl
                }
                preload="none"
              >
                <source
                  src={
                    (videoMp4Urls &&
                      (videoMp4Urls.lowRes || videoMp4Urls.highRes)) ||
                    videoUrl
                  }
                />
              </Video>
            ) : (
              <Image
                src={
                  (thumbJpgUrls &&
                    (thumbJpgUrls.lowRes || thumbJpgUrls.highRes)) ||
                  photoUrl ||
                  ''
                }
              />
            )}
          </div>
          <Content>
            <div style={{ textAlign: 'center' }}>
              <b>
                {/* eslint-disable-next-line no-nested-ternary */}
                {isAvailable
                  ? endedAtFormatted
                    ? `Available until ${endedAtFormatted}`
                    : `${availableNumberOfPrints} left`
                  : endedAtFormatted
                  ? `Ended at ${endedAtFormatted}`
                  : ''}
              </b>
            </div>
          </Content>
          <Content>
            <div style={{ textAlign: 'center' }}>
              <i>{description}</i>
            </div>
          </Content>
        </Modal.Card.Body>
        <Modal.Card.Footer>
          <Form.Field className="has-addons">
            <Form.Control>
              <Form.Input
                placeholder="Order"
                onChange={onOrderChange}
                type="number"
                value={order}
              />
            </Form.Control>
            <Form.Control>
              <Button
                loading={featuring}
                onClick={() => updateFeaturing(featured)}
              >
                Change Order
              </Button>
            </Form.Control>
            <Form.Control>
              <Button
                color="warning"
                loading={featuring}
                onClick={() => updateFeaturing(!featured)}
              >
                {featured ? 'Remove from Featured' : 'Set as Featured'}
              </Button>
            </Form.Control>
          </Form.Field>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  );
};

export default PostDetailsModal;
