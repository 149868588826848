import { PaymentMethod, PaymentTransfer } from './payment';

export type After = {
  after?: string;
};

export type AfterWithFeatured = After & { featured?: boolean | null };

export type AfterWithCuratedAndFeatured = AfterWithFeatured & {
  curated?: boolean;
  order?: string;
  limit?: number;
};

export type AfterWithFeaturedAndPath = AfterWithCuratedAndFeatured & {
  path: string;
};

export type ListFunc<T> = ({ after }: After) => Promise<T[]>;

export const emptyListFunc: ListFunc<any> = async () => [];

export enum UserSearchField {
  ID = 'userId',
  Email = 'emailAddress',
  Wallet = 'ethAddress',
  Phone = 'phoneNumber',
}

export enum SearchType {
  USER = 'USER',
}

export type UserSearchQuery = {
  [x in UserSearchField]?: string;
} & {
  type: SearchType;
  after?: string;
  limit?: number;
  selects?: string[];
};

export const emptyUserSearchQuery: UserSearchQuery = {
  type: SearchType.USER,
  userId: '',
  selects: [
    'id',
    'emailAddress',
    'emailAddressAlt',
    'ethAddress',
    'ethAddressAlt',
    'phoneNumber',
    'registration.isLocked',
  ],
};

export interface SearchResult<T> {
  type: SearchType;
  object: T;
}

export interface ChargeRequest {
  paymentMethod: Partial<PaymentMethod>;
  paymentTransfer: Partial<PaymentTransfer>;
  quarterNumber?: number;
  dryRun?: boolean;
}

export const emptyChargeRequest: ChargeRequest = {
  paymentMethod: {
    metadata: { email: '', percentage: 0 },
    paymentProfile: { stripeCustomerId: '' },
  },
  paymentTransfer: {
    revenueDetails: {
      creatorId: '',
      startDate: '',
      endDate: '',
      totalRevenue: 0,
    },
  },
  quarterNumber: 1,
  dryRun: true,
};

export interface ChargeResult {
  paymentTransfer: PaymentTransfer;
  paymentMethod?: PaymentMethod;
  period?: {
    startedAt: string;
    endedAt: string;
    // numberOfYears?: number;
  };
}

export interface PayoutRequest {
  creatorId: string;
  endDate: string;
  startDate: string;
  totalRevenue: number;
  dryRun?: boolean;
  // hzs?: boolean;
}

export const emptyPayoutRequest: PayoutRequest = {
  creatorId: '',
  endDate: '',
  startDate: '',
  totalRevenue: 0,
  dryRun: true,
  // hzs: true,
};

export interface PayoutResult {
  revenuePayoutTransfer: PaymentTransfer;
  revenueShareTransfers: PaymentTransfer[];
}

export interface PresignRequest {
  type: string;
  id: string;
}

export interface UploadRequest extends PresignRequest {
  directory?: string;
  subDirectory?: string;
  file: File;
}

export type UploadResult = Promise<{ location?: string; status: number }>;
