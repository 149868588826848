import React from 'react';
import { Progress, Table } from 'react-bulma-components';

import { Profile } from '../../types';

import ProfileRow from './row';

type Props = {
  isLoading?: boolean;
  list: Profile[];
};

const ProfilesTable: React.FC<Props> = function ProfilesTable({
  isLoading,
  list,
}) {
  return (
    <Table hoverable className="is-fullwidth">
      <thead>
        <tr>
          <th>ID</th>
          <th colSpan={2}>UserName</th>
        </tr>
      </thead>
      <tbody>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <tr>
            <th colSpan={6}>
              <Progress max={100} color="info" />
            </th>
          </tr>
        ) : list && list.length ? (
          list.map((profile) => (
            <ProfileRow key={profile.id} profile={profile} />
          ))
        ) : (
          <tr>
            <th colSpan={6}>
              <i>There is no creators found.</i>
            </th>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

ProfilesTable.defaultProps = { isLoading: false };

export default ProfilesTable;
