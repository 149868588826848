import React, { useState, useEffect } from 'react';
import { Container, Progress } from 'react-bulma-components';
import { Link, useParams } from 'react-router-dom';

import { PROFILES, ROOT } from '../../constants/routes';
import Api from '../../services/api';
import { emptyProfile as empty, Post, Profile } from '../../types';

import Navigation from '../Navigation';
import { PostsCollection } from '../Post';

import ProfileHeader from './header';

type Params = { userName: string };

const ProfileDetailsPage: React.FC = function ProfileDetailsPage() {
  const { userName } = useParams<Params>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile>(empty);
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    const fetchAndSetData = async () => {
      const [profile0, posts1] = await Promise.all([
        Api.getProfile({ userName }),
        Api.listProfilePosts({ userName }),
      ]);

      if (mounted) {
        setProfile(profile0);
        setPosts(posts1);
        setLoading(false);
      }
    };

    fetchAndSetData().catch((error) =>
      // eslint-disable-next-line no-console
      console.error('fetchAndSetData ERR', error)
    );

    return () => {
      mounted = false;
    };
  }, [userName]);

  return (
    <>
      <Navigation />
      <Container>
        <br />
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li>
              <Link to={PROFILES.to}>{PROFILES.title}</Link>
            </li>
            <li className="is-active">
              <Link to={`${PROFILES.to}/${userName}`}>{userName}</Link>
            </li>
            <li>
              <Link to={`${PROFILES.to}/${userName}/edit`}>Edit</Link>
            </li>
          </ul>
        </nav>
        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <>
            <ProfileHeader profile={profile} setProfile={setProfile} />
            <h2 className="is-size-3">Posts</h2>
            {posts ? <PostsCollection posts={posts} showProfile={false} /> : ''}
          </>
        )}
        <br />
      </Container>
    </>
  );
};

export default ProfileDetailsPage;
