import React, { useState } from 'react';
import {
  Button,
  Card,
  Columns,
  Image,
  Notification,
} from 'react-bulma-components';

import Api from '../../services/api';
import { Profile } from '../../types';

interface Props {
  profile: Profile;
  setProfile: React.Dispatch<React.SetStateAction<Profile>>;
}

const ProfileHeader: React.FC<Props> = function ProfileHeader({
  profile,
  setProfile,
}: Props) {
  const {
    id: userId,
    avatarUrl,
    description,
    fullName,
    userName,
    isCurated,
  } = profile;

  const [notification, setNotification] = useState<{
    message: string;
    color: 'success' | 'danger';
  } | null>(null);
  const [balance, setBalance] = useState<number>(0.005);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleCurated = async () => {
    setIsLoading(true);

    if (
      // eslint-disable-next-line no-alert
      !window.confirm(
        isCurated
          ? `You are about to remove ${userName} from curated profile list.`
          : `You are about to add ${userName} as a curated profile.`
      )
    ) {
      setIsLoading(false);
      return;
    }

    try {
      if (isCurated) {
        await Api.removeFromCuration({ userId });
      } else {
        await Api.addToCuration({ userId });
      }

      setNotification({
        message: `${userName} successfully ${
          isCurated ? 'removed from' : 'added to'
        } curated list`,
        color: 'success',
      });
      setProfile((prevProfile) => ({
        ...prevProfile,
        isCurated: !prevProfile.isCurated,
      }));
    } catch (error: any) {
      // console.error('addToCuration ERR', error);
      setNotification({ message: error.message, color: 'danger' });
    }
    setIsLoading(false);
  };

  const addFlowBalance = async () => {
    setIsLoading(true);

    if (
      // eslint-disable-next-line no-alert
      !window.confirm(
        `You are about to add ${balance} Flow tokens to ${userName} account.`
      )
    ) {
      setIsLoading(false);
      return;
    }

    try {
      await Api.addFlowBalance({ balance: String(balance), userId });

      setNotification({
        message: `${balance} Flow Tokens deposit transaction created successfully`,
        color: 'success',
      });
    } catch (error: any) {
      // console.error('addToCuration ERR', error);
      setNotification({ message: error.message, color: 'danger' });
    }
    setIsLoading(false);
  };

  return (
    <Columns>
      {notification && (
        <Columns.Column size={11}>
          <Notification color={notification.color} colorVariant="light">
            <button
              aria-label="close"
              className="delete"
              onClick={() => setNotification(null)}
              type="button"
            />
            {notification.message}
          </Notification>
        </Columns.Column>
      )}
      <Columns.Column size={9}>
        <Card style={{ padding: '30px' }}>
          <Columns>
            <Columns.Column size={3}>
              <Image size={128} src={avatarUrl || ''} />
            </Columns.Column>
            <Columns.Column size={4}>
              <div>
                <h1 className="is-size-4">{fullName}</h1>
                <div className="is-link" style={{ cursor: 'pointer' }}>
                  {userName}
                </div>
              </div>
              <b>Description</b>
              <div>{description}</div>
            </Columns.Column>
            <Columns.Column size={5}>
              <Columns>
                <Columns.Column size={7}>
                  <Button
                    disabled={isLoading}
                    outlined
                    color={isCurated ? 'danger' : 'success'}
                    onClick={toggleCurated}
                  >
                    {isCurated ? 'Remove from Curation' : 'Add to Curation'}
                  </Button>
                </Columns.Column>
                <Columns.Column size={7}>
                  <Columns paddingless>
                    <Columns.Column paddingless size={6}>
                      <input
                        className="input is-info"
                        value={balance}
                        onChange={(event) =>
                          setBalance(Number(event.target.value))
                        }
                        type="number"
                        step={0.001}
                        min={0.001}
                        disabled={isLoading}
                      />
                    </Columns.Column>
                    <Columns.Column paddingless size={6}>
                      <Button
                        disabled={isLoading}
                        outlined
                        color="success"
                        onClick={addFlowBalance}
                      >
                        Add Flow balance
                      </Button>
                    </Columns.Column>
                  </Columns>
                </Columns.Column>
              </Columns>
            </Columns.Column>
          </Columns>
        </Card>
      </Columns.Column>
    </Columns>
  );
};

export default ProfileHeader;
