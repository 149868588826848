export interface Profile {
  id: string;
  avatarUrl?: string;
  bannerUrl?: string;
  coverUrl?: string;
  featureUrl?: string;
  description?: string;
  fullName?: string;
  header?: string;
  quote?: string;
  userName: string;
  isCurated?: boolean;
  isYouTuber?: boolean;
  additionals?: Additional[];
  additionalsRaw?: AdditionalsRaw;
  socials?: Socials;
  statistics?: { youtube: YouTubeNumbers };
  tags?: Tag[];
  tagsRaw?: TagsRaw;
  numbers?: YouTubeNumbersFlattened;
}

export type UserProfile = Profile & {
  emailAddress?: string;
  emailAddressAlt?: string;
  ethAddress?: string;
  ethAddressAlt?: string;
};

export enum Group {
  BOTTOM = 'BOTTOM',
  TOP = 'TOP',
}

export interface Additional {
  key: string;
  value: string;
  index?: number;
  link?: string;
  featured?: boolean;
  group?: Group;
}

export interface AdditionalsRaw {
  bottoms?: string;
  tops?: string;
}

export const emptyNumbers: YouTubeNumbersFlattened = {
  pastSemesterEarnings: 0,
  pastYearEarnings: 0,
  yearlyEstimateEarnings: 0,
  subscribersCount: 0,
  pastSemesterViewsCount: 0,
  pastYearViewsCount: 0,
  totalViewsCount: 0,
  yearlyEstimateHighViewsCount: 0,
  yearlyEstimateLowViewsCount: 0,
  pastYearWatchHours: 0,
  youtubeStartedDate: '',
};

export const emptySocials: Socials = {
  discord: { serverUrl: '' },
  instagram: { pageUrl: '' },
  tiktok: { profileUrl: '' },
  twitter: { handleUrl: '' },
  website: { homePageUrl: '' },
  youtube: { channelUrl: '', channelUrlAlt: '', videoId: '' },
};

export const empty: Profile = { id: '', userName: '' };

export interface Socials {
  discord?: { serverUrl: string };
  instagram?: { pageUrl: string };
  tiktok?: { profileUrl: string };
  twitter?: { handleUrl: string };
  website?: { homePageUrl: string };
  youtube?: YouTube;
}

export interface Tag {
  id: string;
  title: string;
  subType?: string;
  iconUrl?: string;
}

export interface TagsRaw {
  category?: string;
  stage?: string;
}

export interface YouTube {
  channelUrl?: string;
  channelUrlAlt?: string;
  videoId?: string;
}

export interface YouTubeNumbers {
  createdAt?: Date;
  earningsAmount?: {
    pastSemester?: number;
    pastYear?: number;
    yearlyEstimate?: number;
  };
  subscribersCount?: { total?: number };
  // videosCount?: { total?: number };
  viewsCount?: {
    pastSemester?: number;
    pastYear?: number;
    total?: number;
    yearlyEstimateHigh?: number;
    yearlyEstimateLow?: number;
  };
  watchHours?: { pastYear?: number };
}

export interface YouTubeNumbersFlattened {
  pastSemesterEarnings?: number;
  pastYearEarnings?: number;
  yearlyEstimateEarnings?: number;
  subscribersCount?: number;
  pastSemesterViewsCount?: number;
  pastYearViewsCount?: number;
  totalViewsCount?: number;
  yearlyEstimateHighViewsCount?: number;
  yearlyEstimateLowViewsCount?: number;
  pastYearWatchHours?: number;
  youtubeStartedDate?: string;
}
