import React from 'react';
import { Table } from 'react-bulma-components';

import { PayoutResult } from '../../types/api';

import ShareTransferRow from './row';

type Props = {
  result?: PayoutResult;
};

const PayoutTable: React.FC<Props> = function PayoutTable({ result }) {
  const { revenueShareTransfers: list } = result || {};
  const {
    amount: total,
    collectors,
    emails,
  }: { amount: number; collectors: number; emails: number } = (
    list || []
  ).reduce(
    (map, { amount, revenueDetails }) => {
      const { collector } = revenueDetails || {};
      const { emailAddress, emailAddressAlt } = collector || {};
      const email = emailAddress || emailAddressAlt;
      return {
        ...map,
        amount: map.amount + (amount || 0),
        emails: map.emails + (email ? 1 : 0),
      };
    },
    { amount: 0, collectors: list?.length || 0, emails: 0 }
  );

  return (
    <Table hoverable className="is-fullwidth">
      <thead>
        <tr>
          <th>UserName - ID</th>
          <th>Email</th>
          <th style={{ textAlign: 'right' }}>Amount</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {list && list.length ? (
          list.map((shareTransfer) => (
            <ShareTransferRow
              key={shareTransfer.id}
              shareTransfer={shareTransfer}
            />
          ))
        ) : (
          <tr>
            <td colSpan={3}>
              <i>There is no payouts/collectors found.</i>
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <th>Collectors: {collectors}</th>
          <th>Emails: {emails}</th>
          <th style={{ textAlign: 'right' }}>Total: ${total / 100}</th>
          <th> </th>
        </tr>
      </tfoot>
    </Table>
  );
};

PayoutTable.defaultProps = { result: undefined };

export default PayoutTable;
