import React, { useEffect, useState } from 'react';

import { CREATORS, ROOT } from '../../constants/routes';
import { useNotification } from '../../contexts/notification';
import Api from '../../services/api';
import { Profile } from '../../types';
import Layout, { Path } from '../Layout';
import ProfilesTable from './table';

const CreatorsListPage: React.FC = function CreatorsListPage(): JSX.Element {
  const breadcrumbs: Path[] = [
    { route: ROOT },
    { route: CREATORS, active: true },
  ];
  const { setNotification } = useNotification();
  const [list, setList] = useState<Profile[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      return () => {};
    }
    let mounted = true;
    const loadData = async () => {
      const profiles = await Api.listProfiles({
        limit: 10_000,
        order: 'maticContractAddress',
      });
      if (mounted) {
        setLoading(false);
        setList(profiles);
      }
    };

    loadData().catch((err) => {
      const { data } = err.response || {};
      const { message } = data?.status || err;
      setNotification(message);
      setLoading(false);
    });

    return () => {
      mounted = false;
    };
  }, [isLoading]);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <ProfilesTable list={list} isLoading={isLoading} />
    </Layout>
  );
};

export default CreatorsListPage;
