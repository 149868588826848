import React from 'react';
import { Button, Progress, Table } from 'react-bulma-components';

import { useNotification } from '../../contexts/notification';
import Api from '../../services/api';

import UserRow from './row';
import { User } from './types';

type Props = {
  hasMore?: boolean;
  isLoadingMore?: boolean;
  list: User[];
  onLoadMore: () => void;
  onUserUpdated: (u: User) => void;
};

const UsersTable: React.FC<Props> = function UsersTable({
  hasMore,
  isLoadingMore,
  list,
  onLoadMore,
  onUserUpdated,
}) {
  const { setNotification } = useNotification();

  const onLockUnlock = async ({
    id,
    registration,
  }: Pick<User, 'id' | 'registration'>) => {
    const { isLocked } = registration || {};
    const isLockTitle = isLocked === true ? 'unlock' : 'lock';
    if (
      // eslint-disable-next-line no-alert
      window.confirm(`You are about to ${isLockTitle} a user: ${id}.`)
    ) {
      try {
        const updated = await Api.lockUnlock({
          userId: id,
          isLocked: !isLocked,
        });
        onUserUpdated(updated);
      } catch (err: any) {
        setNotification(err.message);
      }
    }
  };

  return (
    <Table hoverable className="is-fullwidth">
      <thead>
        <tr>
          <th>ID</th>
          <th>Email</th>
          <th>Wallet</th>
          <th>Phone</th>
          <th>Locked?</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {list && list.length > 0
          ? list.map((users) => (
              <UserRow
                key={users.id}
                user={users}
                onLockUnlock={() => onLockUnlock(users)}
              />
            ))
          : !isLoadingMore && (
              <tr>
                <th colSpan={6}>
                  <i>There is no users found.</i>
                </th>
              </tr>
            )}
        {hasMore &&
          (isLoadingMore ? (
            <tr>
              <th colSpan={6}>
                <Progress max={100} color="info" />
              </th>
            </tr>
          ) : (
            <tr>
              <th colSpan={6}>
                <Button
                  text
                  inverted
                  fullwidth
                  color="info"
                  className="is-ghost"
                  onClick={() => onLoadMore()}
                >
                  <i>load more</i>
                </Button>
              </th>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

UsersTable.defaultProps = {
  hasMore: false,
  isLoadingMore: false,
};

export default UsersTable;
