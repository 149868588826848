import React from 'react';
import { Button, Image } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import { Discover } from './types';
import { DISCOVERS } from '../../constants/routes';

type Props = {
  discover: Discover;
  onDelete: (a: string) => void;
};

const DiscoverRow = function DiscoverRow({
  discover: { order, id, title, subtitle, story },
  onDelete,
}: Props) {
  const to = `${DISCOVERS.to}/${id}`;
  return (
    <tr>
      <th>{order}</th>
      <th style={{ whiteSpace: 'nowrap' }}>
        <Link to={to}>{id}</Link>
      </th>
      <td style={{ whiteSpace: 'nowrap' }}>{title}</td>
      <td>
        <Image size="3by2" src={(story && story.thumbImageUrl) || ''} />
      </td>
      <td>{subtitle}</td>
      <td style={{ whiteSpace: 'nowrap' }}>
        <Link to={to}>
          <Button outlined color="warning">
            Edit
          </Button>
        </Link>{' '}
        <Button outlined color="danger" onClick={() => onDelete(id)}>
          Delete
        </Button>
      </td>
    </tr>
  );
};

export default DiscoverRow;
