import { useLocation } from 'react-router-dom';

function useCurated(param: string = 'curated') {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const curated = query.get(param);
  const isCuratedOrNull =
    curated !== null ? curated.trim().toLowerCase() : null;
  let isCurated =
    isCuratedOrNull !== null && isCuratedOrNull === 'false' ? false : null;
  isCurated =
    isCurated !== false &&
    (isCuratedOrNull === '' || isCuratedOrNull === 'true')
      ? true
      : isCurated;

  return isCurated;
}

export default useCurated;
