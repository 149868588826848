import React from 'react';
import { Box, Image, Content, Button, Columns } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { PROFILES } from '../../constants/routes';
import { useCurated } from '../../hooks';
import { Profile } from '../../types';

interface Props {
  onAction: () => Promise<void>;
  profile: Profile;
}

const ProfileCard: React.FC<Props> = function ProfileCard(props: Props) {
  const curated = useCurated();
  const updateType =
    // eslint-disable-next-line no-nested-ternary
    curated === null ? '' : curated === true ? 'remove' : 'add';
  let buttonTitle = '';
  let buttonColor: 'danger' | 'primary' = 'primary';
  if (updateType === 'remove') {
    buttonTitle = 'Remove from Curation';
    buttonColor = 'danger';
  } else if (updateType === 'add') {
    buttonTitle = 'Add to Curation';
    buttonColor = 'primary';
  }

  const { onAction, profile } = props;
  const { avatarUrl, fullName, userName } = profile;

  return (
    <Columns.Column size={3} className={classNames('is-flex-direction-row')}>
      <Box
        style={{
          width: '75%',
          display: 'flex',
          justifyContent: 'center',
          flexFlow: 'column',
        }}
      >
        <Content alignContent="center">
          <Image size={128} src={avatarUrl || ''} className="mr-3" />
          <h4 className="has-text-centered">{fullName}</h4>
          <Link className="has-text-centered" to={`${PROFILES.to}/${userName}`}>
            {userName}
          </Link>
        </Content>
        {curated === null ? null : (
          <Content style={{ display: 'flex', justifyContent: 'center' }}>
            <Button outlined color={buttonColor} onClick={() => onAction()}>
              {buttonTitle}
            </Button>
          </Content>
        )}
      </Box>
    </Columns.Column>
  );
};

export default ProfileCard;
