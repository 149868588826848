const config = {
  aws: {
    cloudfront: {
      baseUrls: {
        assets: 'https://d2st0fnq3grac2.cloudfront.net',
      },
    },
    s3: {
      directories: {
        featured: 'discover-images',
        story: 'discover-files',
        style: 'select-style-images',
      },
    },
  },
  firebase: {
    apiKey:
      process.env.REACT_APP_FIREBASE_API_KEY ||
      'AIzaSyCONbk08dMJO576_XJBE4cmNvmHxGeTdzE',
    authDomain:
      process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
      'strucc-478d0.firebaseapp.com',
    databaseURL:
      process.env.REACT_APP_FIREBASE_DATABASE_URL ||
      'https://strucc-478d0.firebaseio.com',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'strucc-478d0',
    storageBucket:
      process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
      'strucc-478d0.appspot.com',
    messagingSenderId:
      process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '927308035267',
    appId:
      process.env.REACT_APP_FIREBASE_APP_ID ||
      '1:927308035267:web:85dd641ab5a600ff849e76',
    measurementId:
      process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-JXLSR28H2N',
  },
  googleWebClientId:
    process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID ||
    '13621704771-o851aqs3eto1pmurj0j17b2fg3m7ghj4.apps.googleusercontent.com',
  namespace: process.env.REACT_APP_NAMESPACE || 'everbloom:dev:admin',
};

export default config;

export type Config = typeof config;
export type AwsConfig = typeof config.aws;
export type FirebaseConfig = typeof config.firebase;
