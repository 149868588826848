import React, {
  ChangeEvent,
  ChangeEventHandler,
  ReactElement,
  useState,
} from 'react';
import { Button, Columns, Form } from 'react-bulma-components';

type Props = {
  id?: string;
  help?: string | ReactElement<any, string>;
  info?: string | ReactElement<any, string>;
  placeholder?: string;
  value?: number;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const NumberInputColumn = function PositiveNumberInput({
  id,
  help,
  info,
  placeholder,
  onChange,
  value,
}: Props) {
  const [error, setError] = useState<string | undefined>();
  let description: string | ReactElement<any, string> | undefined;
  if (error || help) {
    description = error ? <i>{error}</i> : help;
  }
  const message = description && (
    <Form.Help color={error ? 'danger' : 'info'}>{description}</Form.Help>
  );
  const onInnerChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { valueAsNumber } = event.currentTarget;
    if (!valueAsNumber || valueAsNumber <= 0) {
      setError('Must be a positive number');
    } else {
      setError(undefined);
    }
    onChange(event);
  };
  return (
    <Columns.Column>
      <Form.Field kind="addons">
        {info && (
          <Form.Control>
            <Button isStatic>{info}</Button>
          </Form.Control>
        )}
        <Form.Control fullwidth>
          <Form.Input
            id={id}
            type="number"
            color={error ? 'danger' : undefined}
            placeholder={placeholder}
            value={value}
            onChange={onInnerChange}
          />
        </Form.Control>
      </Form.Field>
      {message}
    </Columns.Column>
  );
};

NumberInputColumn.defaultProps = {
  help: undefined,
  id: undefined,
  info: undefined,
  placeholder: undefined,
  value: undefined,
};

export default NumberInputColumn;
