import { User } from '../components/User/types';
import { LIMIT } from '../constants/api';
import {
  CURATIONS_API,
  FEED_API,
  POSTS_API,
  PROFILES_API,
  CHARGE_PAYOUT_API,
  REVENUE_SHARE_API,
  SEARCH_API,
  USERS_API,
  PAYMENT_TRANSFERS_API,
} from '../constants/routes';
import {
  After,
  AfterWithCuratedAndFeatured,
  AfterWithFeatured,
  AfterWithFeaturedAndPath,
  Post,
  Profile,
} from '../types';
import {
  ChargeRequest,
  ChargeResult,
  PayoutRequest,
  PayoutResult,
  SearchResult,
  UserSearchQuery,
} from '../types/api';
import { PaymentTransfer } from '../types/payment';

import Http from './http';

function processPostTimestamps(post: Post): Post {
  const { endedAt, maximumNumberOfPrints, printsCount, ...rest } = post;
  const availableNumberOfPrints = maximumNumberOfPrints
    ? maximumNumberOfPrints - printsCount
    : undefined;
  const endedAtUnix = endedAt ? Date.parse(endedAt) : null;
  const now = Date.now();
  const isAvailable =
    (!!endedAtUnix && endedAtUnix > now) || !!availableNumberOfPrints;
  const endedDate = endedAtUnix ? new Date(endedAtUnix) : undefined;
  const endedAtFormatted =
    endedAt &&
    `${Intl.DateTimeFormat('en', {
      month: 'short',
      day: 'numeric',
    }).format(endedDate)}, ${Intl.DateTimeFormat('en', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(endedDate)}`;
  return {
    ...rest,
    maximumNumberOfPrints,
    printsCount,
    availableNumberOfPrints,
    isAvailable,
    endedDate,
    endedAtFormatted,
    endedAt,
  };
}

class ApiService {
  async listFeedItems({ after }: After) {
    const data = await this.list<{ posts: Post[] }>({
      after,
      path: FEED_API.to,
    });
    const posts: Post[] =
      data && data.posts && data.posts.map(processPostTimestamps);

    return posts;
  }

  async listPosts({ after, featured }: AfterWithFeatured) {
    const data = await this.list<{ posts: Post[] }>({
      after,
      featured,
      path: POSTS_API.to,
    });
    const posts: Post[] =
      data && data.posts && data.posts.map(processPostTimestamps);

    return posts;
  }

  async listProfilePosts({ userName }: { userName: string }) {
    const data = await this.list<{ posts: Post[] }>({
      path: `${PROFILES_API.to}/${userName}/posts`,
    });
    const posts: Post[] =
      data && data.posts && data.posts.map(processPostTimestamps);

    return posts;
  }

  async listProfiles({
    after,
    curated,
    featured,
    order,
    limit,
  }: AfterWithCuratedAndFeatured) {
    const data = await this.list<{ profiles: Profile[] }>({
      after,
      curated,
      featured,
      order,
      limit,
      path: PROFILES_API.to,
    });

    return data?.profiles;
  }

  // eslint-disable-next-line class-methods-use-this
  async list<T>({
    after,
    curated,
    featured,
    order,
    limit,
    path,
  }: AfterWithFeaturedAndPath): Promise<T> {
    const params = { limit: limit || LIMIT };
    if (after) {
      Object.assign(params, { after });
    }
    if (curated) {
      Object.assign(params, { curated });
    }
    if (order) {
      Object.assign(params, { order });
    }
    if (featured) {
      Object.assign(params, { featured });
    }

    const { data } = await Http.get(path, { params });

    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  async search(query: UserSearchQuery): Promise<User[]> {
    const { data } = await Http.post(SEARCH_API.to, { query });
    const results: SearchResult<User>[] = data && data.results;

    return results && results.map(({ object }) => object);
  }

  // eslint-disable-next-line class-methods-use-this
  async updatePost(id: string, updates: Partial<Post>): Promise<Post> {
    const { data } = await Http.put(`${POSTS_API.to}/${id}`, { post: updates });

    return data && data.post;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCharge(request: ChargeRequest): Promise<ChargeResult> {
    const { paymentTransfer } = request;
    const { revenueDetails: revenue } = paymentTransfer;
    const { totalRevenue: total } = revenue || {};
    const { data } = await Http.post<ChargeResult>(CHARGE_PAYOUT_API.to, {
      ...request,
      paymentTransfer: {
        ...paymentTransfer,
        revenueDetails: {
          ...revenue,
          totalRevenue: total && total * 100,
        },
      },
    });
    const { paymentTransfer: pt } = data;
    const charge = this.normalizePaymentTransfer(pt);
    return { ...data, paymentTransfer: charge };
  }

  // eslint-disable-next-line class-methods-use-this
  async getPaymentTransfer({ id }: { id: string }): Promise<PaymentTransfer> {
    const { data } = await Http.get<{ paymentTransfer: PaymentTransfer }>(
      `${PAYMENT_TRANSFERS_API.to}/${id}`
    );
    const { paymentTransfer: pt } = data;
    return this.normalizePaymentTransfer(pt);
  }

  // eslint-disable-next-line class-methods-use-this
  async updatePaymentTransfer(
    paymentTransfer: Partial<PaymentTransfer>
  ): Promise<ChargeResult> {
    const { id, revenueDetails: revenue } = paymentTransfer;
    const { totalRevenue: total } = revenue || {};
    const { data } = await Http.put<ChargeResult>(
      `${PAYMENT_TRANSFERS_API.to}/${id}`,
      {
        paymentTransfer: {
          ...paymentTransfer,
          revenueDetails: {
            ...revenue,
            totalRevenue: total && total * 100,
          },
        },
      }
    );
    const { paymentTransfer: pt } = data;
    return { ...data, paymentTransfer: this.normalizePaymentTransfer(pt) };
  }

  // eslint-disable-next-line class-methods-use-this
  normalizePaymentTransfer(pt: PaymentTransfer): PaymentTransfer {
    const { amount, revenueDetails } = pt;
    const {
      endDate: end,
      startDate: start,
      totalRevenue,
    } = revenueDetails || {};
    const [endDate] = end
      ? new Date(Date.parse(end)).toISOString().split('T')
      : [];
    const [startDate] = start
      ? new Date(Date.parse(start)).toISOString().split('T')
      : [];
    return {
      ...pt,
      amount: (amount || 0) / 100,
      revenueDetails: {
        ...revenueDetails,
        startDate,
        endDate,
        totalRevenue: (totalRevenue || 0) / 100,
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  async createPayout(payout: PayoutRequest): Promise<PayoutResult> {
    const { totalRevenue } = payout;
    const { data } = await Http.post(REVENUE_SHARE_API.to, {
      ...payout,
      totalRevenue: totalRevenue * 100,
    });
    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createProfile(profile: Profile): Promise<Profile> {
    const { data } = await Http.post(PROFILES_API.to, { profile });

    return data && data.profile;
  }

  // eslint-disable-next-line class-methods-use-this
  async getProfile({ userName }: { userName: string }): Promise<Profile> {
    const { data } = await Http.get(`${PROFILES_API.to}/${userName}`);

    return data && data.profile;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateProfile(id: string, updates: Partial<Profile>): Promise<Profile> {
    const { data } = await Http.put(`${USERS_API.to}/${id}`, {
      profile: updates,
    });

    return data && data.profile;
  }

  // eslint-disable-next-line class-methods-use-this
  async lockUnlock({
    isLocked,
    userId,
  }: {
    isLocked: boolean;
    userId: string;
  }): Promise<User> {
    const { data } = await Http.put(`${USERS_API.to}/${userId}`, {
      user: { registration: { isLocked } },
    });

    return data?.user;
  }

  // eslint-disable-next-line class-methods-use-this
  async addFlowBalance({
    balance,
    userId,
  }: {
    balance: string;
    userId: string;
  }) {
    return Http.put(`${USERS_API.to}/${userId}/flow`, {
      flow: {
        balance: {
          increment: String(balance),
        },
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async addToCuration({ userId }: { userId: string }) {
    return Http.post(CURATIONS_API.to, { curation: { userId } });
  }

  // eslint-disable-next-line class-methods-use-this
  async removeFromCuration({ userId }: { userId: string }) {
    return Http.delete(`${CURATIONS_API.to}/${userId}`);
  }
}

export default new ApiService();
