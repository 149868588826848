import React, { useState } from 'react';
import { Notification, Table } from 'react-bulma-components';
import { useFirebase } from '../Firebase';
import StyleRow from './row';
import { Style } from './types';

type Props = {
  styles: Style[];
};

const StylesTable = function StylesTable({ styles }: Props) {
  const firebase = useFirebase()!;
  const [notificationMessage, setNotificationMessage] = useState<string | null>(
    null
  );

  const deleteStyle = async (id: string) => {
    const ref = firebase.db.collection('selectStyles').doc(id);
    await ref.delete();
  };

  const onDelete = async (id: string) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`You are about to delete a Style: ${id}.`)) {
      try {
        await deleteStyle(id);
        window.location.reload();
      } catch (error: any) {
        // console.log('onDelete ERR', error);
        setNotificationMessage(error.message);
      }
    }
  };

  return (
    <>
      {notificationMessage && (
        <Notification color="danger" colorVariant="light">
          <button
            aria-label="close"
            className="delete"
            onClick={() => setNotificationMessage(null)}
            type="button"
          />
          {notificationMessage}
        </Notification>
      )}
      <Table className="is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>
              <abbr title="Order">#</abbr>
            </th>
            <th>ID</th>
            <th>Name</th>
            <th>Thumbnail</th>
            <th>Variation</th>
            <th>Tier</th>
            <th>Published?</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {styles
            .sort((a, b) => b.order - a.order)
            .map((style) => (
              <StyleRow key={style.id} style={style} onDelete={onDelete} />
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default StylesTable;
