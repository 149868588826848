export interface Route {
  title?: string;
  to: string;
}

export const ROOT: Route = {
  title: 'Admin',
  to: '/',
};
export const LOGIN: Route = { title: 'Login', to: '/login' };
export const DISCOVERS: Route = { title: 'Discover Cards', to: '/discovers' };
export const DISCOVER_EDIT: Route = {
  title: 'Edit Discover Card',
  to: `${DISCOVERS.to}/:id`,
};
export const DISCOVER_NEW: Route = {
  title: 'New Discover Card',
  to: `${DISCOVERS.to}/new`,
};
export const FEATUREDS: Route = { title: 'Featured Cards', to: '/featureds' };
export const FEATURED_EDIT: Route = {
  title: 'Edit Featured Card',
  to: `${FEATUREDS.to}/:id`,
};
export const STORIES: Route = { title: 'Discover Card Videos', to: '/stories' };
export const STORY_EDIT: Route = {
  title: 'Edit Videos Set',
  to: `${STORIES.to}/:id`,
};
export const STORY_NEW: Route = {
  title: 'New Videos Set',
  to: `${STORIES.to}/new`,
};
export const STYLES: Route = { title: 'Styles', to: '/styles' };
export const STYLE_EDIT: Route = {
  title: 'Edit Style',
  to: `${STYLES.to}/:id`,
};
export const STYLE_NEW: Route = {
  title: 'Add a New Style',
  to: `${STYLES.to}/new`,
};
export const API_V1: Route = { to: '/v1' };
export const GOOGLE_AUTH_API: Route = {
  to: `${API_V1.to}/auth/google`,
};
export const REFRESH_AUTH_API: Route = {
  to: `${API_V1.to}/auth/refresh`,
};
export const PRESIGN_API: Route = {
  to: `${API_V1.to}/presign`,
};
export const COLLECTIONS: Route = { title: 'Collections', to: '/collections' };
export const COLLECTION_EDIT: Route = {
  title: 'Edit Collection',
  to: `${COLLECTIONS.to}/:id`,
};
export const COLLECTION_NEW: Route = {
  title: 'New Collection',
  to: `${COLLECTIONS.to}/new`,
};
export const COLLECTIONS_API: Route = {
  to: `${API_V1.to}${COLLECTIONS.to}`,
};
export const COLLECTIONS_ALL_TEMPLATES_API = {
  to: `${COLLECTIONS_API.to}/all?templates=all`,
};
export const TEMPLATES: Route = { title: 'Templates', to: '/templates' };
export const TEMPLATE_EDIT: Route = {
  title: 'Edit Template',
  to: `${TEMPLATES.to}/:id`,
};
export const TEMPLATE_NEW: Route = {
  title: 'New Template',
  to: `${TEMPLATES.to}/new`,
};
export const TEMPLATES_API: Route = {
  to: `${API_V1.to}${TEMPLATES.to}`,
};
export const CREATORS: Route = { title: 'Creators', to: '/creators' };
export const CURATIONS: Route = { title: 'Curations', to: '/curations' };
export const CURATIONS_API: Route = {
  to: `${API_V1.to}${CURATIONS.to}`,
};
export const FEED: Route = { title: 'Feed', to: '/feed' };
export const FEED_API: Route = {
  to: `${API_V1.to}/my-feed`,
};
export const CHARGES: Route = { title: 'Charges', to: '/charges' };
export const CHARGE_EDIT: Route = {
  title: 'Edit Charge',
  to: `${CHARGES.to}/:userName/:paymentTransferId`,
};
export const CHARGE_NEW: Route = {
  title: 'New Charge',
  to: `${CHARGES.to}/:userName/new`,
};
export const PAYOUTS: Route = { title: 'Payouts', to: '/payouts' };
export const PAYOUT_NEW: Route = {
  title: 'New Payout',
  to: `${PAYOUTS.to}/:userName/new`,
};
export const POSTS: Route = { title: 'Posts', to: `/posts` };
export const POSTS_API: Route = {
  to: `${API_V1.to}${POSTS.to}`,
};
export const POSTS_ALL: Route = {
  title: 'All Posts',
  to: `${FEED.to}?curated=false`,
};
export const POSTS_FEATURED: Route = {
  title: 'Featured Drops',
  to: `${POSTS.to}?featured`,
};
export const PROFILES: Route = { title: 'Profiles', to: '/profiles' };
export const PROFILES_API: Route = {
  to: `${API_V1.to}${PROFILES.to}`,
};
export const PROFILES_CURATED: Route = {
  title: 'Curated Profiles',
  to: `${PROFILES.to}?curated`,
};
export const PROFILES_UNCURATED: Route = {
  title: 'Uncurated Profiles',
  to: `${PROFILES.to}?curated=false`,
};
export const PROFILE_DETAILS: Route = {
  title: 'Profile Details',
  to: `${PROFILES.to}/:userName`,
};
export const PROFILE_EDIT: Route = {
  title: 'Edit Profile',
  to: `${PROFILES.to}/:userName/edit`,
};
export const PROFILE_NEW: Route = {
  title: 'Create Profile',
  to: `${PROFILES.to}/new`,
};
export const SEARCH_API: Route = {
  title: 'Search',
  to: `${API_V1.to}/search`,
};
export const USERS: Route = { title: 'Users', to: '/users' };
export const USERS_API: Route = {
  to: `${API_V1.to}${USERS.to}`,
};
export const MUSIC: Route = { title: 'Music', to: '/music' };
export const MUSIC_API: Route = {
  to: `${API_V1.to}${MUSIC.to}`,
};
export const MUSIC_PUBLISHED: Route = {
  title: 'Published Music',
  to: `${MUSIC.to}?published`,
};
export const MUSIC_UNPUBLISHED: Route = {
  title: 'Unpublished Music',
  to: `${MUSIC.to}?published=false`,
};
export const WALLET: Route = {
  title: 'Wallet',
  to: `/wallet`,
};

export const FCL_V1: Route = { to: '/fcl/v1' };
export const PAYMENT_TRANSFERS_API: Route = {
  to: `${FCL_V1.to}/payment-transfers`,
};
export const CHARGE_PAYOUT_API: Route = {
  to: `${FCL_V1.to}/payment-transfers/charge-payout`,
};
export const REVENUE_SHARE_API: Route = {
  to: `${FCL_V1.to}/payment-transfers/revenue-share`,
};

const Routes: { [key: string]: Route } = {
  ROOT,
  LOGIN,
  DISCOVERS,
  DISCOVER_EDIT,
  DISCOVER_NEW,
  FEATUREDS,
  FEATURED_EDIT,
  STORIES,
  STORY_EDIT,
  STORY_NEW,
  STYLES,
  STYLE_EDIT,
  STYLE_NEW,
  API_V1,
  GOOGLE_AUTH_API,
  REFRESH_AUTH_API,
  PRESIGN_API,
  COLLECTIONS,
  COLLECTION_EDIT,
  COLLECTION_NEW,
  COLLECTIONS_API,
  COLLECTIONS_ALL_TEMPLATES_API,
  TEMPLATES,
  TEMPLATE_EDIT,
  TEMPLATE_NEW,
  TEMPLATES_API,
  CREATORS,
  CURATIONS,
  CURATIONS_API,
  FEED,
  FEED_API,
  CHARGES,
  CHARGE_EDIT,
  CHARGE_NEW,
  PAYOUTS,
  PAYOUT_NEW,
  POSTS_API,
  POSTS_ALL,
  PROFILES,
  PROFILES_API,
  PROFILES_CURATED,
  PROFILES_UNCURATED,
  PROFILE_DETAILS,
  PROFILE_EDIT,
  PROFILE_NEW,
  MUSIC,
  MUSIC_API,
  MUSIC_PUBLISHED,
  MUSIC_UNPUBLISHED,
  WALLET,
  FCL_V1,
  CHARGE_PAYOUT_API,
  PAYMENT_TRANSFERS_API,
  REVENUE_SHARE_API,
};

export default Routes;
