import React from 'react';
import { Box, Button, Content, Image } from 'react-bulma-components';
import { DefaultPlayer as Video } from 'react-html5video';
import { Link } from 'react-router-dom';

import { PROFILES } from '../../constants/routes';
import { Post } from '../../types';

interface Props {
  post: Post;
  setSelected: () => void;
  showProfile?: boolean;
}

const PostCard: React.FC<Props> = function PostCard({
  post,
  setSelected,
  showProfile,
}: Props) {
  const {
    profile: { userName, avatarUrl },
    title,
    photoUrl,
    videoUrl,
    thumbJpgUrls,
    videoMp4Urls,
    availableNumberOfPrints,
    endedAtFormatted,
    isAvailable,
  } = post;

  return (
    <Box>
      {showProfile && (
        <Content>
          <div>
            <Link to={`${PROFILES.to}/${userName}`}>
              <div style={{ display: 'flex' }}>
                <Image
                  fullwidth={false}
                  rounded
                  size="16"
                  src={avatarUrl || ''}
                  style={{
                    margin: '10px 10px 0 0',
                    overflow: 'hidden',
                    width: '16%',
                  }}
                />
                <div style={{ margin: '10px 0 0 0' }}>{userName}</div>
              </div>
            </Link>
          </div>
        </Content>
      )}
      <Content>
        <div style={{ display: 'flex' }}>
          <Button color="ghost" onClick={setSelected}>
            <strong>{title}</strong>
          </Button>
        </div>
      </Content>
      <div>
        {videoUrl ? (
          <Video
            loop
            muted
            poster={
              (thumbJpgUrls && (thumbJpgUrls.lowRes || thumbJpgUrls.highRes)) ||
              photoUrl
            }
            preload="none"
          >
            <source
              src={
                (videoMp4Urls &&
                  (videoMp4Urls.lowRes || videoMp4Urls.highRes)) ||
                videoUrl
              }
            />
          </Video>
        ) : (
          <Image
            src={
              (thumbJpgUrls && (thumbJpgUrls.lowRes || thumbJpgUrls.highRes)) ||
              photoUrl ||
              ''
            }
          />
        )}
      </div>
      <Content>
        <div style={{ textAlign: 'center' }}>
          <strong>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isAvailable
              ? endedAtFormatted
                ? `Available until ${endedAtFormatted}`
                : `${availableNumberOfPrints} left`
              : endedAtFormatted
              ? `Ended at ${endedAtFormatted}`
              : ''}
          </strong>
        </div>
      </Content>
    </Box>
  );
};

PostCard.defaultProps = {
  showProfile: true,
};

export default PostCard;
