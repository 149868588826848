import { firebase } from '../Firebase';

export interface Style {
  order: number;
  id: string;
  name: string;
  storyType: string;
  styleId: string;
  tier: string;
  avatarUrl?: string;
  thumbnailUrl: string;
  variationThumbnailUrl: string;
  isPublished: boolean;
  isReleased?: boolean;
}

export const empty: Style = {
  order: 0,
  id: '',
  name: '',
  storyType: '',
  styleId: '',
  tier: '',
  thumbnailUrl: '',
  variationThumbnailUrl: '',
  isPublished: false,
};

export interface Files {
  thumbnail: File | null;
  variationThumbnail: File | null;
}

export const emptyFiles: Files = {
  thumbnail: null,
  variationThumbnail: null,
};

export const converter = {
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)!;
    const style: Style = {
      ...empty,
      id: snapshot.id,
      ...data,
    };
    return style;
  },
  toFirestore(style: Style): firebase.firestore.DocumentData {
    return {
      ...style,
    };
  },
};
