import React, { useState } from 'react';
import { Container, Notification, Progress } from 'react-bulma-components';
import { Link, useHistory } from 'react-router-dom';

import { ROOT, DISCOVERS, DISCOVER_NEW } from '../../constants/routes';

import { useFirebase } from '../Firebase';
import Navigation from '../Navigation';

import DiscoverForm from './form';
import { BadgeUrls, CardBadgeUrls, converter, Discover, empty } from './types';

const NewDiscoverPage = function NewDiscoverPage() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string | null>(
    null
  );
  const [discover, setDiscover] = useState<Discover>({ ...empty });
  const history = useHistory();

  const firebase = useFirebase()!;
  const onChange =
    (fieldName: keyof Discover) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      let { value } = event.currentTarget;
      if (typeof discover[fieldName] !== 'string') {
        try {
          value = JSON.parse(value);
        } catch (err: any) {
          // eslint-disable-next-line no-console
          console.error(
            `typeof discover[${fieldName}] ERR`,
            typeof discover[fieldName],
            err
          );
        }
      } else if (fieldName === 'id') {
        value = value.replace(/[^a-z0-9]/gi, '').toLowerCase();
      }
      // console.log(fieldName, value);
      const values = {
        [fieldName]: value,
      };
      if (fieldName === 'title') {
        values.cardTitle = value;
      } else if (fieldName === 'tier') {
        values.badgeUrl = BadgeUrls[value];
        values.cardBadgeUrl = CardBadgeUrls[value];
      }
      const updated: Discover = { ...discover, ...values };
      setDiscover(updated);
    };

  const saveDiscover = async (discover1: Discover) => {
    const data = (
      await firebase.db
        .collection('discoverStories')
        .withConverter(converter)
        .limit(1)
        .get()
    ).docs[0].data()!;

    const { id } = discover1;
    const ref = firebase.db.collection('discoverStories').doc(id);
    await ref.set(Object.assign(data, discover1), { merge: false });
  };

  const onSave = async () => {
    // console.log('onSave');
    setLoading(true);
    try {
      await saveDiscover(discover);
      history.push(`${DISCOVERS}/${discover.id}`);
    } catch (error: any) {
      // console.log('onSave ERR', error);
      setNotificationMessage(error.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Navigation />
      <Container>
        <br />
        {notificationMessage && (
          <Notification color="danger" colorVariant="light">
            <button
              aria-label="close"
              className="delete"
              onClick={() => setNotificationMessage(null)}
              type="button"
            />
            {notificationMessage}
          </Notification>
        )}
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li>
              <Link to={DISCOVERS.to}>{DISCOVERS.title}</Link>
            </li>
            <li className="is-active">
              <Link to={DISCOVER_NEW.to}>{DISCOVER_NEW.title}</Link>
            </li>
          </ul>
        </nav>

        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <DiscoverForm
            discover={discover}
            onChange={onChange}
            onSave={onSave}
            isNew
          />
        )}
        <br />
      </Container>
    </>
  );
};

export default NewDiscoverPage;
