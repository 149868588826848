import React, { useState } from 'react';
import { Container, Notification, Progress } from 'react-bulma-components';
import { Link, useHistory } from 'react-router-dom';

import { ROOT, STORIES, STORY_NEW } from '../../constants/routes';

import { useFirebase } from '../Firebase';
import Navigation from '../Navigation';

import StoryForm from './form';
import { converter, empty, emptyVideoFiles, Story } from './types';

const NewStoryPage = function NewStoryPage() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string | null>(
    null
  );
  const [story, setStory] = useState<Story>({ ...empty });
  const history = useHistory();

  const firebase = useFirebase()!;
  const onChange =
    (fieldName: keyof Story) =>
    (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      let { value } = event.currentTarget;
      if (typeof story[fieldName] !== 'string') {
        value = JSON.parse(value);
      } else if (fieldName === 'id') {
        value = value.replace(/[^a-z0-9]/gi, '').toLowerCase();
      }
      // console.log(fieldName, value);
      const updated: Story = { ...story, [fieldName]: value };
      setStory(updated);
    };

  const saveStory = async (story1: Story) => {
    const data = (
      await firebase.db
        .collection('stories2.0')
        .withConverter(converter)
        .limit(1)
        .get()
    ).docs[0].data()!;

    const { id } = story;
    const ref = firebase.db.collection('stories2.0').doc(id);
    await ref.set(Object.assign(data, story1), { merge: false });
  };

  const onSave = async () => {
    // console.log('onSave');

    setLoading(true);
    try {
      await saveStory(story);
      history.push(`${STORIES}/${story.id}`);
    } catch (error: any) {
      // console.log('onSave ERR', error);
      setNotificationMessage(error.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Navigation />
      <Container>
        <br />
        {notificationMessage && (
          <Notification color="danger" colorVariant="light">
            <button
              aria-label="close"
              className="delete"
              onClick={() => setNotificationMessage(null)}
              type="button"
            />
            {notificationMessage}
          </Notification>
        )}
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li>
              <Link to={STORIES.to}>{STORIES.title}</Link>
            </li>
            <li className="is-active">
              <Link to={STORY_NEW.to}>{STORY_NEW.title}</Link>
            </li>
          </ul>
        </nav>

        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <StoryForm
            files={emptyVideoFiles}
            story={story}
            onChange={onChange}
            onSave={onSave}
            isNew
          />
        )}
        <br />
      </Container>
    </>
  );
};

export default NewStoryPage;
