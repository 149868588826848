export interface Files {
  avatar: File | null;
  cover: File | null;
  feature: File | null;
}

export const emptyFiles: Files = {
  avatar: null,
  cover: null,
  feature: null,
};
