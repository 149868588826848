import config from '../config';
import { Auth } from '../types';

class TokenService {
  private namespacedKey: string;

  constructor(namespace: string) {
    const key = 'auth';
    this.namespacedKey = `${namespace}:${key}`;
  }

  getAuth(): Auth | undefined {
    const item = localStorage.getItem(this.namespacedKey);
    if (!item) {
      return undefined;
    }

    let auth: Auth | undefined;
    try {
      auth = JSON.parse(item);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Auth parse ERR', err);
    }

    return auth;
  }

  getAccess(): string | undefined {
    const auth = this.getAuth();
    return auth?.accessToken;
  }

  getRefresh(): string | undefined {
    const auth = this.getAuth();
    return auth?.refreshToken;
  }

  removeAll() {
    localStorage.removeItem(this.namespacedKey);
  }

  resetAll(auth: Auth) {
    localStorage.setItem(
      this.namespacedKey,
      JSON.stringify({
        ...this.getAuth(),
        ...auth,
      })
    );
  }
}

export default new TokenService(config.namespace);
