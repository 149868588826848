import React, { useState, useEffect } from 'react';
import { Container, Notification, Progress } from 'react-bulma-components';
import { Link, useParams } from 'react-router-dom';

import { PROFILES, ROOT } from '../../constants/routes';
import Api from '../../services/api';
import File from '../../services/file';
import { emptyProfile as empty, Profile } from '../../types';
import {
  AdditionalsRaw,
  Group,
  Socials,
  TagsRaw,
  YouTube,
  YouTubeNumbersFlattened,
} from '../../types/profile';

import Navigation from '../Navigation';

import ProfileForm from './form';
import { emptyFiles } from './types';

type Params = { userName: string };

const ProfileEditPage: React.FC = function ProfileEditPage() {
  const { userName } = useParams<Params>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile>(empty);
  const [files, setFiles] = useState(emptyFiles);
  const [notification, setNotification] = useState<{
    message: string;
    color: 'success' | 'danger';
  } | null>(null);

  const { id } = profile;

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    const fetchAndSetData = async () => {
      const profile0 = await Api.getProfile({ userName });
      if (mounted) {
        const { additionals, statistics, tags } = profile0 || {};
        const bottoms = (additionals || [])
          .filter(({ group }) => group === Group.BOTTOM)
          .map(({ index, key, value }) => `${index}) ${key}: ${value}`)
          .join('\n');
        const tops = (additionals || [])
          .filter(({ group }) => group === Group.TOP)
          .map(({ key, value }) => `- ${key}: ${value}`)
          .join('\n');
        const tagsRaw: TagsRaw = (tags || []).reduce(
          (map, { subType, title }) => ({
            ...map,
            [(subType || '').toLowerCase()]: title,
          }),
          {}
        );
        const { youtube: numbers } = statistics || {};
        const {
          createdAt,
          earningsAmount,
          subscribersCount: subsCount,
          viewsCount,
          watchHours,
        } = numbers || {};
        const {
          pastSemester: pastSemesterEarnings,
          pastYear: pastYearEarnings,
          yearlyEstimate: yearlyEstimateEarnings,
        } = earningsAmount || {};
        const { total: subscribersCount } = subsCount || {};
        const {
          pastSemester: pastSemesterViewsCount,
          pastYear: pastYearViewsCount,
          total: totalViewsCount,
          yearlyEstimateHigh: yearlyEstimateHighViewsCount,
          yearlyEstimateLow: yearlyEstimateLowViewsCount,
        } = viewsCount || {};
        const { pastYear: pastYearWatchHours } = watchHours || {};
        const profile1 = {
          ...profile0,
          numbers: {
            youtubeStartedDate:
              createdAt && new Date(createdAt).toISOString().split('T')[0],
            pastSemesterEarnings,
            pastYearEarnings,
            yearlyEstimateEarnings,
            subscribersCount,
            pastSemesterViewsCount,
            pastYearViewsCount,
            totalViewsCount,
            yearlyEstimateHighViewsCount,
            yearlyEstimateLowViewsCount,
            pastYearWatchHours,
          },
          additionalsRaw: { bottoms, tops },
          tagsRaw,
        };
        setProfile(profile1);
        setLoading(false);
      }
    };

    fetchAndSetData().catch((error) =>
      // eslint-disable-next-line no-console
      console.error('fetchAndSetData ERR', error)
    );

    return () => {
      mounted = false;
    };
  }, [userName]);

  const onAdditionalsChange =
    (fieldName: keyof AdditionalsRaw) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      let { value } = event.currentTarget;
      const { additionalsRaw } = profile;
      const fieldType = typeof (additionalsRaw && additionalsRaw[fieldName]);
      if (fieldType !== 'undefined' && fieldType !== 'string') {
        value = JSON.parse(value);
      }
      // console.log(fieldType, fieldName, value);
      setProfile({
        ...profile,
        additionalsRaw: { ...additionalsRaw, [fieldName]: value || '' },
      });
    };

  const onNumbersChange =
    (fieldName: keyof YouTubeNumbersFlattened) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const { value } = event.currentTarget;
      const { numbers } = profile;
      setProfile({ ...profile, numbers: { ...numbers, [fieldName]: value } });
    };

  const onSocialsChange =
    (fieldName: keyof Socials) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const { value } = event.currentTarget;
      const { socials } = profile;
      const { discord, instagram, tiktok, twitter, website } = socials || {};

      let updated = socials;
      if (fieldName === 'discord') {
        updated = {
          ...socials,
          discord: { ...discord, serverUrl: value || '' },
        };
      } else if (fieldName === 'instagram') {
        updated = {
          ...socials,
          instagram: { ...instagram, pageUrl: value || '' },
        };
      } else if (fieldName === 'tiktok') {
        updated = {
          ...socials,
          tiktok: { ...tiktok, profileUrl: value || '' },
        };
      } else if (fieldName === 'twitter') {
        updated = {
          ...socials,
          twitter: { ...twitter, handleUrl: value || '' },
        };
      } else if (fieldName === 'website') {
        updated = {
          ...socials,
          website: { ...website, homePageUrl: value || '' },
        };
      }
      // console.log(fieldType, fieldName, value);
      setProfile({
        ...profile,
        socials: updated,
      });
    };

  const onTagsChange =
    (fieldName: keyof TagsRaw) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      let { value } = event.currentTarget;
      const { tagsRaw } = profile;
      const fieldType = typeof (tagsRaw && tagsRaw[fieldName]);
      if (fieldType !== 'undefined' && fieldType !== 'string') {
        value = JSON.parse(value);
      }
      // console.log(fieldType, fieldName, value);
      setProfile({
        ...profile,
        tagsRaw: { ...tagsRaw, [fieldName]: value || '' },
      });
    };

  const onYouTubeChange =
    (fieldName: keyof YouTube) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      let { value } = event.currentTarget;
      const { socials } = profile;
      const { youtube } = socials || {};
      const fieldType = typeof (youtube && youtube[fieldName]);
      if (fieldType !== 'undefined' && fieldType !== 'string') {
        value = JSON.parse(value);
      }
      // console.log(fieldType, fieldName, value);
      setProfile({
        ...profile,
        socials: {
          ...socials,
          youtube: { ...youtube, [fieldName]: value || '' },
        },
      });
    };

  const onChange =
    (fieldName: keyof Profile) =>
    (
      event: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      let { value } = event.currentTarget;
      const fieldType = typeof profile[fieldName];
      if (fieldType !== 'undefined' && fieldType !== 'string') {
        value = JSON.parse(value);
      }
      // console.log(fieldType, fieldName, value);
      setProfile({
        ...profile,
        [fieldName]: value,
      });
    };

  const onFileChange = (event: React.FormEvent<HTMLInputElement>) => {
    // console.log(event.currentTarget.id, event.currentTarget.value);
    const { files: files0 } = event.currentTarget;
    setFiles({
      ...files,
      [event.currentTarget.id]: files0 && files0.length > 0 ? files0[0] : null,
    });
  };

  const onSave = async () => {
    setLoading(true);
    try {
      const { avatar, cover, feature } = files;
      const type = 'profile';
      const avatarUpload = avatar && File.upload({ type, id, file: avatar });
      const coverUpload = cover && File.upload({ type, id, file: cover });
      const featureUpload = feature && File.upload({ type, id, file: feature });
      const [avatarResult, coverResult, featureResult] = await Promise.all([
        avatarUpload,
        coverUpload,
        featureUpload,
      ]);
      const emptyLocation = { location: null };
      const { location: avatarLocation } = avatarResult || emptyLocation;
      const { location: coverLocation } = coverResult || emptyLocation;
      const { location: featureLocation } = featureResult || emptyLocation;
      const { avatarUrl, coverUrl, featureUrl } = profile;
      await Api.updateProfile(id, {
        ...profile,
        avatarUrl: avatarLocation || avatarUrl,
        coverUrl: coverLocation || coverUrl,
        featureUrl: featureLocation || featureUrl,
      });
      setFiles({ avatar: null, cover: null, feature: null });
      setNotification({
        message: 'Profile updated successfully',
        color: 'success',
      });
    } catch (error: any) {
      setNotification({
        message: error.response?.data?.status?.message || error.message,
        color: 'danger',
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Navigation />
      <Container>
        <br />
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li>
              <Link to={PROFILES.to}>{PROFILES.title}</Link>
            </li>
            <li>
              <Link to={`${PROFILES.to}/${userName}`}>{userName}</Link>
            </li>
            <li className="is-active">
              <Link to={`${PROFILES.to}/${userName}/edit`}>Edit</Link>
            </li>
          </ul>
        </nav>
        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <>
            {notification && (
              <Notification color={notification.color} colorVariant="light">
                <button
                  aria-label="close"
                  className="delete"
                  onClick={() => setNotification(null)}
                  type="button"
                />
                {notification.message}
              </Notification>
            )}
            <ProfileForm
              files={files}
              onChange={onChange}
              onFileChange={onFileChange}
              onAdditionalsChange={onAdditionalsChange}
              onNumbersChange={onNumbersChange}
              onSocialsChange={onSocialsChange}
              onTagsChange={onTagsChange}
              onYouTubeChange={onYouTubeChange}
              onSave={onSave}
              profile={profile}
            />
          </>
        )}
        <br />
      </Container>
    </>
  );
};

export default ProfileEditPage;
