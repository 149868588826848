import FirebaseContext, {
  FirebaseProvider,
  useFirebase,
  useFirebaseToken,
  useFirebaseUser,
  withFirebase,
} from './context';
import Firebase, { firebase } from './firebase';

export type { firebase };
export default Firebase;

const { documentId } = firebase.firestore.FieldPath;

export {
  documentId,
  FirebaseContext,
  FirebaseProvider,
  useFirebase,
  useFirebaseToken,
  useFirebaseUser,
  withFirebase,
};
