import React, { useEffect, useState } from 'react';
import { Container, Progress } from 'react-bulma-components';
import { Link } from 'react-router-dom';

import { ROOT, STORIES, STORY_NEW } from '../../constants/routes';

import { useFirebase } from '../Firebase';
import Navigation from '../Navigation';

import StoriesTable from './table';
import { converter, Story } from './types';

const StoriesListPage = function StoriesListPage() {
  const firebase = useFirebase()!;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [stories, setStories] = useState<Story[]>([]);

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const fetchAndSetData = async () => {
      const snapshot = await firebase.db
        .collection('stories2.0')
        .withConverter(converter)
        .get();
      const stories1: Story[] = snapshot.docs.map((doc) => doc.data()!);
      if (mounted) {
        // console.log(JSON.stringify(stories1[0], null, 4));
        setStories(stories1);
        setLoading(false);
      }
    };
    fetchAndSetData().catch((error) =>
      // eslint-disable-next-line no-console
      console.error('fetchAndSetData ERR', error)
    );
    return () => {
      mounted = false;
    };
  }, [firebase.db]);

  return (
    <>
      <Navigation />
      <Container>
        <br />
        <nav className="breadcrumb">
          <ul>
            <li>
              <Link to={ROOT.to}>{ROOT.title}</Link>
            </li>
            <li className="is-active">
              <Link to={STORIES.to}>{STORIES.title}</Link>
            </li>
            <li>
              <Link to={STORY_NEW.to}>{STORY_NEW.title}</Link>
            </li>
          </ul>
        </nav>
        {isLoading ? (
          <Progress max={100} color="info" />
        ) : (
          <StoriesTable stories={stories} />
        )}
        <br />
      </Container>
    </>
  );
};

export default StoriesListPage;
