import { useEffect, useState } from 'react';

import Api from '../services/api';
import { Post } from '../types';

import useCurated from './useCurated';

function usePosts(featured0?: boolean) {
  const [after, setAfter] = useState('');
  const curated = useCurated();
  const featured1 = useCurated('featured');
  const featured = featured0 || featured1;
  const [isLoading, setLoading] = useState(false);
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    let mounted = true;
    const func = () => {
      mounted = false;
    };

    setLoading(true);

    // eslint-disable-next-line no-nested-ternary
    const promise = featured
      ? Api.listPosts({ after, featured })
      : curated === false
      ? Api.listPosts({ after })
      : Api.listFeedItems({ after });

    const fetchAndSetData = async () => {
      const posts1 = await promise;
      if (mounted) {
        setLoading(false);
        setPosts(posts1);
      }
    };

    fetchAndSetData().catch((error) =>
      // eslint-disable-next-line no-console
      console.error('usePosts fetchAndSetData ERR', error)
    );

    return func;
  }, [after, curated, featured]);

  return { curated, featured, isLoading, posts, setAfter };
}

export default usePosts;
