import React, { useState } from 'react';
import { Button } from 'react-bulma-components';

import { User } from './types';

type Props = {
  user: User;
  onLockUnlock: () => Promise<void>;
};

const UserRow: React.FC<Props> = function UserRow({ user, onLockUnlock }) {
  const {
    id,
    emailAddress,
    emailAddressAlt,
    ethAddress,
    ethAddressAlt,
    phoneNumber,
    registration,
  } = user;
  const { isLocked } = registration || {};
  const [isLoading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    await onLockUnlock();
    setLoading(false);
  };

  return (
    <tr>
      <td>
        <i>
          <div className="content is-small">{id}</div>
        </i>
      </td>
      <td>{emailAddress || emailAddressAlt}</td>
      <td>{ethAddress || ethAddressAlt}</td>
      <td>{phoneNumber}</td>
      <td>{isLocked ? 'true' : 'false'}</td>
      <td>
        <div className="field ">
          <p className="control">
            <Button
              outlined
              color="warning"
              loading={isLoading}
              onClick={onClick}
            >
              {isLocked ? 'Unlock' : 'Lock'}
            </Button>
          </p>
        </div>
      </td>
    </tr>
  );
};

export default UserRow;
