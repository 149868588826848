import axios from 'axios';

import { GOOGLE_AUTH_API, ROOT } from '../constants/routes';
import Auth from './auth';
import Token from './token';

const instance = axios.create({
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.request.use(
  (config) => {
    const token = Token.getAccess();
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  ({ data }) => data,
  async (error) => {
    const { config, response } = error;
    const { sirius, url } = config;
    const { status } = response;
    if (url !== GOOGLE_AUTH_API.to && response) {
      if (status === 401 && !sirius) {
        config.sirius = true;
        try {
          await Auth.refresh();
          // eslint-disable-next-line @typescript-eslint/return-await
          return instance(config);
        } catch (err) {
          Auth.logout();
          window.location.href = ROOT.to;
          return Promise.reject(err);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
